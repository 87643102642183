// component
import SvgColor from 'src/components/svg-color/SvgColor';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navDetails = (user) => {
  let config = [
    {
      title: 'Tenants',
      path: '/dashboard/tenants',
      icon: icon('tenants'),
      children: [
        {
          title: 'Preshipment Alert',
          path: '/dashboard/tenants/details',
        },
      ]
    },
    {
      title: 'Plans',
      path: '/dashboard/subscription_plans',
      icon: icon('plans'),
    },
    {
      title: 'Payment Processors',
      path: '/dashboard/payment_processors',
      icon: icon('payments'),
    },
    {
      title: 'Tracking',
      path: '/dashboard/tracking',
      icon: icon('tracking'),
    },
    // {
    //   title: 'Reports',
    //   path: '/#report_menu',
    //   icon: icon('reports'),
    //   children: [
    //     {
    //       title: 'Preshipment Alert',
    //       path: '/dashboard/reports/preshipment',
    //     },
    //     {
    //       title: 'In\xa0Transit Alert',
    //       path: '/dashboard/reports/intransit',
    //     },
    //     {
    //       title: 'Average Time InTransit',
    //       path: '/dashboard/reports/average_intransit_time',
    //     },
    //   ],
    // },
  ];
  config.push({ title: 'Manage Returns', path: '/dashboard/manage-returns', icon: icon('inbound-management') });

  // config page for admins only
  // if (user?.role?.role_name == 'Administrators') {
  config.push({ title: 'Configurations', path: '/dashboard/config', icon: icon('settings') });
  // }

  // users page added below config page
  config.push({ title: 'Users', path: '/dashboard/users', icon: icon('users') });
  // config.push({ title: 'Billing', path: '/dashboard/billing', icon: icon('billing') });
  config.push({ title: 'Onboard Tenants', path: '/dashboard/onboardTenants', icon: icon('onboard') });
  config.push({ title: 'Utility', path: '/dashboard/utility', icon: icon('settings') });
  config.push({ title: 'Blog Management', path: '/dashboard/blogs', icon: icon('blogs') });

  return config;
};

export default navDetails;
