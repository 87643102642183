
  import {fetchTemplateByName,htmlReturnTemplateTextList,} from "../convertHtmlToText";
  import {getEmailTemplatesList} from "src/features/config/configApi";
  import {putEmailTemplatesList} from "src/features/returnConfiguration/emailTemplatesApi";
import {incrementAwaitingApiCounter,decrementAwaitingApiCounter,} from "src/features/ui/uiState";
  
  export const mergeHtmlLayoutAndNewContent = (
    emailTemplateLayout,
    emailTemplateContent,
    retrieveLogoUrl,
    logoFile
  ) => {
    let emailTemplateFormatted = emailTemplateContent.replaceAll(
      "<p",
      '<p style="margin: 0px; line-height: 20px;"'
    );
  
    // if includes the track return items btn
    if (emailTemplateFormatted.includes('class="ql-align-center')) {
      emailTemplateFormatted = emailTemplateFormatted.replaceAll(
        '<p style="margin: 0px; line-height: 20px;" class="ql-align-center"',
        '<p style="margin: 0px; line-height: 20px; text-align: center;" class="ql-align-center"'
      );
    }
  
    const src = retrieveLogoUrl + logoFile;
  
    return emailTemplateLayout.replace(
      "[replace_this_by_content]",
      `<div style="display: flex; justify-content: center; padding-bottom: 20px">
        <img
        alt="logo"
        src="${src}"
        style="display: block; max-width: 150px"
      /></div>` + emailTemplateFormatted
    );
  };
  
  export const filterImgs = (htmlContent) => {
    const temporalComp = document.createElement("div");
    temporalComp.innerHTML = htmlContent;
  
    const unwantedImgs = temporalComp.getElementsByTagName("img");
    for (let i = 0; i < unwantedImgs.length; i++) {
      unwantedImgs[i].parentNode.removeChild(unwantedImgs[i]);
    }
  
    // const paragraphs = temporalComp.getElementsByTagName("p");
    // const ul = temporalComp.getElementsByTagName("ul");
    // const ol = temporalComp.getElementsByTagName("ol");
    // for (let i = 0; i < paragraphs.length; i++) {
    //   const br = document.createElement("br");
    //   paragraphs[i].parentNode.insertBefore(br, paragraphs[i].nextSibling);
    // }
    // for (let i = 0; i < ul.length; i++) {
    //   const br = document.createElement("br");
    //   ul[i].parentNode.insertBefore(br, ul[i].nextSibling);
    // }
    // for (let i = 0; i < ol.length; i++) {
    //   const br = document.createElement("br");
    //   ol[i].parentNode.insertBefore(br, ol[i].nextSibling);
    // }
  
    return temporalComp.innerHTML;
  };

  export const getTrackingUrl=(template_name,userInfo)=>{
    if (["first_scan_email", "delivered_email", "out_for_delivery_email"]?.includes(template_name)) {
      return `{{tracking_link}}`
    } else {
      return `https://app-staging.beyondcarts.com/returns/${userInfo.tenant_name}/tracking`
    }
  }
  
  export const getEmailTemplate = async (
    userInfo,
    template_name,
    setEmailTemplateLayout,
    setEmailTemplateSubject,
    setEmailTemplateContent,
    setEmailTemplateBackendReponse,
    // emailTemplateBackendReponse,
    dispatch,
  ) => {
    dispatch(incrementAwaitingApiCounter());
    try {
      const response = await getEmailTemplatesList(userInfo);
      const templateData = await fetchTemplateByName(template_name);
      if (response.statusCode === 200) {
        if (response.data.length > 0) {
          const emailTemplateData = response?.data?.find(
            (el) => el.template_name === template_name
          );
          if (emailTemplateData) {
            setEmailTemplateContent(filterImgs(emailTemplateData?.html_content));
            setEmailTemplateLayout(templateData?.html_layout);
            setEmailTemplateBackendReponse(
              filterImgs(emailTemplateData?.html_content)
            );
            setEmailTemplateSubject(emailTemplateData?.subject);
            dispatch(decrementAwaitingApiCounter());
            return;
          }
        }
        const defaultTemplate = (await htmlReturnTemplateTextList()).find(
          (el) => el.template_name === template_name
        );
        console.log(template_name,"template_name")
        setEmailTemplateContent(
          defaultTemplate?.html_content.replace(
            "{{tracking_link}}",
            // TODO: when deploying to product, change this url to the prod url
            getTrackingUrl(template_name,userInfo)
            // `https://app-staging.beyondcarts.com/returns/${userInfo.tenant_name}/tracking`
          )
        );
       
        setEmailTemplateLayout(defaultTemplate?.html_layout);
        setEmailTemplateSubject(defaultTemplate?.subject);
        setEmailTemplateBackendReponse(
          defaultTemplate?.html_layout.replace(
            "[replace_this_by_content]",
            filterImgs(defaultTemplate?.html_content)
          )
        );
        dispatch(decrementAwaitingApiCounter());
      }
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
  
      console.log("error", error);
    }
  };
  
  export const publishEmailTemplate = async (
    userInfo,
    template_name,
    emailTemplateLayout,
    emailTemplateSubject,
    emailTemplateContent,
    retrieveLogoUrl,
    logoFile
  ) => {
    const body = {
      template_name,
      html_content: mergeHtmlLayoutAndNewContent(
        emailTemplateLayout,
        emailTemplateContent,
        retrieveLogoUrl,
        logoFile
      ),
      subject: emailTemplateSubject,
    };
  
    return await putEmailTemplatesList(body, userInfo);
  };
  