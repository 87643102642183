import React from 'react'
import { useEffect, useState, useRef } from "react";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import "./style/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
// import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import ReturnRules from "./ReturnRules";
import AutoApproval from "./AutoApproval";
import ShippingRefund from "./ShippingRefund/ShippingRefund";
import BlockedCustomer from "./BlockedCustomer/BlockedCustomer";
import EmailTemplate from "src/pages/EmailTemplate/EmailTemplate";
import ReturnMethod  from "src/pages/ReturnConfiguration/ReturnMethod/ReturnMethod";



import {
  TextField,
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  Modal,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
  Grid,
  Tab,
  Tabs,
  Stack
} from "@mui/material";

// import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import InfoLogo from "src/components/info-logo/info-logo";
import ManageForm from "src/sections/auth/signin/formsContainer/manage/manage";
import Carrier from "src/sections/auth/signin/formsContainer/carrier/carrier";
import { unixToDate } from "src/utils/core";
import ModalComponent from "src/components/modal/ModalComponent";

const styleInput = {
    input: { color: palette.common.black },
    width: { lg: "100%" },
    marginTop: "10px",
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "primary.dark",
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.dark",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "secondary.light",
    },
    "& .MuiInputLabel-formControl": {
      color: "#00000099",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "#00000099",
    },
    "& .MuiFormHelperText-root": {
      textAlign: "left",
      fontSize: "14px",
    },
  };
  
  const btnStyle = {
    width: 188,
    height: 48,
    boxShadow: "none",
    fontSize: 18,
    fontFamily: typography.fontFamilySemiBold,
  };
  
  const stylePassModal = {
    position: "absolute",
    top: { lg: "46%", md: "42.5%", sm: "30%" },
    left: { lg: "47.5%", md: "52%", sm: "50%" },
    transform: "translate(-50%, -50%)",
    width: "550px",
    borderRadius: "10px",
    background: "#FAFAFA",
    padding: "20px 0",
  };
  
  const textStyle = {
    color: palette.common.black,
    fontSize: { xs: 16, md: 18 },
  };
  const popUpModalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "80%", sm: "auto", md: "auto" },
    borderRadius: "20px",
    background: "#FFF",
    overflowX: "hidden",
    outline: "none",
  };
export default function ReturnConfigPage(
  {
    emailTemplateLayout,
  setEmailTemplateLayout,
  emailTemplateContent,
  setEmailTemplateContent,
  emailTemplateSubject,
  setEmailTemplateSubject,
  emailTemplateBackendReponse,
  setEmailTemplateBackendReponse,
  }
){
    const [configTab, setConfigTab] = useState("1")

    return (<>
     <TabContext value={configTab}>
        <Grid container xs={12}>
          <Grid item xs={2.0} style={{border:"1px solid #D9D9D9",borderRadius:"5px",background:"white"}} height={"40vw"}>
            <Box>
              <TabList
                onChange={(__, newValue) => {
                  setConfigTab(newValue);
                //   setIsConfig(false);
                //   setIsConfigCarrierOrderManager(false)
                //   setOpenEditTemplateUI("");
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="lab API tabs"
                orientation="vertical"
                TabIndicatorProps={{ style: { display: 'none' } }}
                sx={{
                  "& .MuiTab-root": {
                    color: "black",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"start" ,
                    padding:' 12px 6px',
                    "&.Mui-selected": {
                      color: "white",
                      background:"#143F67",
                      // borderRadius:"5px",
                    },
                  },
                }}
              >
                <Tab
                  label="Return rules"
                  value="1"
                />
                <Tab
                  label="Auto approval"
                  value="2"
                />
                <Tab
                  label="Shipping refund configuration"
                  value="3"
                  // style={{display:'flex',textWrap:'nowrap'}}
                />
                <Tab
                  label="Return method"
                  value="4"
                />
                <Tab
                  label="Blocked customer"
                  value="5"
                />
                <Tab
                  label="Email template"
                  value="6"
                />
               
              </TabList>
            </Box>
          </Grid>
          <Grid item xs={10} paddingTop={0} >
            <Box >
              <TabPanel
                value="1"
              >
                <ReturnRules/>
                
              </TabPanel>
              <TabPanel
                value="2"
              >
             <AutoApproval/>
              </TabPanel>
              <TabPanel
                value="3"
                style={{ paddingTop:'0px' }}

              >
               
             <ShippingRefund/>
              </TabPanel>
              <TabPanel value="4">
              <ReturnMethod
                emailTemplateLayout={emailTemplateLayout}
                setEmailTemplateLayout={setEmailTemplateLayout}
                emailTemplateContent={emailTemplateContent}
                setEmailTemplateContent={setEmailTemplateContent}
                emailTemplateSubject={emailTemplateSubject}
                setEmailTemplateSubject={setEmailTemplateSubject}
                emailTemplateBackendReponse={emailTemplateBackendReponse}
                setEmailTemplateBackendReponse={setEmailTemplateBackendReponse}
              />
              </TabPanel>
              <TabPanel value="5" 
          style={{paddingTop:'0px'}} 
              >
             <BlockedCustomer />
              </TabPanel>
              <TabPanel value="6" 
          style={{paddingTop:'0px'}} 
              >
         <EmailTemplate
         emailTemplateLayout={emailTemplateLayout}
         setEmailTemplateLayout={setEmailTemplateLayout}
         emailTemplateContent={emailTemplateContent}
         setEmailTemplateContent={setEmailTemplateContent}
         emailTemplateSubject={emailTemplateSubject}
         setEmailTemplateSubject={setEmailTemplateSubject}
         emailTemplateBackendReponse={emailTemplateBackendReponse}
         setEmailTemplateBackendReponse={setEmailTemplateBackendReponse}
         />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>

       
      </TabContext>
    </>)
}