/* eslint-disable */
import {
	Navigate,
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import { useState, useEffect } from "react";
//
import MailScans from "./pages/MailScans";
import TrackingPage from "./pages/TrackingPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import Reports from "./pages/Reports/Reports";
import DashboardAppPage from "./pages/DashboardAppPage";
import SigninFormContainer from "./sections/auth/signin/formsContainer/formsContainer";
import Carrier from "./sections/auth/signin/formsContainer/carrier/carrier";
import ManageForm from "./sections/auth/signin/formsContainer/manage/manage";
import SigninCarrier from "./sections/auth/signin/formsContainer/carrier/signinCarrier/signinCarrier";
import SigninManage from "./sections/auth/signin/formsContainer/manage/signinManage/signinManage";
import Plans from "./sections/auth/signin/formsContainer/plans/plans";
import Billing from "./sections/auth/signin/formsContainer/billing/billing";
import Checkout from "./sections/auth/signin/formsContainer/checkout/checkout";
import WelcomeView from "./sections/auth/signin/welcome/welcome";
import { connect, useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
import { getToken } from "./utils/extractUserToken";
import { logoutUser } from "./features/user/userState";
import { resetScanState } from "./features/scan/scanState";
import { resetConfigState } from "./features/config/configState";
import { resetTrackState } from "./features/tracking/trakingState";
import { decrementAwaitingApiCounter } from "./features/ui/uiState";
import AverageTimeTracker from "./pages/Reports/AverageTimeTracker";
import ResetPasswordStep1Page from "./pages/ResetPassword/ResetPasswordStep1Page";
import ResetPasswordStep2Page from "./pages/ResetPassword/ResetPasswordStep2Page";
import ResetPasswordStep3Page from "./pages/ResetPassword/ResetPasswordStep3Page";
import ResetPasswordLogged from "./pages/ResetPassword/ResetPasswordLogged";
import ResetPasswordDone from "./pages/ResetPassword/ResetPasswordDone";
import UsersPage from "./pages/Users/UsersPage";
import BillingInvoice from "./pages/billing/billing";
import SubscriptionPlans from "./pages/Plans/SubscriptionPlans";

import PaymentProcessors from "./pages/PaymentProcessors/PaymentProcessors";
import Tenants from "./pages/tenants/Tenants";
import ProfileDetails from "./pages/tenants/ProfileDetails";
import OnboardTenants from "./pages/onboardTenants/onboardTenants";
import Configuration from "./pages/tenants/Configuration";
import Utility from "./pages/Utility";
import WelcomeTenantView from "./sections/auth/signin/welcome/welcomeTenant";
import CreateBlogs from "./pages/blogs/CreateBlogs";
import BlogsList from "./pages/blogs/BlogsList";
import PreviewBlogs from "./pages/blogs/PreviewBlogs";
import ManageReturns from "./pages/ManageReturns/ManageReturns";
import ReturnsDetail from "./pages/ManageReturns/ReturnsDetail";

function Router({ user, ui }) {
	const dispatch = useDispatch();
	const [token, setToken] = useState(getToken());

	useEffect(() => {
		const fetchedToken = getToken();

		if (!fetchedToken) {
			setToken(false);
			dispatch(logoutUser());
			dispatch(resetScanState());
			dispatch(resetConfigState());
			dispatch(resetTrackState());
			dispatch(decrementAwaitingApiCounter());
		} else {
			setToken(fetchedToken);
		}
	}, [token, user, ui]);

	const routes = createBrowserRouter([
		{
			path: "login",
			element: token ? <Navigate to="/dashboard" /> : <LoginPage />,
		},
		{
			path: "signin",
			children: [
				{
					element: <SigninFormContainer />,
					children: [
						{ path: "carrier", element: <Carrier /> },
						{ path: "order-manager", element: <ManageForm /> },
						{ path: "signin-carrier", element: <SigninCarrier /> },
						{ path: "signin-manager", element: <SigninManage /> },
						{ path: "plans", element: <Plans /> },
						{ path: "billing", element: <Billing /> },
						{ path: "checkout", element: <Checkout /> },
					],
				},
			],
		},
		{ path: "welcome", element: <WelcomeView /> },
		{ path: "reset-password/step-1", element: <ResetPasswordStep1Page /> },
		{ path: "reset-password/step-2", element: <ResetPasswordStep2Page /> },
		{ path: "reset-password/step-3", element: <ResetPasswordStep3Page /> },
		{ path: "reset-password/logged", element: <ResetPasswordLogged /> },
		{ path: "reset-password/done", element: <ResetPasswordDone /> },
		{ path: "preview", element: <PreviewBlogs/> },

		{
			path: "dashboard",
			element: token ? <DashboardLayout /> : <Navigate to="/login" />,
			children: [
				{ element: <Navigate to="/dashboard/tenants" />, index: true },
				{ path: "app", element: <DashboardAppPage /> },
				{ path: "payment_processors", element: <PaymentProcessors /> },
				{
					path: "on-board",
					children: [
						{
							element: <SigninFormContainer />,
							children: [
								{ path: "carrier", element: <Carrier /> },
								{
									path: "order-manager",
									element: <ManageForm />,
								},
								{
									path: "signin-carrier",
									element: <SigninCarrier />,
								},
								{
									path: "signin-manager",
									element: <SigninManage />,
								},
								{ path: "plans", element: <Plans /> },
								{ path: "billing", element: <Billing /> },
								{ path: "checkout", element: <Checkout /> },
								{
									path: "welcome",
									element: <WelcomeTenantView />,
								},
							],
						},
					],
				},
				{
					path: "tenants",
					children: [
						{ element: <Tenants />, index: true },
						{ path: "details", element: <ProfileDetails /> },
					],
				},
				{ path: "onboardTenants", element: <OnboardTenants /> },
				{ path: "utility", element: <Utility /> },
				{ path: "tracking", element: <TrackingPage /> },
				{ path: "tracking/:trackingNumber", element: <TrackingPage /> },
				{
					path: "tracking/filter/:status/:dashboardStartDate/:dashboardEndDate",
					element: <TrackingPage />,
				},
				{
					path: "tracking/scan_filter/:first_scan/:dashboardStartDate/:dashboardEndDate",
					element: <TrackingPage />,
				},
				{ path: "subscription_plans", element: <SubscriptionPlans /> },
				{ path: "mail_scans", element: <MailScans /> },
				{ path: "reports", element: <Reports /> },
				{ path: "reports/preshipment", element: <Reports /> },
				{ path: "reports/intransit", element: <Reports /> },
				{
					path: "reports/average_intransit_time",
					element: <AverageTimeTracker />,
				},
				{ path: "users", element: <UsersPage /> },
				{ path: "billing", element: <BillingInvoice /> },
				{
					path: "config",
					element: <Configuration />,
				},

				//blogs
				{
					path: "blogs",
					children: [
						{ element: <BlogsList/>, index: true },
						{ path: "manage-blogs", element: <CreateBlogs/> },
					],
				},
				//Managereturns
				{
					path: "manage-returns",
					children: [
						{ element: <ManageReturns/>, index: true },
						{ path: "return-details", element: <ReturnsDetail/> },
					],
				},
			],
		},
		{
			children: [
				{ element: <Navigate to="/dashboard/tenants" />, index: true },
				{ path: "*", element: <Page404 /> },
			],
		},
	]);

	return <RouterProvider router={routes} />;
}

Router.propTypes = {
	user: PropTypes.shape({}),
};
const mapDispatch = (state) => ({
	user: state.user,
	ui: state.ui,
});

export default connect(mapDispatch)(Router);
