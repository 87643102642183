const { createSlice } = require("@reduxjs/toolkit");

export const shopifyImageSlice = createSlice({
    name: 'shopify',
    initialState: { shopifyImages: {} },
    reducers: {
        setShopifyImages : (state, action) => {
            state.shopifyImages = action.payload
        }
    }
})

export default shopifyImageSlice.reducer
export const { setShopifyImages } = shopifyImageSlice.actions;
