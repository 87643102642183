import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Stack,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useDispatch, useSelector } from "react-redux";
import { updateCarrier } from "src/features/returns/stepperFormSlice";
import palette from "src/theme/palette";
import CloseIcon from "@mui/icons-material/Close";
import {mailClassOptions } from 'src/utils/labelUtils';
import '../../style/styles.css'

const dropDownStyles={
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#AAAAAA", 
    },
    "&:hover fieldset": {
      borderColor: "#000000", 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#002F43", 
    },
  },
  "& .MuiInputLabel-root": {
    color: "#AAAAAA", 
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#002F43", 
  },
}
const CarrierStepper = ({ handleNext, handleBack }) => {
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        overflowY: 'auto', 
      },
    
    },
    sx: {
      "& .MuiPaper-root": {
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1C272A",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#333",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f0f0f0",
        },
      },
    },
  };

  const { carrierValue, mailClassValue } = useSelector((state) => ({
    carrier: state?.form?.carrier?.carrier,
    mailClassValue: state?.form?.carrier?.mailClass,
  }));
  const dispatch = useDispatch();
  const [carrier] = useState("USPS");
  const [mailClass, setMailClass] = useState([]);
  const [error, setError] = useState(false);


  const handleDeleteReturnReason = (elementToDelete) => {
    const newReturnReasonList = mailClass?.filter(
      (el) => el !== elementToDelete
    );
    setMailClass(newReturnReasonList);
  };
  useEffect(() => {
    if (mailClassValue.length > 0) setMailClass(mailClassValue);
  }, []);

  const handleNextClick = () => {
    if (mailClass.length > 0) {
      setError(false);
      dispatch(updateCarrier({ carrier, mailClass }));
      handleNext();
    } else {
      setError(true);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setMailClass(typeof value === "string" ? value.split(",") : value);
    if (value.length > 0) setError(false);
  };

  return (
    <Stack gap={5} padding={5}>
      <Stack gap={1}>
        <Typography variant="h5" textAlign={"center"}  sx={{ color: "#002F43"}}>
          Carrier & Mail class configuration
        </Typography>
        <Typography variant="body2" textAlign={"center"} color={"#AAAAAA"}>
          Select your preferred Mail Class based on Carrier chosen.
        </Typography>
      </Stack>
      <Stack alignItems={"center"} gap={2} sx={{flexDirection:'row',justifyContent:'center'}}>

          <FormControl    
          sx={{ 
            mb: 2, 
           ...dropDownStyles
          }}>
            <InputLabel>Carrier</InputLabel>
            <Select value={carrier} disabled label="Carrier"
            className="textBoxsize"
            >
              <MenuItem value="USPS"   
              sx={{
              color: "#002F43", 
              '&:hover': {
                backgroundColor: '#E3F2FD',
    },
  }}
>USPS</MenuItem>
            </Select>
          </FormControl>

          <FormControl  error={error} 
          sx={{ 
            mb: 2, 
           ...dropDownStyles
          }}>
            <InputLabel id="mail-class-label">Select mail class</InputLabel>
            <Select
              labelId="mail-class-label"
              id="mail-class-select"
              multiple
              className="textBoxsize"
              value={mailClass}
              onChange={handleChange}
              input={<OutlinedInput label="Select mail class" />}
              renderValue={(selected) =>
                selected.map((val) => {
                  const option = mailClassOptions.find((opt) => opt?.value === val);
                  return option ? option?.label : val;
                }).join(", ")
              }
              MenuProps={MenuProps}
            >
              {mailClassOptions.map((mailClassOption) => (
                <MenuItem key={mailClassOption?.value} value={mailClassOption?.value} 
                
                sx={{
                  color: "#002F43", 
                  '&:hover': {
                    backgroundColor: '#E3F2FD',
        },
      }}>
                  <Checkbox checked={mailClass.includes(mailClassOption?.value)} />
                  <ListItemText primary={mailClassOption.label} 
                   />
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText error>
                Please select at least one mail class
              </FormHelperText>
            )}
          </FormControl>
</Stack>
          {mailClass && (
            <Grid
              container
              direction={"row"}
              gap={2}
              justifyContent={"center"}
              paddingX={1}
            >
              {mailClass && mailClass?.map((el) => (
                <Grid
                  key={el?.label}
                  item
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  paddingX={1.2}
                  paddingY={0.5}
                  gap={0.8}
                  onClick={() => handleDeleteReturnReason(el)}
                  sx={{
                    cursor: "pointer",
                    bgcolor: palette.primary.main,
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                >
                  <Typography>
                    {mailClassOptions.find((opt) => opt.value === el)?.label || el}
                  </Typography>
                  <CloseIcon
                    fontSize="small"
                    sx={{
                      width: "17px",
                      fill: "#fff",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}

      <Stack gap={1} direction={"row"} justifyContent={"flex-end"}>
        <ArrowCircleLeftIcon
          onClick={handleBack}
          sx={{
            color: "#002F43",
            cursor: "pointer",
            width: "50px !important",
            height: "50px !important",
          }}
        />
        <ArrowCircleRightIcon
          onClick={handleNextClick}
          sx={{
            color: error || mailClass.length == 0 ? "#CFDEE9" : "#002F43",
            cursor: "pointer",
            width: "50px !important",
            height: "50px !important",
          }}
        />
      </Stack>
    </Stack>
  );
};

export default CarrierStepper;
