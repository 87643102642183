import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Modal,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import palette from "src/theme/palette";
  import typography from "src/theme/typography";
  import CloseIcon from "@mui/icons-material/Close";
  import { LoadingButton } from "@mui/lab";
  import NumberInputBasic from "src/components/number-input/NumberInputBasic";
  import SearchIcon from "@mui/icons-material/Search";
  import { getProductList } from "src/features/managereturns/returnsApi";
  import { useAppSelector } from "src/hooks/hooks";
  import { getUserInfo } from "src/features/user/userState";
  import { useDispatch } from "react-redux";
  import CircularLoader from "src/loader/circular/CircularLoader";
  import {
    decrementAwaitingApiCounter,
    incrementAwaitingApiCounter,
  } from "src/features/ui/uiState";
//   import InfiniteScroll from "react-infinite-scroll-component";
import {  useSelector } from "react-redux";


  
  const popUpModalStyle = {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "80%", sm: "auto", lg: "580px", md: "auto" },
    // height: { xs: "80%", md: "60%" },
    borderRadius: "20px",
    background: "#FFF",
    overflowX: "hidden",
    outline: "none",
  };
  const dropDownStyles={
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AAAAAA", 
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000", 
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#002F43", 
    },
  }
  const textStyle = {
    color: palette.common.black,
    fontSize: { xs: 16, md: 18 },
  };
  const SelectableItem = ({
    data,
    productListLength,
    returnReasonList,
    setReturnReasonList,
    returnWindowDays,
    setReturnWindowDays,
    productExclusionsList,
    setProductExclusionsList,
    setHasBeenSaved,
    isActive,
    setIsActive,
    handleRemoveProduct,
  }) => {
    const userInfo = useAppSelector(getUserInfo);
    const dispatch = useDispatch();
    const scrollRef = useRef(null);
  
    const [isLoading, setIsLoading] = useState(false);
    const [productList, setProductList] = useState([]);
    const [textAreaValue, setTextAreaValue] = useState("");
    const [openProductExclusionsModal, setOpenProductExclusionsModal] =
      useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchExclusionProductValue, setSearchExclusionProductValue] =
      useState("");
    const [searchAddProducts, setSearchAddProducts] = useState(null);
  
    const [pageSize, setPageSize] = useState(8);
    const [currentPage, setCurrentPage] = useState(0);
    const [shopifyCursor, setShopifyCursor] = useState(null);
    const [isHasMoreProducts, setIsHasMoreProducts] = useState(true);
    const [reasonLimit, setReasonLimit] = useState("");
    const [isOpen, setOpen] = useState(false);
    const [productToRemove, setProductToRemove] = useState(null);
    const handleSearchOnChange = (event) => {
      setSearchExclusionProductValue(event.target.value);
    };
   const selectedTenant = useSelector(
          (state) => state?.tenants
      );
    useEffect(() => {
      if (openProductExclusionsModal) {
        setProductList([]);
        populateProductList(1);
      } else {
        resetProductStatus();
      }
    }, [openProductExclusionsModal]);
  
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;
        if (scrollTop + clientHeight + 5 >= scrollHeight) {
          populateProductList();
        }
      }
    };
    useEffect(() => {
      if (currentPage >= 1) {
        const scrollElement = scrollRef.current;
        if (scrollElement) {
          scrollElement.addEventListener("scroll", handleScroll);
        }
        return () => {
          if (scrollElement) {
            scrollElement.removeEventListener("scroll", handleScroll);
          }
        };
      }
    }, [currentPage]); // Re-run effect if items change
  
    const handleSearhAddProducts = async () => {
      setProductList([]);
      resetProductStatus();
      populateProductList(1);
    };
    
  
    const populateProductList = async (page = null) => {
      try {
        if (isHasMoreProducts) {
          setIsLoading(true);
  
          let params = {
            page_size: 10,
            current_page: page != null ? page : currentPage,
          };
          if (shopifyCursor != null) {
            params["cursor"] = shopifyCursor;
          }
          if (searchAddProducts != null) {
            params["search_txt"] = searchAddProducts;
          }
          const response = await getProductList({ ...userInfo, tenant: selectedTenant }, params);
  
          if (response.statusCode === 200) {
            if (response?.data.hasOwnProperty("cursor")) {
              if (response?.data?.cursor != null) {
                setShopifyCursor((prev) => response?.data?.cursor);
              } else {
                setIsHasMoreProducts((prev) => false);
                setShopifyCursor(null);
              }
            } else {
              if (response.data.product_list.length <= 0) {
                setIsHasMoreProducts((prev) => false);
              }
              setShopifyCursor(null);
            }
            setProductList((prev) => [...prev, ...response.data.product_list]);
          }
        }
      } catch (error) {
        console.error("Failed to fetch product list:", error);
      } finally {
        setIsLoading(false);
        setCurrentPage((prev) => (page != null ? page : currentPage) + 1);
      }
    };
  
    const hasMoreFn = () => {
      if (searchAddProducts) return false;
      else return true;
    };
    const handleRemove = (el) => {
      setProductToRemove(el);
      setOpen(true);
    };
    useEffect(() => {
      setSelectedProducts(productExclusionsList);
  
      // populateProductList();
    }, [productExclusionsList]);
  
    const handleSelectChange = (value) => {
      setReturnWindowDays(value);
      setHasBeenSaved(true);
    };
    const handleTextAreaChange = (event) => {
      setReasonLimit(false);
      setTextAreaValue(event.target.value);
    };
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (textAreaValue.trim() !== "") {
          if (returnReasonList.length < 15) {
            setReturnReasonList((prev) => [...prev, textAreaValue]);
            setTextAreaValue("");
            setHasBeenSaved(true);
            setReasonLimit("");
          } else {
            setReasonLimit("Limit reached! Please remove one to add.");
          }
        }
      }
    };
    const handleDeleteReturnReason = (elementToDelete) => {
      if (returnReasonList.length === 1) {
        setReasonLimit("At least one reason must be present.");
        return;
      }
      const newReturnReasonList = returnReasonList.filter(
        (el) => el !== elementToDelete
      );
      setReasonLimit("");
      setHasBeenSaved(newReturnReasonList.length > 0);
      setReturnReasonList(newReturnReasonList);
    };
  
    const handleCheckboxClicked = (event) => {
      setIsActive(event.target.checked);
      setHasBeenSaved(true);
    };
  
    const handleCloseProductExclusionsModal = () => {
      setSelectedProducts(productExclusionsList);
      setOpenProductExclusionsModal(false);
      // setCurrentPage(1);
      setProductList([]);
      setSearchAddProducts("");
      resetProductStatus();
    };
  
    const handleCheckboxChange = (event, selectedEl) => {
      event.target.checked
        ? setSelectedProducts((prev) => [...prev, selectedEl])
        : setSelectedProducts((prev) =>
            prev.filter((prod) => prod.product_id !== selectedEl.product_id)
          );
    };
  
    function resetProductStatus() {
      setCurrentPage(0);
      setShopifyCursor(null);
      setIsHasMoreProducts(true);
    }
  
    const handleAddProductExclusion = () => {
      setProductExclusionsList(selectedProducts);
      setOpenProductExclusionsModal(false);
      //   setCurrentPage(1);
      setProductList([]);
      setSearchAddProducts("");
      setHasBeenSaved(true);
      populateProductList(1);
      resetProductStatus();
    };
    return (
      <>
        {isLoading && <CircularLoader />}
  
        <Grid
          container
          paddingY={2}
          borderBottom={
            data.title === "Returns Window" ? "1px solid lightgrey" : "none"
          }
        >
          <Grid
            item
            xs={12}
            md={8}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            {isActive}
            {data.title !== "Return Reason" ? (
              <Checkbox onChange={handleCheckboxClicked} checked={isActive} />
            ) : (
              <Box sx={{ px: "20px" }} />
            )}
            <Grid item>
              <Box sx={{ display: "flex", gap: 1, mb: 1, alignItems: "center" }}>
                <Typography
                  fontSize={18}
                  fontFamily={typography.fontFamilySemiBold}
                  sx={{ ...textStyle }}

                >
                  {data.title}
                </Typography>
              </Box>
              <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
                {data.subtitle}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            gap={1}
          >
            {data.selectItems && (
              <NumberInputBasic
                value={returnWindowDays}
                handleSelectChange={handleSelectChange}
                isActive={isActive}
              />
            )}
            {/* //hello */}
            {data.returnReason && (
              <FormControl fullWidth sx={{ position: "relative" }}>
                <TextField
                  value={textAreaValue}
                  onChange={handleTextAreaChange}
                  onKeyDown={handleKeyDown}
                  label={data.returnReason?.label}
                  disabled={!isActive}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: !isActive && "#1C272A1A",
                      color: palette.common.black,
                      fontSize: { xs: 16, md: 18 },
                      "&:hover fieldset": {
                        borderColor: "primary.dark",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "primary.dark",
                      },
                    },
                  }}
                  error={reasonLimit}
                />
                {reasonLimit && (
                  <Typography
                    sx={{
                      
                      fontSize: "12px",
                      color: "red",
                      position: "absolute",
                      bottom: "-15px",  
                    }}
                  >
                    {reasonLimit}
                  </Typography>
                )}
              </FormControl>
            )}
            {data.productExclusions && isActive && (
              <LoadingButton
                variant="outlined"
                size="large"
                onClick={() => setOpenProductExclusionsModal(true)}
                sx={{
                  border: "1px rgba(128, 128, 128, 0.32) solid",
                  color: "secondary.main",
                }}
              >
                Add products
              </LoadingButton>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            gap={1}
          >
            {data.productExclusions && isActive && (
              <Stack
                bgcolor={"#F4F4F4"}
                marginTop={2}
                marginLeft={7}
                borderRadius={"12px"}
                paddingY={3}
                paddingX={4}
                gap={2}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={18} sx={{...textStyle}}>
                    Products ({productExclusionsList.length})
                  </Typography>
                  <OutlinedInput
                    size="small"
                    value={searchExclusionProductValue}
                    placeholder={"Search products"}
                    sx={{
                      color: "#000",
                      overflow: "hidden",
                      width: "400px",
                      ...dropDownStyles
                    }}
                    onChange={handleSearchOnChange}
                  />
                </Stack>
                <Stack gap={1.5}>
                  {productExclusionsList &&
                    productExclusionsList
                      .filter(
                        (el) =>
                          !searchExclusionProductValue ||
                          (searchExclusionProductValue &&
                            el.name
                              .toLowerCase()
                              .includes(
                                searchExclusionProductValue.toLowerCase()
                              ))
                      )
                      .map((el) => {
                        return (
                          <Stack
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={2}
                            >
                              <Box
                                borderRadius={"10px"}
                                border={
                                  el.image_url ? "none" : "1px #d9d7d7 solid"
                                }
                                overflow={"hidden"}
                              >
                                <img
                                  src={el.image_url || "/assets/no-image.png"}
                                  alt=""
                                  width={65}
                                />
                              </Box>
                              <Typography sx={{...textStyle}}>
                                  {el.name.length > 60
                                ? el.name.slice(0, 60) + "..."
                                : el.name}
                              </Typography>

                              {/* {el.name.length > 60
                                ? el.name.slice(0, 60) + "..."
                                : el.name} */}
                            </Stack>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={1}
                              onClick={() => handleRemove(el)}
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <img src={"/assets/delete.svg"} alt="" width={20} />
                              <Typography sx={{...textStyle}}>Remove</Typography>
                            </Stack>
                          </Stack>
                        );
                      })}
                </Stack>
              </Stack>
            )}
          </Grid>
        </Grid>
  
        {data.returnReason && isActive && (
          <Grid
            container
            direction={"row"}
            gap={2}
            justifyContent={"flex-end"}
            paddingLeft={7}
            borderBottom={"1px solid lightgrey"}
            paddingBottom={3}
          >
            {returnReasonList.map((el) => (
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                paddingX={1.2}
                paddingY={0.5}
                gap={0.8}
                onClick={() => handleDeleteReturnReason(el)}
                sx={{
                  cursor: "pointer",
                  bgcolor: palette.primary.main,
                  borderRadius: "5px",
                  color: "#fff",
                }}
              >
                <Typography>{el}</Typography>
                <CloseIcon
                  fontSize="small"
                  sx={{
                    width: "17px",
                    fill: "#fff",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
  
        {openProductExclusionsModal && (
          <Modal
            open={openProductExclusionsModal}
            onClose={handleCloseProductExclusionsModal}
          >
            <Stack
              direction="column"
              gap={2}
              sx={{
                ...popUpModalStyle,
                color: "#1C272A",
                backgroundColor: "white",
                fontFamily: typography.fontFamilyRegular,
              }}
            >
              <Stack gap={2} paddingX={2.5} paddingTop={2}>
                <Typography
                  fontSize={18}
                  fontFamily={typography.fontFamilySemiBold}
                >
                  Products({productList.length})
                </Typography>
                <OutlinedInput
                  size="small"
                  value={searchAddProducts}
                  placeholder={"Search products"}
                  sx={{
                    color: "#000",
                    overflow: "hidden",
                    ...dropDownStyles
                  }}
                  onChange={(event) => setSearchAddProducts(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleSearhAddProducts}
                        sx={{
                          backgroundColor: "secondary.main",
                          color: "#fff",
                          borderRadius: 0,
                          right: "-2px",
                          "&:hover": {
                            backgroundColor: "secondary.main",
                          },
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
  
                <div
                  ref={scrollRef}
                  style={{
                    height: "250px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    padding: "10px",
                  }}
                >
                  {productList?.map((el, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems={"center"}
                      gap={1}
                    >
                      <Checkbox
                        defaultChecked={
                          productExclusionsList.filter(
                            (prod) => prod.product_id === el.product_id
                          ).length > 0
                        }
                        onChange={(event) => handleCheckboxChange(event, el)}
                      />
                      <img
                        src={el.image_url || "/assets/no-image.png"}
                        alt=""
                        width={30}
                        height={30}
                      />
                      {el.name.length > 30
                        ? el.name.slice(0, 30) + "..."
                        : el.name}
                    </Stack>
                  ))}
                </div>
              </Stack>
  
              <Stack
                direction={"row"}
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
                paddingX={2.5}
                paddingY={1.5}
                bgcolor={"#CFDEE9"}
                boxShadow="0px -4px 8px 0px #00000033"
              >
                <Typography>
                  {selectedProducts.length <= 1
                    ? `${selectedProducts.length} product`
                    : `${selectedProducts.length} products`}{" "}
                  selected
                </Typography>
                <Stack direction={"row"} gap={2}>
                  <LoadingButton
                    variant="outlined"
                    disableElevation
                    sx={{
                      width: 130,
                      borderRadius: "10px",
                      boxShadow: "none",
                      paddingY: 1,
                      fontSize: {
                        md: 15,
                        xs: 13,
                      },
                      border: "1px rgba(128, 128, 128, 0.32) solid",
                      color: "secondary.main",
                      fontFamily: typography.fontFamilySemiBold,
                    }}
                    onClick={() => [
                      setOpenProductExclusionsModal(false),
                      setSelectedProducts(productExclusionsList),
                    ]}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    disableElevation
                    type="submit"
                    sx={{
                      width: 130,
                      borderRadius: "10px",
                      boxShadow: "none",
                      backgroundColor: palette.secondary.main,
                      paddingY: 1,
                      fontSize: {
                        lg: 15,
                        md: 15,
                        xs: 13,
                        sm: 13,
                      },
                    }}
                    style={{
                      fontFamily: typography.fontFamilySemiBold,
                    }}
                    onClick={handleAddProductExclusion}
                  >
                    Done
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </Modal>
        )}
        <Dialog
          open={isOpen}
          onClose={() => setOpen(false)}
          fullWidth={true}
          maxWidth="xs"
        >
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ mb: 2 ,...textStyle}}>
              Are you sure you want to remove this item?
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "secondary.main",
                  width: "130px",
                  margin: "auto",
                }}
                onClick={() => setOpen(false)}
              >
                No
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "secondary.main",
                  width: "130px",
                  margin: "auto",
                }}
                onClick={() => {
                  handleRemoveProduct(productToRemove);
                  setOpen(false);
                  setProductToRemove(null);
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  };
  
  export default SelectableItem;
  