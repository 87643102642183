import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    SelectedTenant: {},
};

  export const filteredTenant = createSlice({
    name: 'tenant/selectedTenant',
    initialState,
    reducers: {
        updateTenant : (state, action) => {
            state.SelectedTenant = {...action.payload};
        },
        clearTenant : (state) => {
            state.SelectedTenant = initialState.SelectedTenant
        }
    }
  });

export const {updateTenant, clearTenant} = filteredTenant.actions;
export default filteredTenant.reducer;