import { useEffect, useState } from "react";
import {
  DeclineReturn,
  ApproveReturn,
  CancelReturn,
  CloseReturn,
  CreateRefund,
  getBoxConfigDetails,
  generateLabelApi,
} from "src/features/managereturns/returnsApi";
import { LABEL_PROCESS_TYPE } from "./Labels";
import {
  Button,
  Box,
  MenuItem,
  styled,
  Menu,
  alpha,
  Dialog,
  IconButton,
  Typography,
  TextField,
  ListItemText,
  ListItemIcon,
  Grid,
  Stack,
  InputAdornment,
  Select,
  Tooltip,
  Divider,
} from "@mui/material";
import CustomTextField from "./common/CustomTextField";
import CustomSelect from "./common/CustomSelect";
import "./style/styles.css";
import { LoadingButton } from "@mui/lab";
import palette from "src/theme/palette";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import CircularLoader from "src/loader/circular/CircularLoader";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import typography from "src/theme/typography";
// import { selectSelectedMethod } from "src/features/returns/returnMethodSlice";
// import { selectLabelSizeOption } from "src/features/returns/stepperFormSlice";
import { useDispatch, useSelector } from "react-redux";
// import { getLabelByValue } from "src/utils/labelUtils";
import { getSpecificReturnConfig } from "src/features/managereturns/returnsApi";
import { fetchTenants } from "src/features/user/userApi";
import BoxConfigDialog from "./BoxConfigDialog";


const Actions = ({ returnDetails, summaryInfo, formData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAcceptAction, setIsAcceptAction] = useState(false);
  const open = Boolean(anchorEl);
  const [buttonLabel, setButtonLabel] = useState("Reject");
  const [isApprove, setApproveStatus] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [label, setLabel] = useState("");
  const [modalText, setModalText] = useState("");
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [modalSuccessText, setModalSuccessText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [returnRuleData, setReturnRuleData] = useState([]);
  const userInfo = useAppSelector(getUserInfo);
//   const selectedMethod = useSelector(selectSelectedMethod);
const [tenantsList, setTenantsList] = useState(null);

const filteredTenant = useSelector(
  (state) => state.selectedTenant.SelectedTenant
);
const { tenant_id, tenant_name,user_id,is_demo_user } = useSelector((state) => state.tenants);
const enhancedUserInfo = {
  accessToken: userInfo?.accessToken,
  tenant_id,
  tenant_name,
  user_id,
  is_demo_user,
};

  const navigate = useNavigate();

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleReject = () => {
    setModalOpen(true);
    setLabel("Reject");
  };

  const handleRefund = () => {
    setModalOpen(true);
    setModalText(
      formData?.comment
        ? "Are you sure you want to issue a partial deduction for this returned order ?"
        : "Are you sure you want to refund ?"
    );
    setLabel("Refund");
  };
  

useEffect(() => {
  const tenantList = fetchTenants(userInfo.accessToken);
  tenantList.then((res) => {
      setTenantsList(res?.data);
  });
}, []);

  useEffect(() => {
    const populateData = async () => {
      try {
        const response = await getSpecificReturnConfig(
          enhancedUserInfo,
          "return_method"
        );
        if (response?.statusCode === 200) {
          const data = response.data;
          setReturnRuleData(data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    populateData();
  }, []);
  const approveReturnRequest = (payload = {}) => {
    // const updatedUserInfo = {
    //   ...userInfo,
    //   tenant: filteredTenant,
    // };
    ApproveReturn({ return_id: returnDetails._id, ...payload }, enhancedUserInfo)
      .then(() => {
        setIsLoading(false);
        setSuccessModalOpen(true);
        setModalSuccessText(
          "Return has been accepted. The return label and instructions are emailed to the customer with RMA based on the shipping refund and return method configuration set by the admin. "
        );
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setSuccessModalOpen(true);
        setModalSuccessText("Sorry! Unable to process your request");
      });
  };
  const handleLabelGenerateSubmit = (data) => {
    approveReturnRequest({
      label_type: data.label_type,
      label_info: {
        carrier: "usps",
        ...data,
      },
    });
  };
  const handleSubmit = ({ isLabelGenerate = false }) => {
    setIsLoading(true);
    setModalOpen(false);
    if (label === "Approve") {
      const { label_type, return_flow } = returnDetails;
      if (
        label_type === LABEL_PROCESS_TYPE.OWN_LABEL ||
        label_type === LABEL_PROCESS_TYPE.TWO_WAY_LABEL
      ) {
        approveReturnRequest();
      } else if (
        label_type === LABEL_PROCESS_TYPE.PREPAID_PAID_LABEL ||
        label_type == LABEL_PROCESS_TYPE.PREPAID_FREE_LABEL
      ) {
        if (return_flow === "customer_support") {
          approveReturnRequest();
        } else {
          setIsAcceptAction(true);
        }
      } else if (label_type == null) {
        const rule_type = returnRuleData.value;
        if (rule_type === "prepaid_free_label") {
          setIsAcceptAction(true);
          setIsLoading(false);
        } else {
          approveReturnRequest({
            label_type: LABEL_PROCESS_TYPE.TWO_WAY_LABEL,
          });
        }
      }
      // if(isLabelGenerate===true && selectedMethod=='prepaid_free_label')
      // {
      //     setIsLoading(false);
      //     setSuccessModalOpen(true);
      //     setModalSuccessText(
      //         "Tracking label has been Generated and Return has been accepted. The return label and instructions are emailed to the customer with RMA based on the shipping refund and return method configuration set by the admin. "
      //     );
      //     // ApproveReturn({return_id: returnDetails._id}, userInfo)
      //     //     .then(() => {
      //     //         setIsLoading(false);
      //     //         setSuccessModalOpen(true);
      //     //         setModalSuccessText(
      //     //             "Return has been accepted. The return label and instructions are emailed to the customer with RMA based on the shipping refund and return method configuration set by the admin. "
      //     //         );
      //     //     })
      //     //     .catch((e) => {
      //     //         setIsLoading(false);
      //     //         setSuccessModalOpen(true);
      //     //         setModalSuccessText(e.response.data.statusMessage);
      //     //     });
      // }
      // else if(isLabelGenerate==false && selectedMethod !='prepaid_free_label')
      // {
      //     setIsLoading(false);
      //     setSuccessModalOpen(true);
      //     setModalSuccessText(
      //         "Tracking label has been Generated and Return has been accepted. The return label and instructions are emailed to the customer with RMA based on the shipping refund and return method configuration set by the admin. "
      //     );
      //     // ApproveReturn({return_id: returnDetails._id}, userInfo)
      //     //     .then(() => {
      //     //         setIsLoading(false);
      //     //         setSuccessModalOpen(true);
      //     //         setModalSuccessText(
      //     //             "Return has been accepted. The return label and instructions are emailed to the customer with RMA based on the shipping refund and return method configuration set by the admin. "
      //     //         );
      //     //     })
      //     //     .catch((e) => {
      //     //         setIsLoading(false);
      //     //         setSuccessModalOpen(true);
      //     //         setModalSuccessText(e.response.data.statusMessage);
      //     //     });
      // }
      // else
      // {
      //     setIsLoading(false);
      //     //setSuccessModalOpen(false);
      //     setIsAcceptAction(true);
      // }
      //setModalOpen(false);
    }
    if (label === "Cancel") {
      DeclineReturn({ return_id: returnDetails._id, decline_reason: "OTHER" })
        .then(() => {
          setIsLoading(false);
          setSuccessModalOpen(true);
          setModalSuccessText("Successfully Cancelled!");
        })
        .catch((e) => {
          setIsLoading(false);
          setSuccessModalOpen(true);
          setModalSuccessText(e.response.data.statusMessage);
        });
    }
    if (label === "Close") {
      CloseReturn({ return_id: returnDetails._id })
        .then(() => {
          setIsLoading(false);
          setSuccessModalOpen(true);
          setModalSuccessText("Successfully Closed!");
        })
        .catch((e) => {
          setIsLoading(false);
          setSuccessModalOpen(true);
          setModalSuccessText(e.response.data.statusMessage);
        });
    }
    if (label === "Reject") {
      // const updatedUserInfo = {
      //   ...userInfo,
      //   tenant: filteredTenant,
      // };
      DeclineReturn(
        {
          return_id: returnDetails._id,
          decline_reason: rejectReason,
        },
        enhancedUserInfo
      )
        .then(() => {
          setIsLoading(false);
          setSuccessModalOpen(true);
          setModalSuccessText("Successfully Rejected!");
        })
        .catch((e) => {
          setIsLoading(false);
          setSuccessModalOpen(true);
          setModalSuccessText(e.response.data.statusMessage);
        });
    }
    if (label === "Refund") {
      let data = {
        return_id: returnDetails._id,
        items_subtotal: summaryInfo.return_items_amount,
        order_discount: 0,
        tax_amount: summaryInfo.tax_amount,
        shipping_charges: summaryInfo.shipping_maximum_refundable,
        adjustment_amount: 0,
        deduction: 0,
        grand_total: (
          (parseFloat(summaryInfo.return_items_amount) || 0) +
          (parseFloat(summaryInfo.tax_amount) || 0) +
          (parseFloat(summaryInfo.shipping_maximum_refundable) || 0)
        ).toFixed(2),
      };
      if (summaryInfo?.partial_amount > 0) {
        data = {
          ...data,
          label_fees: parseInt(summaryInfo.label_fees || 0),
          is_partial: true,
          partial_reason: formData.comment,
          partial_percentage: Number(formData.partialDeduction),
          partial_amount: Number(summaryInfo?.partial_amount),
        };
      }
      CreateRefund(data,{ ...userInfo, tenant: filteredTenant })
        .then(() => {
          setIsLoading(false);
          setSuccessModalOpen(true);
          setModalSuccessText("Successfully Refunded!");
        })
        .catch((e) => {
          setIsLoading(false);
          setSuccessModalOpen(true);
          // setModalSuccessText(e.response.data.statusMessage);
        });
    }
  };

  const handleApprove = () => {
    setModalOpen(true);
    setModalText("Are you sure you want to accept this return ?");
    setLabel("Approve");
  };

  const handleCancel = () => {
    setModalOpen(true);
    setModalText("Are you sure you want to cancel this return ?");
    setLabel("Cancel");
  };

  const handleCloseReturn = () => {
    setModalOpen(true);
    setLabel("Close");
    setModalText("Are you sure you want to close this return ?");
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      {isAcceptAction && (
        <BoxConfigDialog
          onLabelGenerated={handleLabelGenerateSubmit}
          returnDetails={returnDetails}
          userInfo={userInfo}
          isAcceptAction={isAcceptAction}
          setIsAcceptAction={setIsAcceptAction}
          returnRuleData={returnRuleData}
          labelButton={"Generate Label"}
        />
      )}

      {returnDetails.return_status === "Canceled" ||
      returnDetails.return_status === "Rejected" ||
      returnDetails.return_status === "Refund" ? (
        <Box></Box>
      ) : (
        <Box
          sx={{ display: "flex", gap: 2, justifyContent: "flex-end", mt: 3 }}
          // sx={{display: "flex", gap: 2, justifyContent: "flex-end", mt: 3,flexDirection:'column'}}
        >
          {returnDetails.return_status === "Initiated" && (
            <Button
              variant="contained"
              fullWidth
              sx={{ boxShadow: "none", backgroundColor: "secondary.main" }}
              onClick={handleApprove}
            >
              Accept
            </Button>
          )}
          {returnDetails.return_status === "Accepted" && (
            <Button
              variant="contained"
              fullWidth
              sx={{ boxShadow: "none", backgroundColor: "secondary.main" }}
              onClick={handleRefund}
            >
              Refund
            </Button>
          )}
          {/* {returnDetails.return_status === "Accepted" && (
            <Button
              variant="contained"
              fullWidth
              sx={{boxShadow: "none", backgroundColor: 'secondary.main'}}
              // onClick={handleRefund}
            >
              Partial Refund
            </Button>
          )} */}
          {returnDetails.return_status === "Accepted" && (
            <Button
              variant="outlined"
              fullWidth
              sx={{
                boxShadow: "none",
                borderColor: "secondary.main",
                color: "secondary.main",
              }}
              onClick={handleReject}
            >
              Reject
            </Button>
          )}
        </Box>
      )}
      <Dialog
        fullWidth={true}
        open={isModalOpen}
        // onClose={() => setModalOpen(false)}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          setModalOpen(false);
        }}
        maxWidth="xs"
      >
        <Box sx={{ padding: "20px" }}>
          {label === "Reject" ? (
            <Box>
              <Typography sx={{ color: "#000", mb: 2 }}>
                Reason for rejection
              </Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                fullWidth
                onChange={(e) => setRejectReason(e.target.value)}
                rows={2}
                placeholder="Add a Comment"
                className="colorBlack"
              />
              <Typography sx={{ color: "#AAAAAA", fontSize: "10px", mt: 1 }}>
                Please note that this comment will be displayed on the customer
                tracking portal and will also appear in the TimeLine history.
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{ color: "#000", textAlign: "center", fontSize: "16px" }}
            >
              {modalText}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "130px",
                boxShadow: "none",
                mt: 2,
                borderColor: "secondary.main",
                color: "secondary.main",
              }}
              onClick={() => {
                setModalOpen(false);
                setIsAcceptAction(false);
              }}
            >
              {label === "Reject" ? "Cancel" : "No"}
            </Button>
            {label !== "Reject" ? (
              <Button
                variant="contained"
                sx={{
                  width: "130px",
                  boxShadow: "none",
                  mt: 2,
                  backgroundColor: "secondary.main",
                }}
                onClick={handleSubmit}
              >
                Yes
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={rejectReason ? false : true}
                sx={{
                  width: "130px",
                  boxShadow: "none",
                  mt: 2,
                  backgroundColor: "secondary.main",
                }}
                onClick={handleSubmit}
              >
                Reject
              </Button>
            )}
          </Box>
        </Box>
      </Dialog>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Dialog
          fullWidth={true}
          open={isSuccessModalOpen}
          onClose={() => setSuccessModalOpen(false)}
          maxWidth="xs"
        >
          <Box sx={{ padding: "20px" }}>
            <Typography
              sx={{ color: "#000", textAlign: "center", fontSize: "14px" }}
            >
              {modalSuccessText}
            </Typography>
            {label === "Approve" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: "14px",
                    mt: 2,
                    // borderRight: "2px solid #D9D9D9",
                    padding: "0 20px",
                  }}
                >
                  Return Order Id : 35645742324
                </Typography>
                {/* <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: "14px",
                    mt: 2,
                    padding: "0 20px",
                  }}
                >
                  {" "}
                  RMA No: 5455656
                </Typography> */}
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                sx={{ width: "130px", borderColor: "secondary.main" }}
                onClick={() => [
                setSuccessModalOpen(false),
                navigate("/dashboard/manage-returns/"),
                ]}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default Actions;
