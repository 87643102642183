const CustomerLabelCreatedIcon = ({ color,height,width }) => {
    return (
      <svg
        width={width||"50"}
        height={height||"40"}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.7506 43.75H10.4173C10.1437 43.7502 9.87271 43.6964 9.61987 43.5917C9.36704 43.4871 9.13731 43.3336 8.94382 43.1402C8.75034 42.9467 8.59689 42.7169 8.49225 42.4641C8.3876 42.2113 8.33383 41.9403 8.33398 41.6667V39.5833C8.33398 39.0308 8.55348 38.5009 8.94418 38.1102C9.33488 37.7195 9.86478 37.5 10.4173 37.5C10.9699 37.5 11.4998 37.7195 11.8905 38.1102C12.2812 38.5009 12.5007 39.0308 12.5007 39.5833H41.6673V10.4167H12.5007C12.5007 10.9692 12.2812 11.4991 11.8905 11.8898C11.4998 12.2805 10.9699 12.5 10.4173 12.5C9.86478 12.5 9.33488 12.2805 8.94418 11.8898C8.55348 11.4991 8.33398 10.9692 8.33398 10.4167V8.33333C8.33383 8.0597 8.3876 7.78872 8.49225 7.53589C8.59689 7.28306 8.75034 7.05333 8.94382 6.85984C9.13731 6.66635 9.36704 6.5129 9.61987 6.40826C9.87271 6.30362 10.1437 6.24984 10.4173 6.25H43.7506C44.0243 6.24984 44.2953 6.30362 44.5481 6.40826C44.8009 6.5129 45.0307 6.66635 45.2241 6.85984C45.4176 7.05333 45.5711 7.28306 45.6757 7.53589C45.7804 7.78872 45.8341 8.0597 45.834 8.33333V41.6667C45.8341 41.9403 45.7804 42.2113 45.6757 42.4641C45.5711 42.7169 45.4176 42.9467 45.2241 43.1402C45.0307 43.3336 44.8009 43.4871 44.5481 43.5917C44.2953 43.6964 44.0243 43.7502 43.7506 43.75Z"
          fill={color || "white"}
        />
        <path
          d="M31.2493 8.33337V20.8334L27.0827 16.6667L22.916 20.8334V8.33337H31.2493Z"
          fill={color || "white"}
        />
        <path
          d="M10.4167 33.3333C10.143 33.3336 9.87206 33.2798 9.61928 33.1751C9.36651 33.0703 9.13693 32.9166 8.94375 32.7229L1.2207 24.9999L8.94375 17.277C9.33559 16.8922 9.86355 16.6777 10.4128 16.6802C10.962 16.6827 11.488 16.902 11.8763 17.2903C12.2646 17.6786 12.4839 18.2046 12.4864 18.7538C12.4889 19.303 12.2744 19.831 11.8896 20.2229L7.11262 24.9999L11.8897 29.777C12.181 30.0684 12.3793 30.4396 12.4597 30.8437C12.54 31.2477 12.4988 31.6666 12.3411 32.0472C12.1834 32.4278 11.9165 32.7532 11.5739 32.9821C11.2314 33.211 10.8287 33.3332 10.4167 33.3333Z"
          fill={color || "white"}
        />
        <path
          d="M20.8327 27.0833H6.24935C5.69681 27.0833 5.16691 26.8638 4.77621 26.4731C4.38551 26.0824 4.16602 25.5525 4.16602 25C4.16602 24.4474 4.38551 23.9175 4.77621 23.5268C5.16691 23.1361 5.69681 22.9166 6.24935 22.9166H20.8327C21.3852 22.9166 21.9151 23.1361 22.3058 23.5268C22.6965 23.9175 22.916 24.4474 22.916 25C22.916 25.5525 22.6965 26.0824 22.3058 26.4731C21.9151 26.8638 21.3852 27.0833 20.8327 27.0833Z"
          fill={color || "white"}
        />
      </svg>
    );
  };
  
  export default CustomerLabelCreatedIcon;
  