import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Grid,
  Stack,
  Modal,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { selectPaidLabelReasons } from "src/features/returns/stepperFormSlice";
import {
  selectCustomerManagedReturn,
  setCustomerManagedReturn,
} from "src/features/returns/returnMethodSlice";
import { LoadingButton } from "@mui/lab";
import palette from "src/theme/palette";
import typography from "src/theme/typography";
import ModalComponent from "src/components/modal/ModalComponent";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { updateSpecificReturnConfig } from "src/features/returnConfiguration/returnRulesApi";
import { LABELS } from "../Labels";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import { emailConfigurationTexts } from "src/pages/EmailTemplate/EmailConfigurationTexts";
import EmailTemplateItem from "src/pages/EmailTemplate/EmailTemplateItem";
import {
  getEmailTemplatesConfig,
  getEmailTemplatesList,
  retrieveLogoUrl,
} from "src/features/returnConfiguration/emailTemplatesApi";
import PreviewTemplate from "../../EmailTemplate/PreviewTemplate";
import EmailTemplateEdition from "src/pages/EmailTemplate/EmailTemplateEdition";
import { getUserNameById } from "src/utils/getUserNameById";
import moment from "moment";
import { formatDateTime } from "src/utils/labelUtils";
import { publishEmailTemplate } from "src/utils/emailTemplates/functions";

const popUpModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "auto", md: "auto" },
  borderRadius: "20px",
  background: "#FFF",
  overflowX: "hidden",
  outline: "none",
};

const EmailConfigStepper = ({
  handleNext,
  handleBack,
  customerManagedReturn,
  setHasBeenSaved,
  openEditTemplateUI,
  setOpenEditTemplateUI,
  emailTemplateItemData,
  setEmailTemplateItemData,
  logoFile,
  setLogoFile,
  emailTemplateLayout,
  setEmailTemplateLayout,
  emailTemplateContent,
  setEmailTemplateContent,
  emailTemplateSubject,
  setEmailTemplateSubject,
  refresh,
  setRefresh,
  emailTemplateBackendReponse,
  setEmailTemplateBackendReponse,
}) => {
 
  const [showCustomManagerReturn, setShowCustomManagerReturn] = useState(true);
  const [openConfirmSaveModal, setOpenConfirmSaveModal] = useState(false);
  const [emailConfigType, setEmailConfigType] = useState("freeLabel");

  const userInfo = useAppSelector(getUserInfo);
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();

  const paidConfig = useSelector(selectPaidLabelReasons);

  const [openPreview, setOpenPreview] = useState(false);
  const [templateNameSelected, setTemplateNameSelected] = useState(false);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [templateLastUpdateList, setTemplateLastUpdateList] = useState([]);
  const { tenant_id, tenant_name,user_id,is_demo_user } = useSelector((state) => state?.tenants);
const tenantsData = useSelector((state) => state.tenants);  




  const enhancedUserInfo = {
    accessToken: userInfo?.accessToken,
    tenant_id,
    tenant_name,
    user_id,
    is_demo_user,
  };  

  const handleCustomManagerReturn = () => {
    setShowCustomManagerReturn(!showCustomManagerReturn);
    setEmailConfigType("customManagerReturn");
  };

  const handleSaveReturnMethod = async () => {
    dispatch(incrementAwaitingApiCounter());

    const body = {
      rule_name: "return_method",
      value: LABELS.PREPAID_LABEL,
      is_customer_manage_return: customerManagedReturn,
      warehouse_address: {
        first_name: form.warehouse.firstName,
        last_name: form.warehouse.lastName,
        city: form.warehouse.city,
        state: form.warehouse.state,
        country: form.warehouse.country,
        zip_code: form.warehouse.zipCode,
        address_line: form.warehouse.addressLine,
      },
      carrier_mail_config: {
        carrier: form.carrier.carrier,
        mail_class: form.carrier.mailClass,
      },
      box_config: form.boxes.map((el) => ({
        height: el.height,
        width: el.width,
        length: el.length,
        config_name: el.configName,
        box_weight: +el.box_weight,
        ...(el?.isActive && {
          is_active: el.isActive,
        }),
      })),
      label_config: {
        label_size: form.label.labelSize,
        label_type: form.label.labelType,
      },
      paid_label_config: form.paidLabelConfig.reasons,
    };

    try {
      const response = await updateSpecificReturnConfig(enhancedUserInfo, body);

      if (response.statusCode === 200) {
        dispatch(setCustomerManagedReturn(customerManagedReturn));

        emailTemplateItemData.map(async (el) => {
          const publishEmailTemplateResponse = await publishEmailTemplate(
            enhancedUserInfo,
            el.templateName,
            emailTemplateLayout,
            emailTemplateSubject,
            emailTemplateContent,
            retrieveLogoUrl,
            logoFile
          );
          return publishEmailTemplateResponse;
        });

        setRefresh(true);

        setHasBeenSaved(false);
        handleNext();
      }
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
    }

    setHasBeenSaved(false);
  };

  const handleLastUpdatedInfo = async (currentTextData) => {
    if (templateLastUpdateList?.length > 0) {
      const currentTemplate = templateLastUpdateList.find(
        (updateInfo) =>
          updateInfo.template_name === currentTextData.templateName
      );
      if (currentTemplate) {
        const updatedById =
          currentTemplate.updated_by || currentTemplate.created_by;
        const updatedAt =
          currentTemplate.updated_at || currentTemplate.created_at;

        const updatedByName =
          (await getUserNameById(enhancedUserInfo, updatedById)) || "Unknown User";

        return `${updatedByName} on ${moment
          .unix(updatedAt)
          .tz("America/Los_Angeles")
          .format("MMM DD, YYYY")}`;
      } else return "No updated yet";
    } else return "No updated yet";
  };

  useEffect(() => {
    const getEmailTemplateInfo = async () => {
      dispatch(incrementAwaitingApiCounter());

      try {
        const configResponse = await getEmailTemplatesConfig(enhancedUserInfo);
        const templatesResponse = await getEmailTemplatesList(enhancedUserInfo);

        if (
          configResponse?.statusCode === 200 &&
          templatesResponse?.statusCode === 200
        ) {
          const configData = configResponse.data;

          setLogoFile(configData?.value?.brand_logo || "/assets/no-img.svg");

          const templateData = templatesResponse.data;
          setEmailTemplateList(templateData);

          const lastUpdateDate = templateData.map((el) => ({
            template_name: el.template_name,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
          }));
          setTemplateLastUpdateList(lastUpdateDate);
        }

        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        console.error("Error fetching template info:", error);
        dispatch(decrementAwaitingApiCounter());
      }
    };

    getEmailTemplateInfo();
  }, [refresh, openEditTemplateUI]);

  useEffect(() => {
    const updateSelectableItemData = async () => {
      // dispatch(incrementAwaitingApiCounter());

      let updatedData = [
        emailConfigurationTexts.freeLabel,
        ...(paidConfig?.length > 0 ? [emailConfigurationTexts.paidLabel] : []),
        ...(customerManagedReturn
          ? [emailConfigurationTexts.customerManagedReturns]
          : []),
      ];

      updatedData = await Promise.all(
        updatedData.map(async (el) => {
          const lastUpdated = await handleLastUpdatedInfo(el);
          return { ...el, lastUpdatedInfo: lastUpdated };
        })
      );

      setEmailTemplateItemData(updatedData);

      // dispatch(decrementAwaitingApiCounter());
    };

    updateSelectableItemData();
  }, [
    paidConfig,
    customerManagedReturn,
    emailConfigurationTexts,
    templateLastUpdateList,
  ]);

  return (
    <>
      {openEditTemplateUI ? (
        <Stack gap={2} padding={5} paddingTop={2}>
          <EmailTemplateEdition
            template_name={openEditTemplateUI}
            setOpenEditTemplateUI={setOpenEditTemplateUI}
            emailTemplateItemData={emailTemplateItemData}
            logoFile={logoFile}
            setRefresh={setRefresh}
            emailTemplateLayout={emailTemplateLayout}
            setEmailTemplateLayout={setEmailTemplateLayout}
            emailTemplateContent={emailTemplateContent}
            setEmailTemplateContent={setEmailTemplateContent}
            emailTemplateSubject={emailTemplateSubject}
            setEmailTemplateSubject={setEmailTemplateSubject}
            emailTemplateBackendReponse={emailTemplateBackendReponse?emailTemplateBackendReponse:''}
            setEmailTemplateBackendReponse={emailTemplateBackendReponse?setEmailTemplateBackendReponse:''}
          />
        </Stack>
      ) : (
        <Stack gap={4} padding={5}>
          <Stack gap={1}>
            <Typography variant="h5" textAlign={"center"}
                  sx={{
                color: "#002F43", 
                '&:hover': {
                  backgroundColor: '#E3F2FD',
      },
    }}>
              Email configuration
              <Tooltip
                title="You can edit this later from Email templates."
                placement="right"
                arrow
              >
                <IconButton>
                  <InfoOutlinedIcon fontSize="small" color="action" />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body2" textAlign={"center"} color={"#AAAAAA"}>
              Select your email configuration for your return label
            </Typography>
          </Stack>

          <Stack>
            {emailTemplateItemData.map((el, index) => (
              <EmailTemplateItem
                key={index}
                data={el}
                setOpenEditTemplateUI={setOpenEditTemplateUI}
                setOpenPreview={setOpenPreview}
                setTemplateNameSelected={setTemplateNameSelected}
                emailTemplateList={emailTemplateList}
                lastUpdatedInfo={el?.lastUpdatedInfo}
                showBtns
                hideCheckbox
                hideDivider={index === emailTemplateItemData.length - 1}
              />
            ))}
          </Stack>

          <Stack gap={1} direction={"row"} justifyContent={"flex-end"}>
            <ArrowCircleLeftIcon
              onClick={handleBack}
              sx={{
                color: "#002F43",
                cursor: "pointer",
                width: "50px !important",
                height: "50px !important",
                transition: "transform 0.3s ease-in-out",
              }}
            />
            <CheckCircleIcon
              onClick={() => setOpenConfirmSaveModal(true)}
              sx={{
                color: "#002F43",
                cursor: "pointer",
                width: "50px !important",
                height: "50px !important",
                transition: "transform 0.3s ease-in-out",
              }}
            />
          </Stack>

          {openConfirmSaveModal && (
            <ModalComponent
              openModal={openConfirmSaveModal}
              handleCloseModal={() => setOpenConfirmSaveModal(false)}
              title={"Are you sure you want to make this change?"}
              btnText={"Proceed"}
              cancelBtnText={"Cancel"}
              handleBtnClick={handleSaveReturnMethod}
              description={
                <Typography width={600}>
                  {`Note: Returns from [${formatDateTime(
                    new Date()
                  )} of change] will
                  reflect the Prepaid label setting.`}
                </Typography>
              }
            />
          )}
        </Stack>
      )}

      <Modal open={openPreview} onClose={() => setOpenPreview(false)}>
        <Box sx={popUpModalStyle}>
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: 11,
              right: 11,
              zIndex: 2,
            }}
            onClick={() => setOpenPreview(false)}
          >
            <img width={25} src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>
          <PreviewTemplate
            template_name={templateNameSelected}
            logoFile={logoFile}
            isRenderingFromModal
          />
        </Box>
      </Modal>
    </>
  );
};

export default EmailConfigStepper;
