import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Radio,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Alert,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import typography from "src/theme/typography";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import InfoIcon from "@mui/icons-material/Info";
import BoxDeleteIcon from "../BoxDeleteIcon";
import BoxEditIcon from "../BoxEditIcon";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { addBox, updateActiveBox } from "src/features/returns/stepperFormSlice";
import ModalComponent from "src/components/modal/ModalComponent";

const isDecimalValid = (value) => /^\d+(\.\d{1,2})?$/.test(value);
const isWeightValid = (value) => value <= 70;

const styleInput = {
  "& .MuiOutlinedInput-root": {
    paddingRight: 0, 
    "& fieldset": {
      borderColor: "#AAAAAA", 
    },
    "&:hover fieldset": {
      borderColor: "black", 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#002F43", 
    },
  },
  "& .MuiInputBase-input": {
    fontSize: 14,
    color: "#002F43",
  },
  "& .MuiFormLabel-root": {
    fontSize: 14,
    color: "#AAAAAA",
  },
};

const BoxDimensions = ({
  length,
  width,
  height,
  box_weight,
  errors,
  onChange,
  onBlur,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <TextField
          label="Length"
          value={length}
          // onChange={(e) => onChange("length", e.target.value)}
          onChange={(e) => {
            if (e.target.value.length <= 5) {
              onChange("length", e.target.value);
            }
          }}
          onBlur={() => onBlur("length")}
          error={!!errors.length}
          helperText={errors.length}
          variant="outlined"
          fullWidth
          type="number"
          sx={{
            ...styleInput,
           }}
          inputProps={{ maxLength: 5 }}
          InputProps={{
            maxLength: 5,
            fontSize: 12,
            endAdornment: (
              <InputAdornment
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    color: "#AAAAAA",
                  },
                  backgroundColor: (theme) => theme.palette.divider,
                  minHeight: "100%",
                  padding: "26.5px 14px",
                  borderTopRightRadius: (theme) =>
                    theme.shape.borderRadius + "px",
                  borderBottomRightRadius: (theme) =>
                    theme.shape.borderRadius + "px",
                }}
                position="end"
              >
                In
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Width"
          value={width}
          // onChange={(e) => onChange("width", e.target.value)}
          onChange={(e) => {
            if (e.target.value.length <= 5) {
              onChange("width", e.target.value);
            }
          }}
          onBlur={() => onBlur("width")}
          error={!!errors.width}
          helperText={errors.width}
          variant="outlined"
          fullWidth
          type="number"
          sx={{
            ...styleInput,
           }}
          inputProps={{ maxLength: 5 }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    color: "#AAAAAA",
                  },
                  backgroundColor: (theme) => theme.palette.divider,
                  padding: "26.5px 14px",
                  borderTopRightRadius: (theme) =>
                    theme.shape.borderRadius + "px",
                  borderBottomRightRadius: (theme) =>
                    theme.shape.borderRadius + "px",
                }}
                position="end"
              >
                In
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Height"
          value={height}
          // onChange={(e) => onChange("height", e.target.value)}
          onChange={(e) => {
            if (e.target.value.length <= 5) {
              onChange("height", e.target.value);
            }
          }}
          onBlur={() => onBlur("height")}
          error={!!errors.height}
          helperText={errors.height}
          variant="outlined"
          fullWidth
          type="number"
          sx={{
            ...styleInput,
           }}
          inputProps={{ maxLength: 5 }}
          InputProps={{
            maxLength: 5,
            endAdornment: (
              <InputAdornment
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    color: "#AAAAAA",
                  },
                  backgroundColor: (theme) => theme.palette.divider,
                  padding: "26.5px 14px",
                  borderTopRightRadius: (theme) =>
                    theme.shape.borderRadius + "px",
                  borderBottomRightRadius: (theme) =>
                    theme.shape.borderRadius + "px",
                }}
                position="end"
              >
                In
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Box weight"
          value={box_weight}
          onChange={(e) => onChange("box_weight", e.target.value)}
          onBlur={() => onBlur("box_weight")}
          error={!!errors.box_weight}
          helperText={errors.box_weight}
          variant="outlined"
          fullWidth
          type="number"
          sx={{
            ...styleInput,
           }}
          inputProps={{ maxLength: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    color: "#AAAAAA",
                  },
                  backgroundColor: (theme) => theme.palette.divider,
                  padding: "26.5px 14px",
                  borderTopRightRadius: (theme) =>
                    theme.shape.borderRadius + "px",
                  borderBottomRightRadius: (theme) =>
                    theme.shape.borderRadius + "px",
                }}
                position="end"
              >
                Lbs
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export const BoxComponent = ({
  box,
  onEdit = () => {},
  onDelete = () => {},
  selected = false,
  onSelect = () => {},
  isDeletable,
  isPreviewShow = false,
}) => (
<>  

  <Stack
    sx={{
      width: "180px",
      height: "150px",
      padding: 0.5,
      color: selected ? "#FFFFFF" : "1C272A",
      backgroundColor: selected ? "#256FA5" : "#CFDEE9",
      cursor: "pointer",
      position: "relative",
      boxShadow: "0px 6px 30px 0px #00000014",
      borderRadius: "10px",
    }}
    onClick={(e) => {
      if (
        e.target.tagName !== "BUTTON" &&
        e.target.tagName !== "svg" &&
        !e.target.closest(".box-edit-icon")
      ) {
        onSelect();
      }
    }}
  >
    {/* //---------------------- */}
    {selected && !isPreviewShow ? (
      <>
        <Box display="flex" justifyContent="flex-end" className="box-edit-icon">
          <img
            style={{ position: "absolute", top: "8px", left: "8px" }}
            src="/assets/circleselect.svg"
          />
          <IconButton onClick={onEdit}>
            <BoxEditIcon />
          </IconButton> 
        </Box>
      </>
    ) : (
      <Box display="flex" justifyContent="flex-end" className="box-edit-icon">
        {isPreviewShow && selected && (
          <Typography
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              background: "#CFDEE9",
              color: "black",
              paddingX: 0.8,
              paddingY: 0.4,
              fontSize: 10,
              borderRadius: "30px",
              lineHeight: "normal",
            }}
          >
            Default
          </Typography>
        )}

        {!isPreviewShow && (
          <IconButton onClick={onEdit}>
            <BoxEditIcon color="#002F43" />
          </IconButton>
        )}

        {isDeletable && (
          <IconButton onClick={onDelete}>
            <BoxDeleteIcon />
          </IconButton>
        )}
      </Box>
    )}


    {/* //------------------------- */}
    <Stack flex={1} justifyContent={"center"} gap={2} textAlign={"center"} >
      <Typography
        variant="h6"
        sx={{ fontSize: 16, fontFamily: typography.fontFamilySemiBold, color:selected?'':'#002F43'}}
      >
        {box.configName}
      </Typography>
      <Stack gap={1}>
        <Typography
          sx={{ fontSize: 12 ,color:selected?'':'#002F43'}}
        >{`${box?.length}" (L) x ${box?.width}" (W) x ${box?.height}" (H)`}</Typography>
        <Typography
          sx={{ fontSize: 12,color:selected?'':'#002F43' }}
        >{`Box weight: ${box?.box_weight} lbs`}</Typography>
      </Stack>
    </Stack>
  </Stack>
  </>
);

const BoxConfigStepper = ({ handleNext, handleBack }) => {
  const [selectedBox, setSelectedBox] = useState(0);
  const [customBoxes, setCustomBoxes] = useState([]);
  const [predefinedBoxes, setPredefinedBoxes] = useState([
    {
      configName: "Small flat rate box",
      length: 8.68,
      width: 5.44,
      height: 1.44,
      box_weight: 0.5,
    },
    {
      configName: "Medium flat rate box",
      length: 11,
      width: 8.5,
      height: 5.5,
      box_weight: 0.1,
    },
    {
      configName: "Large flat rate box",
      length: 12,
      width: 12,
      height: 5.5,
      box_weight: 0.15,
    },
  ]);
  const { boxConfigStore, activeBox } = useSelector((state) => ({
    boxConfigStore: state.form.boxes,
    activeBox: state.form.activeBox,
  }));
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newBox, setNewBox] = useState({
    configName: "",
    length: "",
    width: "",
    height: "",
    box_weight: "",
  });
  const [editBoxIndex, setEditBoxIndex] = useState(null);
  const [isPredefinedBox, setIsPredefinedBox] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [boxToDelete, setBoxToDelete] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    if (boxConfigStore?.length) {
      
      setPredefinedBoxes(boxConfigStore);}
  }, []);

  useEffect(() => {
    setSelectedBox(activeBox || 0);
  }, [activeBox]);

  const handleOpenDialog = () => {
    if (predefinedBoxes.length + customBoxes.length >= 5) {
      setAlertDialogOpen(true);
    } else {
      setNewBox({
        configName: "",
        length: "",
        width: "",
        height: "",
        box_weight: "",
      });
      setEditBoxIndex(null);
      setErrors({});
      setIsDialogOpen(true);
    }
  };
  const handleBlur = (fieldName) => {
    let newErrors = { ...errors };

    if (fieldName === "configName") {
      if (!newBox.configName) {
        newErrors.configName = "Config name is required";
      } else {
        newErrors.configName = "";
      }
    }

    setErrors(newErrors);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setErrors({});
  };

  const handleSaveBox = () => {
    let validationErrors = {};
    const allBoxes = [...predefinedBoxes];
    const isDuplicateConfigName = allBoxes.some(
      (box) => box.configName === newBox.configName && box !== newBox
    );
    if (!newBox.configName) {
      validationErrors.configName = "Name is required";
    } else if (isDuplicateConfigName) {
      validationErrors.configName = "Config name already exists";
    }

    const isValidDecimal = (value) => /^\d+(\.\d{1,2})?$/.test(value);
    if (!newBox?.length) {
      validationErrors.length = "Length is required";
    } else if (!isValidDecimal(newBox.length)) {
      validationErrors.length =
        "Invalid length, should be a number with up to 2 decimal places";
    }
    if (!newBox?.width) {
      validationErrors.width = "Width is required";
    } else if (!isValidDecimal(newBox.width)) {
      validationErrors.width =
        "Invalid width, should be a number with up to 2 decimal places";
    }
    if (!newBox?.height) {
      validationErrors.height = "Height is required";
    } else if (!isValidDecimal(newBox.height)) {
      validationErrors.height =
        "Invalid height, should be a number with up to 2 decimal places";
    }

    const isValidWeight = (value) => isValidDecimal(value) && value <= 70;
    if (!newBox?.box_weight) {
      validationErrors.box_weight = "Box weight is required";
    } else if (!isValidWeight(newBox.box_weight)) {
      validationErrors.box_weight =
        "Invalid boxWeight, should be a number below 70 lbs";
    }

    // if (Object.keys(validationErrors).length === 0) {
    //   if (editBoxIndex) {
    //     predefinedBoxes[editBoxIndex] = newBox
    //     setPredefinedBoxes([...predefinedBoxes])
    //   } else {
    //     predefinedBoxes.push(newBox)
    //     setPredefinedBoxes([...predefinedBoxes])
    //   }
    //   handleCloseDialog();
    // } else {
    //   setErrors(validationErrors);

    // }
    if (Object.keys(validationErrors).length === 0) {
      if (editBoxIndex !== null) {
        if (isPredefinedBox) {
          const updatedPredefinedBoxes = [...predefinedBoxes];
          updatedPredefinedBoxes[editBoxIndex] = newBox;
          setPredefinedBoxes(updatedPredefinedBoxes);
        } else {
          const updatedCustomBoxes = [...customBoxes];
          updatedCustomBoxes[editBoxIndex] = newBox;
          setCustomBoxes(updatedCustomBoxes);
        }
      } else {
        setCustomBoxes([...customBoxes, newBox]);
      }
      handleCloseDialog();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleDeleteBox = (index, isPredefined) => {
    setBoxToDelete({ index, isPredefined });
    setDeleteDialogOpen(true);
  };

  const confirmDeleteBox = () => {
    const { index, isPredefined } = boxToDelete;

    if (isPredefined) {
      const updatedPredefinedBoxes = predefinedBoxes.filter(
        (_, i) => i !== index
      );
      setPredefinedBoxes(updatedPredefinedBoxes);
    } else {
      const updatedCustomBoxes = customBoxes.filter((_, i) => i !== index);
      setCustomBoxes(updatedCustomBoxes);
    }

    setDeleteDialogOpen(false);
    setBoxToDelete(null);
  };

  const handleEditBox = (index, isPredefined) => {
    predefinedBoxes[index];
    if (isPredefined) {
      setNewBox(predefinedBoxes[index]);
      setIsPredefinedBox(true);
    } else {
      setNewBox(customBoxes[index]);
      setIsPredefinedBox(false);
    }
    setEditBoxIndex(index);
    setIsDialogOpen(true);
  };

  const handleSelectBox = (index) => {
    dispatch(updateActiveBox(index));
    setSelectedBox(index);
  };

  const handleBoxConfigSubmit = () => {
    dispatch(addBox([...predefinedBoxes, ...customBoxes]));
    handleNext();
  };

  const totalBoxes = predefinedBoxes.length + customBoxes.length;
  const isDeletable = totalBoxes > 1;

  return (
    <Stack gap={4} padding={5}>
      <Stack gap={3} alignItems={"center"}>
        <Stack gap={1}>
          <Typography variant="h5" textAlign={"center"}  sx={{ color: "#002F43"}}>
            Box configuration
          </Typography>
          <Typography variant="body2" textAlign={"center"} color={"#AAAAAA"}>
            Create templates to ease label generation process
          </Typography>
        </Stack>
        <Typography
          textAlign={"center"}
          variant="h6"
          sx={{
            color: "#256FA5",
            textDecoration: "underline",
            fontSize: 18,
            cursor: "pointer",
            display: "inline-flex",
          }}
          onClick={handleOpenDialog}
        >
          Add custom box
        </Typography>
      </Stack>

      <Stack direction={"row"} justifyContent={"center"} gap={3}>
        {predefinedBoxes?.map((box, index) => (
          <BoxComponent
            key={index}
            box={box}
            onEdit={() => handleEditBox(index, true)}
            onDelete={() => handleDeleteBox(index, true)}
            selected={selectedBox === index}
            onSelect={() => handleSelectBox(index)}
            isDeletable={isDeletable}
          />
        ))}

        {customBoxes.map((box, index) => (
          <BoxComponent
            key={index + predefinedBoxes.length}
            box={box}
            onEdit={() => handleEditBox(index, false)}
            onDelete={() => handleDeleteBox(index, false)}
            selected={selectedBox === index + predefinedBoxes.length}
            onSelect={() => handleSelectBox(index + predefinedBoxes.length)}
            isDeletable={isDeletable}
          />
        ))}
      </Stack>

      <Stack gap={1} direction={"row"} justifyContent={"flex-end"}>
        <ArrowCircleLeftIcon
          onClick={handleBack}
          sx={{
            color: "#002F43",
            cursor: "pointer",
            width: "50px !important",
            height: "50px !important",
            transition: "transform 0.3s ease-in-out",
            "@keyframes pulse": {
              "0%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.1)" },
              "100%": { transform: "scale(1)" },
            },
          }}
        />
        <ArrowCircleRightIcon
          onClick={handleBoxConfigSubmit}
          sx={{
            color: selectedBox.length == 0 ? "#CFDEE9" : "#002F43",
            cursor: "pointer",
            width: "50px !important",
            height: "50px !important",
            transition: "transform 0.3s ease-in-out",
            "@keyframes pulse": {
              "0%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.1)" },
              "100%": { transform: "scale(1)" },
            },
          }}
        />
      </Stack>

      {/* add/update box modal */}
      <ModalComponent
        openModal={isDialogOpen}
        handleCloseModal={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseDialog();
          }
        }}
        description={
          <Stack gap={2.5} paddingTop={1}>
            <TextField
              name="configName"
              label="Config name "
              value={newBox.configName}
              onChange={(e) =>
                setNewBox({ ...newBox, configName: e.target.value })
              }
              onBlur={() => handleBlur("configName")}
              error={!!errors.configName}
              helperText={errors.configName}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 15 }}
              sx={{
               ...styleInput
              }}
            />
            <BoxDimensions
              length={newBox.length}
              width={newBox.width}
              height={newBox.height}
              box_weight={newBox.box_weight}
              onChange={(field, value) =>
                setNewBox({ ...newBox, [field]: value })
              }
              onBlur={(field) => {
                const errorText = !isDecimalValid(newBox[field])
                  ? "Invalid value"
                  : "";
                setErrors({ ...errors, [field]: errorText });
              }}
              errors={errors}
            />
          </Stack>
        }
        handleBtnClick={handleSaveBox}
        btnText={editBoxIndex !== null ? "Update" : "Add"}
        title={
          editBoxIndex !== null ? "Update box configuration" : "Add box config"
        }
      />

      {/* delete box modal */}
      <ModalComponent
        openModal={deleteDialogOpen}
        handleCloseModal={(event, reason) => {
          if (reason !== "backdropClick") {
            setDeleteDialogOpen(false);
          }
        }}
        description={
          <Typography width={600} fontSize={18}>
            Are you sure to want to delete this box configuration?
          </Typography>
        }
        handleBtnClick={confirmDeleteBox}
        btnText={"Delete"}
        cancelBtnText={"Cancel"}
      />

      {/* limit reached modal */}
      <ModalComponent
        openModal={alertDialogOpen}
        handleCloseModal={(event, reason) => {
          if (reason !== "backdropClick") {
            setAlertDialogOpen(false);
          }
        }}
        title={"Limit Reached"}
        description={
          <Typography width={550} fontSize={18}>
            You can't add more than 5 boxes
          </Typography>
        }
        handleBtnClick={() => setAlertDialogOpen(false)}
        btnText={"Ok"}
      />

      {/* <Dialog open={alertDialogOpen} onClose={() => setAlertDialogOpen(false)}>
        <DialogTitle>Limit Reached</DialogTitle>
        <DialogContent>
          <Alert severity="warning">You can't add more than 5 boxes</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertDialogOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog> */}
    </Stack>
  );
};

export default BoxConfigStepper;
