const FullRefundIcon = ({ color }) => {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.25 23.25C2.5625 23.25 1.97396 23.0052 1.48438 22.5156C0.994792 22.026 0.75 21.4375 0.75 20.75V3.25C0.75 2.5625 0.994792 1.97396 1.48438 1.48438C1.97396 0.994792 2.5625 0.75 3.25 0.75H20.75C21.4375 0.75 22.026 0.994792 22.5156 1.48438C23.0052 1.97396 23.25 2.5625 23.25 3.25V6.375H20.75V3.25H3.25V20.75H20.75V17.625H23.25V20.75C23.25 21.4375 23.0052 22.026 22.5156 22.5156C22.026 23.0052 21.4375 23.25 20.75 23.25H3.25ZM13.25 18.25C12.5625 18.25 11.974 18.0052 11.4844 17.5156C10.9948 17.026 10.75 16.4375 10.75 15.75V8.25C10.75 7.5625 10.9948 6.97396 11.4844 6.48438C11.974 5.99479 12.5625 5.75 13.25 5.75H22C22.6875 5.75 23.276 5.99479 23.7656 6.48438C24.2552 6.97396 24.5 7.5625 24.5 8.25V15.75C24.5 16.4375 24.2552 17.026 23.7656 17.5156C23.276 18.0052 22.6875 18.25 22 18.25H13.25ZM22 15.75V8.25H13.25V15.75H22ZM17 13.875C17.5208 13.875 17.9635 13.6927 18.3281 13.3281C18.6927 12.9635 18.875 12.5208 18.875 12C18.875 11.4792 18.6927 11.0365 18.3281 10.6719C17.9635 10.3073 17.5208 10.125 17 10.125C16.4792 10.125 16.0365 10.3073 15.6719 10.6719C15.3073 11.0365 15.125 11.4792 15.125 12C15.125 12.5208 15.3073 12.9635 15.6719 13.3281C16.0365 13.6927 16.4792 13.875 17 13.875Z"
          fill={color || "#1C272A"}
        />
      </svg>
    );
  };
  
  export default FullRefundIcon;
  