import axios from "axios";
import { extractData } from "src/dataFetcher/extractData";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import deleteData from "src/dataFetcher/deleteDataFetcher";
import {
	backendApiGatewayTenant,
	backendTenantUrl,
} from "src/utils/backendUrls";
import {
	cleanObject,
} from "src/utils/core";

export async function getReturnList({ data = null, userInfo }) {

	const headers = {
		// tenant_id: "67595e14bb392dd3c509c5cc",
		tenant_id: userInfo?.tenant?._id,
		user_id: userInfo?.user_id,
	};
	// if (userInfo?.is_demo_user) {
	//   headers.is_demo_user = true; //dont need
	// }
	const params = cleanObject(data);
	// const params = null;
	const token = userInfo?.accessToken;
	const result = await getData(
		`${backendTenantUrl}tenant/${userInfo?.tenant?.tenant_name}/returns/list`,
		params,
		headers,
		token
	);
	return result;
}

export async function updateTrackingDetails(userInfo, body, return_id) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await putData(
	  `${backendTenantUrl}tenant/returns/update_return_label/${return_id}`,
	  {},
	  body,
	  token
	);
	return JSON.parse(extractData(result));
  }
  export async function GetTimelineHistory(id, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant?._id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}returns/returns/comments/${id}`,
	  null,
	  headers,
	  token
	);
	return result;
  }

  export async function AddNewComment(id, data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await postData(
	  `${backendTenantUrl}returns/returns/comments`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData({ data: result }));
  }
  
  export async function UpdateComment(id, data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await putData(
	  `${backendTenantUrl}returns/returns/comments/${id}`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData(result));
  }
  
  export async function DeleteComment(id, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant?._id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await deleteData(
	  `${backendTenantUrl}returns/returns/comments/${id}`,
	  headers,
	  token
	);
	return JSON.parse(extractData({ data: result }));
  }
  export async function ApproveReturn(data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await postData(
	  `${backendTenantUrl}returns/returns/return_action/approve`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData({ data: result }));
	// return JSON.parse(extractData(result));
  }
  
  export async function CancelReturn(data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
	  headers["is_demo_user"] = true;
	}
	const token = userInfo?.accessToken;
	const result = await postData(
	  `${backendTenantUrl}returns/returns/return_action/customer_cancel`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData({ data: result }));
  }
  
  export async function CloseReturn(data) {
	try {
	  const response = await axios.post(
		`https://jyzuqize6hpxvfihwbwulsst5i0ntcmw.lambda-url.us-east-2.on.aws/order/api/V1/returns/return_action/close`,
		data
	  );
	  return response.data;
	} catch (e) {
	  console.error(e, "cancelerror");
	  //throw e;
	  return null;
	}
  }
  
  export async function DeclineReturn(data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await postData(
	  `${backendTenantUrl}returns/returns/return_action/decline`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData({ data: result }));
  }
  export async function CreateRefund(data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant?._id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await postData(
	  `${backendTenantUrl}returns/refunds/issue_refund`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData({ data: result }));
  }
  export async function generateLabelApi(data, userInfo, return_id) {
	try {
	  let config = {
		method: "post",
		// maxBodyLength: Infinity,
		url: `${backendBaseUrl}label-generation`,
		// headers: {
		//   'Content-Type': 'application/json',
		//   'tenant_id': '66e192d245e80081398624d4',
		//   'Authorization': 'Bearer U2FsdGVkX18APqGo7xEWRQr7T9AAQoF2IL8XkQmiCKD+OfB3e+VmwDXw3ovQGmh/ZEq5kmfnIGSHVsPa2mkwjTjDAhO0pL2+XTFAHOTNE5Y7pZDYkSYUK9j9LMas+53eAfMAQSv8qDvSGHxmJtT+IIo4LUQ3OoGDk+hpZO4o2oYAC2jN/LUkJScHI4nzWld4L3m5LTlq6wl/ZdEFDs5pMePU2S50K3dsknvoUJR5swIfoSp4dy79s46V+ytpSp5EjJU4etADQJKlAG7VpGKPnJpKZkAhhKC5aFlWf5K0syi0qTk/WkSzagMXX3+5Xqbfm6mDPofqTXSukelWZj6SEh3CxtG9PknSFCamvXP4ZsIZXYhNxX1F1CsgNQe8MSYbM/7uQrE5Bxbdobiy9anFmUpchrBdmkWy0bCpG7EFG5WASe+g21iVrtTbcvAtBi8AjG6bgq+OY189w6qvQ/5QUq3Ig/1AE4DgBGzE1HpQ/R6qO5FCuTK4JjKiauYC6ftnSDhzmPmM5ZJnke5fC2et62NnL1mjMaRBNVo2bCOe8ie+j8yXjHmq26YR0AkK+8/G'
		// },
		// url: 'https://qmcundq0fl.execute-api.us-east-2.amazonaws.com/v1/label-generation',
		headers: {
		  // 'Content-Type': 'application/json',
		  // 'tenant_id':userInfo?.tenant_id,
		  //  'Authorization': `Bearer ${userInfo?.accessToken}`,
		  return_id: return_id ? return_id : "",
		  // 'Authorization': 'Bearer U2FsdGVkX18APqGo7xEWRQr7T9AAQoF2IL8XkQmiCKD+OfB3e+VmwDXw3ovQGmh/ZEq5kmfnIGSHVsPa2mkwjTjDAhO0pL2+XTFAHOTNE5Y7pZDYkSYUK9j9LMas+53eAfMAQSv8qDvSGHxmJtT+IIo4LUQ3OoGDk+hpZO4o2oYAC2jN/LUkJScHI4nzWld4L3m5LTlq6wl/ZdEFDs5pMePU2S50K3dsknvoUJR5swIfoSp4dy79s46V+ytpSp5EjJU4etADQJKlAG7VpGKPnJpKZkAhhKC5aFlWf5K0syi0qTk/WkSzagMXX3+5Xqbfm6mDPofqTXSukelWZj6SEh3CxtG9PknSFCamvXP4ZsIZXYhNxX1F1CsgNQe8MSYbM/7uQrE5Bxbdobiy9anFmUpchrBdmkWy0bCpG7EFG5WASe+g21iVrtTbcvAtBi8AjG6bgq+OY189w6qvQ/5QUq3Ig/1AE4DgBGzE1HpQ/R6qO5FCuTK4JjKiauYC6ftnSDhzmPmM5ZJnke5fC2et62NnL1mjMaRBNVo2bCOe8ie+j8yXjHmq26YR0AkK+8/G'
		},
		data: data,
	  };
	  const result = await axios.request(config);
  
	  return result;
	} catch (error) {
	  console.log(error, "errror");
	}
  }
  export async function getBoxConfigDetails(id, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	  is_demo_user: userInfo?.is_demo_user,
	};
  
	const token = userInfo?.accessToken;
  
	const result = await getData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/get_label_config/${id}`,
	  null,
	  headers,
	  token
	);
	return result;
  }

  export async function getSpecificReturnConfig(userInfo, config_name) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/rules/info/${config_name}`,
	  {},
	  headers,
	  token
	);
	return result;
  }
  
  export async function DirectRefund(data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await postData(
	  `${backendTenantUrl}returns/refunds/direct_refund`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData({ data: result }));
  }

  export async function GetEstimateSummary(data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	// if (userInfo?.is_demo_user) {
	//   headers.is_demo_user = true;
	// }
	const token = userInfo?.accessToken;
	const result = await postData(
	  `${backendTenantUrl}returns/refunds/info/return_estimate_summary`,
	  headers,
	  data,
	  token
	);
	//working 
	return JSON.parse(extractData({ data: result }));
  }

  export async function GetUsersList(userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}user/frontend/tenant_info/users_list`,
	  null,
	  headers,
	  token
	);
	return result;
  }

  export const getUserNameById = async (userInfo, userId) => {
	const usersList = await GetUsersList(userInfo);
	const data = usersList.data;
  
	for (const key in data) {
	  if (key === userId) return data[key];
	}
  };
  
export async function getLabelPreviewApi(id, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	  is_demo_user: userInfo?.is_demo_user,
	};
  
	const token = userInfo?.accessToken;
  
	const result = await getData(
	  `${backendTenantUrl}tenant/returns/get_label/${id}`,
	  // `${backendBaseUrl}tenant/${userInfo.tenant_name}/return/get_label_config/${id}`,
	  null,
	  headers,
	  token
	);
  
	return result;
  }
  export async function getOrderInfo(id, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}returns/returns/order_info/${id}`,
	  null,
	  headers,
	  token
	);
	return result;
  }
  export async function getOrderStatus(id, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}tenant/frontend/returns/status_history/${id}`,
	  null,
	  headers,
	  token
	);
	return result;
  }
  
export async function getReturnDetails(id, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/returns/list/${id}`,
	  null,
	  headers,
	  token
	);
	return result;
  }
  
  export async function generateLabel(data, userInfo, headers = {}) {
	const token = userInfo?.accessToken;
  
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
  
	let result;
	try {
	  result = await postData(
		`${backendTenantUrl}label-generation`,
		headers,
		data,
		token
	  );
	  return JSON.parse(extractData({ data: result }));
	} catch (error) {
	  return { error };
	}
  }
  export async function GetImages(userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}returns/returns/products/images`,
	  {},
	  headers,
	  token
	);
	return result;
  }
  //--------------return config--------
  export async function getReturnRules(userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id ,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/rules/info/return_rule`,
	  null,
	  headers,
	  token
	);
	return result;
  }
  export async function updateReturnRules(userInfo, data) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await putData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/rules/info`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData(result));

  }
  export async function getProductList(userInfo, params) {
	const headers = {
	  tenant_id: userInfo?.tenant?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}returns/returns/products/list`,
	  params,
	  headers,
	  token
	);
	return result;
  }

  ///--------------------auto approval

  
  export async function updateSpecificReturnConfig(userInfo, data) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await putData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/rules/info`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData(result));
  }
  

  //-------block customer api----

  export async function getBlockedCustomersList(userInfo, params) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/block_customer/info`,
	  params,
	  headers,
	  token
	);
	return result;
  }
  
  export async function addBlockedCustomer(userInfo, data) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await putData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/block_customer/info`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData(result));

  }
  
  export async function unblockBlockedCustomer(userInfo, data) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await postData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/block_customer/info`,
	  headers,
	  data,
	  token
	);
	return JSON.parse(extractData({ data: result }));
  }
  export async function getCustomersByEmail(userInfo, params) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}tracking/${userInfo?.tenant_name}/returns/customer_info`,
	  params,
	  headers,
	  token
	);
	return result;
  }
  

  //------------------------------