import { Box } from '@mui/material';
import React, { useState } from 'react';
import { retrieveLogoUrl, uploadBrandedPortalImages, uploadToS3 } from 'src/features/config/configApi';
import { getUserInfo } from 'src/features/user/userState';
import { useAppSelector } from 'src/hooks/hooks';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch } from 'react-redux';
import { decrementAwaitingApiCounter, incrementAwaitingApiCounter } from 'src/features/ui/uiState';
import ImageCompression from 'src/components/BlogsComponent/ImageCompression';

const BlogsImageUploader = ({ setValue, value, handleImageDelete, required, disabled, fileName }) => {
    const userInfo = useAppSelector(getUserInfo);
    const dispatch = useDispatch()

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        event.target.value = "";

        const fileURL = URL.createObjectURL(file);

        const maxFileSizeMB = 15;
        const fileSizeMB = file.size / 1024 / 1024;

        if (fileSizeMB >= maxFileSizeMB) {
            alert("File size exceeds 15MB. Please upload a smaller file.");
            URL.revokeObjectURL(fileURL);
            return;
        }

        // File type validation
        const fileType = file.type;
        const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/svg+xml"];

        if (!validImageTypes.includes(fileType)) {
            alert("File format must be JPG, PNG, or SVG.");
            URL.revokeObjectURL(fileURL);
            return;
        }

        const fileExtension = file.name.split(".").pop();
        const fileBody = {
            content_type: fileType,
            file_extension: fileExtension,
            upload_task: "marketing_blogs",
            file_name: file.name.split(".")[0]
        };

        const fileBodyThumb = {
            content_type: fileType,
            file_extension: fileExtension,
            upload_task: "marketing_blogs_thumb",
            file_name: file.name.split(".")[0]
        };

        let compressedFile = file;
        try {
            compressedFile = await ImageCompression(file, {
                maxSizeMB: 1,         // Maximum file size in MB (1MB for compression)
                maxWidthOrHeight: 800, // Maximum width/height of the image
                useWebWorker: true,    // Use Web Worker for compression
            });
        } catch (error) {
            console.error("Error compressing image:", error);
        }

        try {
            dispatch(incrementAwaitingApiCounter());

            const uploadResponse = await uploadBrandedPortalImages(fileBody, userInfo);

            if (uploadResponse.statusCode === 200) {
                const headers = {
                    "Content-Type": fileType,
                    "Content-Length": file.size.toString(),
                    "x-amz-acl": "public-read",
                };

                let s3UploadResponse = await uploadToS3(uploadResponse?.data?.pre_signed_url, file, headers);

                let uploadResponseThumb = null;
                if (fileName === "bannerImage") {
                    uploadResponseThumb = await uploadBrandedPortalImages(fileBodyThumb, userInfo);
                    let compressedImage = await uploadToS3(uploadResponseThumb?.data?.pre_signed_url, compressedFile, headers);
                }

                // Step 3: Update preview with the final URL after successful upload
                const finalURL = `${retrieveLogoUrl}/${uploadResponse.data.file_path}`;
                let imageObj = {
                    fileName: file.name,
                    url: finalURL,
                    path: uploadResponse?.data?.file_path,
                };

                if (fileName === "bannerImage") {
                    imageObj = {
                        ...imageObj,
                        thumbnailUrl: uploadResponseThumb?.data?.file_path
                    };
                }

                setValue({ ...imageObj });
                dispatch(decrementAwaitingApiCounter());
            } else {
                throw new Error("Failed to get pre-signed URL");
            }
        } catch (error) {
            console.log(error, 'Error during image upload');
            alert("Failed to upload image.");
            dispatch(decrementAwaitingApiCounter());
        } finally {
            // Cleanup blob URL after the upload process is complete
            URL.revokeObjectURL(fileURL); // Cleanup blob URL in finally block
        }
    };

    return (
        <>
            <div
                style={{
                    height: "200px",
                    width: "100%",
                    margin: "20px 0px",
                    position: "relative",
                    top: 0,
                    left: 0,
                }}
            >
                <img src={"/assets/dropzoneImage.png"} width={"100%"} height={"100%"} loading="lazy" />
                {value == null ?

                    <div
                        style={{
                            top: 0,
                            left: 0,
                            color: "black",
                            padding: '20px',
                            width: '100%',
                            height: '100%',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: 'absolute',
                        }}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            disabled={disabled}
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                opacity: 0,
                                cursor: "pointer"

                            }}
                        />

                        <>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-around"} flexDirection={"column"}>
                                <>
                                    <img src='/assets/imageUpload.svg' style={{ marginBottom: "10px" }} loading="lazy" />
                                    <p><span style={{ textDecoration: "underline", color: "#143F67" }}>Click to upload</span>  or drag and drop</p>
                                </>
                                <p style={{ marginTop: "10px", fontSize: "12px", fontWeight: 400, color: "#AAAAAA" }}>Max file size : 15MB &nbsp; Support format : JPF, JPEG, PNG</p>
                            </Box>
                        </>
                    </div> :
                    <div style={{
                        top: 0,
                        left: 0,
                        color: "black",
                        padding: '20px',
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: 'absolute',
                        background: disabled ? "#F0F0F0" : "",
                        cursor: disabled && "not-allowed"
                    }}>

                        <Box display={"flex"} flexDirection={"column"}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1} position={"relative"} top={0} left={0}>
                                <Box height={"150px"} width={"300px"}>
                                    <img
                                        src={value?.url}
                                        alt="Dropped Image"
                                        loading="lazy"
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%',

                                        }}
                                    />
                                </Box>
                                <span style={{ position: "absolute", top: "-10%", left: "100%" }} onClick={(e) => { if (!disabled) { e.stopPropagation(); handleImageDelete() } }}><HighlightOffIcon sx={{ fill: "#E50909", color: "#E50909", cursor: "pointer" }} /></span>
                            </Box>
                        </Box>
                    </div>
                }
                {required &&
                    <span className={"text-error"}>{"Required"}</span>
                }
            </div>


        </>

    );
};

export default BlogsImageUploader;
