import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const blogSlice = createSlice({
    name: 'blogSlice',
    initialState: {
        viewBlogs: {}
    },
    reducers: {
        setViewBlog: (state, action) => {
            state.viewBlogs = action.payload
        }
    },
});

export const { setViewBlog } = blogSlice.actions;
export default blogSlice.reducer;