import { combineReducers } from "redux";
import userDetailsReducer from "./userDetailsReducer";
import userReducer from "src/features/user/userState";
import plansReducer from "src/features/plans/plansState";
import uiReducer from "src/features/ui/uiState";
import trackingReducer from "../features/tracking/trakingState";
import scanDetail from "../features/scan/scanState";
import columnPreferenceState from "../features/tableActions/userColPreferanceState";
import configReducer from "src/features/config/configState";
import { signinReducer } from "src/features/signin/signin.reducer";
import tenantReducer from "src/features/tenants/tenantsState";
import filteredTenant from "src/features/filteredTenant/filteredTenant";
import chosenTenantSlice from "src/features/user/userSelect";
import blogsState from "src/features/blogs/blogsState";
// import configDetailReducer from '../features/config/configState';
import shopifyImageSlice from "src/features/returns/shopifyImages";
import stepperFormReducer from "src/features/returns/stepperFormSlice";
import returnMethodReducer from "src/features/returns/returnMethodSlice";

export default combineReducers({
	// user: userDetailsReducer,
	ui: uiReducer,
	user: userReducer,
	plans: plansReducer,
	trackInfo: trackingReducer,
	scan: scanDetail,
	userColumns: columnPreferenceState,
	config: configReducer,
	signin: signinReducer,
	selectedTenant: filteredTenant,
	tenants: tenantReducer,
	chosenTenantUser: chosenTenantSlice,
	blogsState:blogsState,
	// configDetails: configDetailReducer,
	shopify: shopifyImageSlice,
	form: stepperFormReducer,
    returnMethod: returnMethodReducer,
});
