import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select, Typography } from "@mui/material";
import { fetchTenants } from "src/features/user/userApi";
import { useDispatch, useSelector } from "react-redux";
import { updateTenant } from "src/features/filteredTenant/filteredTenant";
import { useAppDispatch } from "src/hooks/hooks";

export default function TenantMenuList({ tenantsList ,isBlockCustomer}) {
	const dispatch = useAppDispatch();
	const filteredTenant = useSelector(
		(state) => state.selectedTenant.SelectedTenant
	);
	const [tenant, setTenant] = useState("");
	const handleTenant = (ind) => {
		setTenant(tenantsList[ind]);
		dispatch(updateTenant(tenantsList[ind]));
	};
	const handleClearTenant = () => {
		setTenant("");
		dispatch(updateTenant(null));
	};
	return (
		<>
		{isBlockCustomer?'':
		<FormControl
			sx={{
				minWidth: 200,
				bgcolor: "common.white",
				borderRadius: 1,
				"& .MuiInputBase-formControl": {
					paddingLeft: 0,
				},
				"& .MuiOutlinedInput-root": {
					"&:hover fieldset": {
						borderColor: "primary.dark",
					},
					"&.Mui-focused fieldset": {
						borderColor: "primary.dark",
					},
				},
			}}
			size="small"
		>
			<InputLabel id="demo-select-small-label">
				Tenants Selection
			</InputLabel>
			<Select
				labelId="demo-select-small-label"
				id="demo-select-small"
				disabled={tenantsList ? false : true}
				value={filteredTenant?.company_name||''}
				label="Tenant Selection"
				sx={{
					backgroundColor: `${tenantsList ? "" : "#ddd"}`,
					height: { xs: 36, lg: 40 },
				}}
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: 200,
							overflowY: "auto",
						},
					},
				}}
			>
				{tenantsList?.map((option, index) => (
					<MenuItem
						sx={{ overflow: "hidden" }}
						onClick={() => handleTenant(index)}
						value={option.company_name}
					>
						<Typography variant="body2" sx={{ color: "#000" }}>
							{option.company_name}
						</Typography>
					</MenuItem>
				))}
				<MenuItem onClick={handleClearTenant}>
					<Typography variant="body2" sx={{ color: "red" }}>
						Clear Tenant
					</Typography>
				</MenuItem>
			</Select>
		</FormControl>}
		</>
	);
}
