import {
    Typography,
    Stack,
    Box,
    TextField,
    Button,
    MenuItem,
    Grid,
    Divider,
    Radio,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  import {} from "@mui/material";
  import { useEffect, useState } from "react";
  import DeliveryLabelIcon from "./DeliveryLabelIcon";
  import PrepaidFreeIcon from "./PrepaidFreeIcon";
  import PrepaidCostIcon from "./PrepaidCostIcon";
  import CustomerLabelCreatedIcon from "./CustomerLabelCreatedIcon";
  import typography from "src/theme/typography";
  import { LoadingButton } from "@mui/lab";
  import palette from "src/theme/palette";
  import {
    getSpecificReturnConfig,
    updateSpecificReturnConfig,
  } from "src/features/returnConfiguration/returnRulesApi";
  import { getUserInfo } from "src/features/user/userState";
  import { useAppSelector } from "src/hooks/hooks";
  import {
    decrementAwaitingApiCounter,
    incrementAwaitingApiCounter,
  } from "src/features/ui/uiState";
  import { useDispatch, useSelector } from "react-redux";
  import moment from "moment";
  import { getUserNameById } from "src/utils/getUserNameById";
  import FormStepper from "./Stepper/FormStepper";
  import {
    setSelectedMethod,
    setCustomerManagedReturn,
  } from "src/features/returns/returnMethodSlice";
  import ModalComponent from "src/components/modal/ModalComponent";
  import EmailConfiguration from "./EmailConfiguration/EmailConfiguration";
  import { LABEL_PROCESS_TYPE, LABELS } from "src/pages/ManageReturns/Labels";
  import ConfigPreview from "./Stepper/ConfigPreview";
  import {
    selectPaidLabelReasons,
    updateForm,
    updateStep,
  } from "src/features/returns/stepperFormSlice";
  import { useNavigate } from "react-router-dom";
//   import appUrls from "src/utils/appUrls";
  import { emailConfigurationTexts } from "src/pages/EmailTemplate/EmailConfigurationTexts";
  import { retrieveLogoUrl } from "src/features/returnConfiguration/emailTemplatesApi";
  import {
    getEmailTemplate,
    publishEmailTemplate,
  } from "src/utils/emailTemplates/functions";
import { textStyle } from "src/pages/billing/cardsComponent/cardsUtils";
const textStyles = {
	color: palette.common.black,
	// fontSize: { xs: 16, md: 18 },
  // fontFamily: typography.fontFamily
};
  const ReturnMethod = ({
    emailTemplateLayout,
    setEmailTemplateLayout,
    emailTemplateContent,
    setEmailTemplateContent,
    emailTemplateSubject,
    setEmailTemplateSubject,
    emailTemplateBackendReponse,
    setEmailTemplateBackendReponse,
  }) => {
    const userInfo = useAppSelector(getUserInfo);
    const form = useSelector((state) => state.form);
    const dispatch = useDispatch();
  
    const [selectedOption, setSelectedOption] = useState(LABELS.NO_SELECTED);
    const [editPrepaidLabelConfiguration, setEditPrepaidLabelConfiguration] =
      useState(false);
  
    const [enableCustomerManagedReturn, setEnableCustomerManagedReturn] =
      useState(false);
    const [openEnableCustomerManagedModal, setOpenEnableCustomerManagedModal] =
      useState(false);
  
    const [hasBeenSaved, setHasBeenSaved] = useState(false);
    const [lastUpdatedInfo, setLastUpdatedInfo] = useState("No updated yet");
    const [stepperStep, setStepperStep] = useState(0);
    const [openUspsLabelGenerationModal, setOpenUspsLabelGenerationModal] =
      useState(false);
    const [openEditTemplateUI, setOpenEditTemplateUI] = useState("");
  
    const selectedMethod = useSelector(
      (state) => state.returnMethod.selectedMethod
    );
    const customerManagedReturn = useSelector(
      (state) => state.returnMethod.customerManagedReturn
    );
    const signinData = useSelector((state) => state.signin.signinData);
  
    const [openPopup, setOpenPopup] = useState(false);
  
    const navigate = useNavigate();
  
    const { carrierSignUSPSIsLabelGenerate } = signinData || {};
  
    const [logoFile, setLogoFile] = useState("");
    const [refresh, setRefresh] = useState(false);
  
    const paidConfig = useSelector(selectPaidLabelReasons);
    const { tenant_id, tenant_name,user_id,is_demo_user } = useSelector((state) => state?.tenants);
    const enhancedUserInfo = {
        accessToken: userInfo?.accessToken,
        tenant_id,
        tenant_name,
        user_id,
        is_demo_user,
      };

    const [emailTemplateItemData, setEmailTemplateItemData] = useState([
      ...(selectedOption === LABELS.PREPAID_LABEL
        ? [
            emailConfigurationTexts.freeLabel,
            ...(paidConfig?.length > 0
              ? [emailConfigurationTexts.paidLabel]
              : []),
          ]
        : []),
      ...(selectedOption === LABELS.TWO_WAY
        ? [emailConfigurationTexts.twoWayReturn]
        : []),
      ...(customerManagedReturn
        ? [emailConfigurationTexts.customerManagedReturns]
        : []),
    ]);
  
    const handlePopupClose = () => {
      setOpenPopup(false);
    };
  
    const handleUspsModalPopupClose = () => {
      setOpenUspsLabelGenerationModal(false);
    };
  
    const handleUspsModalPopupClick = () => {
      navigate("/dashboard/tenants/details?tab=4"); 
      setOpenUspsLabelGenerationModal(false);
    };
  
    const handleEnableCustomerReturn = () => {
      setEnableCustomerManagedReturn(true);
      setOpenEnableCustomerManagedModal(false);
      setOpenPopup(false);
    };
  
    const handleEditPrepaidLabelConfiguration = () => {
      setEditPrepaidLabelConfiguration(true);
    };
  
    const handleSaveReturnMethod = async () => {
      dispatch(incrementAwaitingApiCounter());
  
      const body = {
        rule_name: "return_method",
        value: selectedOption,
        is_customer_manage_return: enableCustomerManagedReturn,
      };
  
      try {
        const response = await updateSpecificReturnConfig(enhancedUserInfo, body);
  
        if (response.statusCode === 200) {
          dispatch(setCustomerManagedReturn(enableCustomerManagedReturn));
  
          emailTemplateItemData.map(async (el) => {
            const publishEmailTemplateResponse = await publishEmailTemplate(
             enhancedUserInfo,
              el.templateName,
              emailTemplateLayout,
              emailTemplateSubject,
              emailTemplateContent,
              retrieveLogoUrl,
              logoFile
            );
            return publishEmailTemplateResponse;
          });
  
          setRefresh(true);
          setHasBeenSaved(false);
        }
        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        dispatch(decrementAwaitingApiCounter());
      }
  
      setHasBeenSaved(false);
    };
  
    const handleSelection = (option) => {
      const isSameOption = selectedOption === option;
      const isCustomerManaged = option === LABELS.CUSTOMER_MANAGED;
      const isPrepaidLabel = selectedMethod === LABELS.PREPAID_LABEL;
      setOpenEditTemplateUI("")
      if (isSameOption) {
        setSelectedOption("");
        return;
      }
  
      if (option === LABELS.PREPAID_LABEL && !carrierSignUSPSIsLabelGenerate) {
        setOpenUspsLabelGenerationModal(true);
        setOpenEditTemplateUI("");
        return;
      }
  
      if (!isCustomerManaged) {
        setSelectedOption(option);
        setHasBeenSaved(true);
        setEnableCustomerManagedReturn(false);
  
        if (selectedMethod !== option) {
          setEditPrepaidLabelConfiguration(false);
          setOpenPopup(true);
          setOpenEditTemplateUI("");
        }
        return;
      }
  
      if (isCustomerManaged) {
        if (openEditTemplateUI === LABEL_PROCESS_TYPE.OWN_LABEL) {
          return;
        }
  
        if (isPrepaidLabel) {
          setEditPrepaidLabelConfiguration(true);
          setStepperStep(5);
          dispatch(updateStep(5));
          setHasBeenSaved(true);
          setOpenEditTemplateUI("");
        }
  
        if (enableCustomerManagedReturn) {
          setEnableCustomerManagedReturn(false);
        } else {
          if (selectedOption === "") {
            setEnableCustomerManagedReturn(true);
          } else {
            setOpenEnableCustomerManagedModal(true);
          }
        }
      }
    };
  
    useEffect(()=>{},[tenant_name])
    useEffect(() => {
      const populateData = async () => {
        dispatch(incrementAwaitingApiCounter());
  
        try {
          const response = await getSpecificReturnConfig(
            enhancedUserInfo,
            "return_method"
          );
          
          if (response.statusCode === 200) {
            const data = response.data;
  
            if (Object.keys(data).length === 0) {
              setLastUpdatedInfo("No updated yet");
              dispatch(decrementAwaitingApiCounter());
              return;
            }
  
            if (data?.value === LABELS.PREPAID_LABEL) {
              const formData = {
                warehouse: {
                  firstName: data?.warehouse_address.first_name,
                  lastName: data?.warehouse_address.last_name,
                  city: data?.warehouse_address.city,
                  state: data?.warehouse_address.state,
                  country: data?.warehouse_address.country,
                  zipCode: data?.warehouse_address.zip_code,
                  addressLine: data?.warehouse_address.address_line,
                },
                carrier: {
                  carrier: data?.carrier_mail_config.carrier,
                  mailClass: data?.carrier_mail_config.mail_class,
                },
                boxes: data?.box_config.map((el) => ({
                  height: el.height,
                  width: el.width,
                  length: el.length,
                  configName: el.config_name,
                  box_weight: el.box_weight,
                  ...(el?.is_active && {
                    isActive: el.is_active,
                  }),
                })),
                label: {
                  labelSize: data?.label_config?.label_size,
                  labelType: data?.label_config?.label_type,
                },
                paidLabelConfig: { reasons: data.paid_label_config },
              };
  
              dispatch(updateForm(formData));
            }
            setSelectedOption(data.value);
            dispatch(setSelectedMethod(data.value));
  
            dispatch(setCustomerManagedReturn(!!data.is_customer_manage_return));
            setEnableCustomerManagedReturn(!!data.is_customer_manage_return);
  
            const updatedAt = data.updated_at || data.created_at;
            const updatedById = data.updated_by || data.created_by;
  
            const updatedByName = await getUserNameById(enhancedUserInfo, updatedById);
  
            const time = moment
              .unix(updatedAt)
              .tz("America/Los_Angeles")
              .format("MMM DD, YYYY");
  
            setLastUpdatedInfo(updatedByName + " on " + time);
          }
          dispatch(decrementAwaitingApiCounter());
        } catch (error) {
          dispatch(decrementAwaitingApiCounter());
        }
      };
  
      !hasBeenSaved && populateData();
    }, [hasBeenSaved]);
  
    useEffect(() => {
      const handleGetEmailTemplatesData = async () => {
        await Promise.all(
          emailTemplateItemData.map(async (el) => {
            await getEmailTemplate(
              enhancedUserInfo,
              el.templateName,
              setEmailTemplateLayout,
              setEmailTemplateSubject,
              setEmailTemplateContent,
              setEmailTemplateBackendReponse,
              // emailTemplateBackendReponse,
              dispatch
            );
          })
        );
      };
  
      handleGetEmailTemplatesData();
    }, [emailTemplateItemData]);
  
    return (
      <Stack
        sx={{
          gap: 6,
          alignItems: "center",
          marginTop:'-10px'
        }}
      >
        <Stack gap={2} width={"100%"}>
          <Typography textAlign={"right"} 
          // paddingTop={2} 
          sx={{...textStyles}}
          fontSize={14}>
            {lastUpdatedInfo !== "No updated yet"
              ? `Last modified by ${lastUpdatedInfo}`
              : ``}
          </Typography>
          <Stack direction={"row"} justifyContent={"space-between"} gap={4}>
            <Box
              sx={{
                display: "flex",
                flex: 2,
                maxWidth: "900px",
                borderRadius: "10px",
                backgroundColor: "white",
                position: "relative",
                boxShadow: "0px 6px 30px 0px #00000014",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  padding: 3,
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => handleSelection(LABELS.TWO_WAY)}
              >
                <Radio
                  checked={selectedOption === LABELS.TWO_WAY}
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: selectedOption === LABELS.TWO_WAY ? "#fff" : "#256FAG",
                  }}
                />
                {selectedMethod === LABELS.TWO_WAY && (
                  <Typography
                    color={"white"}
                    fontSize={10}
                    sx={{
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                      bgcolor: palette.primary.main,
                      borderRadius: "30px",
                      padding: "5px 10px",
                      lineHeight: "normal",
                    }}
                  >
                    Active
                  </Typography>
                )}
                <Box
                  sx={{
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1.5,
                  }}
                >
                  <DeliveryLabelIcon color="#002F43" />
                  <Typography
                    sx={{
                      color: "#002F43",
                      fontSize: 20,
                      fontFamily: typography.fontFamilySemiBold,
                    }}
                  >
                    2 way delivery label 
                  </Typography>
                  <Typography
                    sx={{
                      color: "#AAAAAA",
                      fontSize: "14px",
                    }}
                  >
                    A pre-shipped return label is included with the original
                    package. Customers can use that return delivery label for easy
                    and hassle-free return.
                  </Typography>
                </Box>
              </Box>
  
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  width: "1.5px",
                  height: "80%",
                  backgroundColor: "#AAAAAA",
                  alignSelf: "center",
                }}
              />
  
              <Box
                sx={{
                  flex: 1,
                  padding: 3,
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => handleSelection(LABELS.PREPAID_LABEL)}
              >
                <Radio
                  checked={selectedOption === LABELS.PREPAID_LABEL}
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color:
                      selectedOption === LABELS.PREPAID_LABEL
                        ? "#fff"
                        : "#256FAG",
                  }}
                />
                {selectedMethod === LABELS.PREPAID_LABEL && (
                  <Typography
                    color={"white"}
                    fontSize={10}
                    sx={{
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                      bgcolor: palette.primary.main,
                      borderRadius: "30px",
                      padding: "5px 10px",
                      lineHeight: "normal",
                    }}
                  >
                    Active
                  </Typography>
                )}
  
                <Box
                  sx={{
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1.5,
                  }}
                >
                  <PrepaidFreeIcon color="#002F43" />
                  <Typography
                    sx={{
                      color: "#002F43",
                      fontSize: 20,
                      fontFamily: typography.fontFamilySemiBold,
                    }}
                  >
                    Prepaid label
                  </Typography>
                  <Typography
                    sx={{
                      color: "#AAAAAA",
                      fontSize: "14px",
                    }}
                  >
                    Generate label for returns, making dynamic decisions based on
                    the return reason and customer rating to provide free or paid
                    label options.
                  </Typography>
                </Box>
              </Box>
            </Box>
  
            <Box
              sx={{
                flex: 1,
                padding: 3,
                maxWidth: "450px",
                borderRadius: "10px",
                backgroundColor: "white",
                cursor: "pointer",
                position: "relative",
                boxShadow: "0px 6px 30px 0px #00000014",
              }}
              onClick={() => handleSelection(LABELS.CUSTOMER_MANAGED)}
            >
              <Checkbox
                checked={enableCustomerManagedReturn}
                sx={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  color:
                    selectedOption === LABELS.CUSTOMER_MANAGED
                      ? "#fff"
                      : "#256FAG",
                }}
              />
  
              {customerManagedReturn && (
                <Typography
                  color={"white"}
                  fontSize={10}
                  sx={{
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    bgcolor: palette.primary.main,
                    borderRadius: "30px",
                    padding: "5px 10px",
                    lineHeight: "normal",
                  }}
                >
                  Active
                </Typography>
              )}
  
              <Box
                sx={{
                  textAlign: "center",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1.5,
                }}
              >
                <CustomerLabelCreatedIcon color="#002F43" />
                <Typography
                  sx={{
                    color: "#002F43",
                    fontSize: 20,
                    fontFamily: typography.fontFamilySemiBold,
                  }}
                >
                  Customer managed returns
                </Typography>
                <Typography
                  sx={{
                    color: "#AAAAAA",
                    fontSize: "14px",
                  }}
                >
                  Enable customers to purchase their own return label with one of
                  the company&apos;s preferred carriers. They can easily update
                  the tracking number through the tracking portal.
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Stack>
  
        {/* Popup Confirmation */}
        <ModalComponent
          openModal={openPopup}
          handleCloseModal={handlePopupClose}
          description={
            <Box
              sx={{
                width: 550,
              }}
            >
              You are allowed to setup the customer managed returns in combination
              with
              <b>
                {selectedOption === LABELS.PREPAID_LABEL
                  ? " Prepaid label"
                  : " 2 way delivery label"}
              </b>
              {selectedOption === LABELS.PREPAID_LABEL
                ? ". This allows the customer to return items by purchasing their own labels."
                : "."}
            </Box>
          }
          handleBtnClick={handleEnableCustomerReturn}
          btnText={"Enable"}
          cancelBtnText={"Close"}
          hideCloseIcon
        />
  
        {openUspsLabelGenerationModal && (
          <ModalComponent
            openModal={openUspsLabelGenerationModal}
            handleCloseModal={handleUspsModalPopupClose}
            description={
              <Box
                sx={{
                  width: 550,
                }}
              >
                You have not enable label generation. Please update your carrier
                in configuration page to enable prepaid label.
              </Box>
            }
            handleBtnClick={handleUspsModalPopupClick}
            btnText={"Enable"}
            cancelBtnText={"Close"}
            hideCloseIcon
          />
        )}
  
        {selectedOption === "" && !enableCustomerManagedReturn && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: "908px",
                height: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#CFDEE9",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <Typography
                sx={{
                  lineHeight: "27px",
                  textAlign: "center",
                  color:"#002F43"
                }}
              >
                Setup the return methods based on the return policy of your
                business. You are allowed to select one of the options between 2
                way delivery label and prepaid label, and Customer managed returns
                as an additional method if required.
              </Typography>
            </Box>
          </div>
        )}
  
        {selectedOption === LABELS.PREPAID_LABEL &&
          (selectedMethod === LABELS.PREPAID_LABEL ? (
            editPrepaidLabelConfiguration ? (
              <FormStepper
                customerManagedReturn={enableCustomerManagedReturn}
                savedLabel={selectedMethod}
                handleEditPrepaidLabelConfiguration={
                  handleEditPrepaidLabelConfiguration
                }
                setHasBeenSaved={setHasBeenSaved}
                step={stepperStep}
                openEditTemplateUI={openEditTemplateUI}
                setOpenEditTemplateUI={setOpenEditTemplateUI}
                emailTemplateItemData={emailTemplateItemData}
                setEmailTemplateItemData={setEmailTemplateItemData}
                logoFile={logoFile}
                setLogoFile={setLogoFile}
                emailTemplateLayout={emailTemplateLayout}
                setEmailTemplateLayout={setEmailTemplateLayout}
                emailTemplateContent={emailTemplateContent}
                setEmailTemplateContent={setEmailTemplateContent}
                emailTemplateSubject={emailTemplateSubject}
                setEmailTemplateSubject={setEmailTemplateSubject}
                emailTemplateBackendReponse={emailTemplateBackendReponse}
                setEmailTemplateBackendReponse={setEmailTemplateBackendReponse}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            ) : (
              <ConfigPreview
                handleEditPrepaidLabelConfiguration={
                  handleEditPrepaidLabelConfiguration
                }
              />
            )
          ) : (
            <FormStepper
              customerManagedReturn={enableCustomerManagedReturn}
              savedLabel={selectedMethod}
              handleEditPrepaidLabelConfiguration={
                handleEditPrepaidLabelConfiguration
              }
              setHasBeenSaved={setHasBeenSaved}
              openEditTemplateUI={openEditTemplateUI}
              setOpenEditTemplateUI={setOpenEditTemplateUI}
              emailTemplateItemData={emailTemplateItemData}
              setEmailTemplateItemData={setEmailTemplateItemData}
              logoFile={logoFile}
              setLogoFile={setLogoFile}
              emailTemplateLayout={emailTemplateLayout}
              setEmailTemplateLayout={setEmailTemplateLayout}
              emailTemplateContent={emailTemplateContent}
              setEmailTemplateContent={setEmailTemplateContent}
              emailTemplateSubject={emailTemplateSubject}
              setEmailTemplateSubject={setEmailTemplateSubject}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          ))}
  
        {selectedOption === LABELS.TWO_WAY && (
          <EmailConfiguration
            handleSave={handleSaveReturnMethod}
            customerManagedReturn={enableCustomerManagedReturn}
            isTwoWayDeliveryLabel={true}
            openEditTemplateUI={openEditTemplateUI}
            setOpenEditTemplateUI={setOpenEditTemplateUI}
            emailTemplateItemData={emailTemplateItemData}
            setEmailTemplateItemData={setEmailTemplateItemData}
            logoFile={logoFile}
            setLogoFile={setLogoFile}
            emailTemplateLayout={emailTemplateLayout}
            setEmailTemplateLayout={setEmailTemplateLayout}
            emailTemplateContent={emailTemplateContent}
            setEmailTemplateContent={setEmailTemplateContent}
            emailTemplateSubject={emailTemplateSubject}
            setEmailTemplateSubject={setEmailTemplateSubject}
            refresh={refresh}
            setRefresh={setRefresh}
            emailTemplateBackendReponse={emailTemplateBackendReponse}
            setEmailTemplateBackendReponse={setEmailTemplateBackendReponse}
          />
        )}
  
        {enableCustomerManagedReturn && selectedOption === "" && (
          <EmailConfiguration
            handleSave={handleSaveReturnMethod}
            customerManagedReturn={enableCustomerManagedReturn}
            openEditTemplateUI={openEditTemplateUI}
            setOpenEditTemplateUI={setOpenEditTemplateUI}
            emailTemplateItemData={emailTemplateItemData}
            setEmailTemplateItemData={setEmailTemplateItemData}
            logoFile={logoFile}
            setLogoFile={setLogoFile}
            emailTemplateLayout={emailTemplateLayout}
            setEmailTemplateLayout={setEmailTemplateLayout}
            emailTemplateContent={emailTemplateContent}
            setEmailTemplateContent={setEmailTemplateContent}
            emailTemplateSubject={emailTemplateSubject}
            setEmailTemplateSubject={setEmailTemplateSubject}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
  
        {openEnableCustomerManagedModal && (
          <ModalComponent
            openModal={openEnableCustomerManagedModal}
            handleCloseModal={() => {
              setOpenEnableCustomerManagedModal(false);
              setOpenEnableCustomerManagedModal(false);
            }}
            description={
              <Box
                sx={{
                  width: 550,
                }}
              >
                You are enabling customer managed returns. Verify the additional
                email template before publishing changes.
              </Box>
            }
            handleBtnClick={handleEnableCustomerReturn}
            btnText={"Enable"}
            cancelBtnText={"Close"}
            hideCloseIcon
          />
        )}
      </Stack>
    );
  };
  
  export default ReturnMethod;
  