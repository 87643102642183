const DeliveryLabelIcon = ({ color,height,width }) => {
    return (
      <svg
        width={height||"50"}
        height={width||"40"}
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.7292 12.168C35.9792 11.1055 34.7292 10.418 33.3333 10.418L10.4167 10.4388C8.125 10.4388 6.25 12.293 6.25 14.5846V35.418C6.25 37.7096 8.125 39.5638 10.4167 39.5638L33.3333 39.5846C34.7292 39.5846 35.9792 38.8971 36.7292 37.8346L45.8333 25.0013L36.7292 12.168ZM33.3333 35.418H10.4167V14.5846H33.3333L40.7292 25.0013L33.3333 35.418Z"
          fill={color || "white"}
        />
        <path
          d="M21.4286 30.7137L17.8571 27.1423L21.4286 23.5709L22.4286 24.5887L20.5893 26.428H25.7143V27.8566H20.5893L22.4286 29.6959L21.4286 30.7137ZM28.5714 26.428L27.5714 25.4102L29.4107 23.5709H24.2857V22.1423H29.4107L27.5714 20.303L28.5714 19.2852L32.1429 22.8566L28.5714 26.428Z"
          fill={color || "white"}
        />
      </svg>
    );
  };
  
  export default DeliveryLabelIcon;
  