import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LABEL_PROCESS_TYPE,LABELS } from "./Labels";
import typography from "src/theme/typography";
import PrepaidFreeIcon from "./icons/PrepaidFreeIcon";
import CustomerLabelCreatedIcon from "./icons/CustomerLabelCreatedIcon";
import DeliveryLabelIcon from "./icons/DeliveryLabelIcon";
import './style/styles.css'

const ReturnMethodContentDetail = ({ returnInfo, returnMethod }) => {
  const labelType =
    returnInfo?.label_type != null
      ? returnInfo.label_type
      : returnMethod?.selectedMethod;

  return (
    <>
      <Box
        sx={{
          borderRadius: "12px",
          mt: 3,
          overflow: "hidden",
          backgroundColor: "white",
          boxShadow: "0px 2px 8px -4px lightgrey",
        }}
      >
        {labelType && (
          <>
            <Typography
              sx={{
                color: "#000",
                padding: "10px 20px",
                fontSize: "17px",
                backgroundColor: "#CFDEE9",
              }}
            >
              Return method
            </Typography>
            <Stack alignItems={"center"} gap={1}>
              {labelType == LABEL_PROCESS_TYPE.TWO_WAY_LABEL && (
                <Box display="flex" alignItems="center" p={2}>
                  <DeliveryLabelIcon color="#002F43" height={18} width={18} />
                  <Typography
                    fontFamily={typography.fontFamilySemiBold}
                    sx={{
                      fontSize: "16px",
                      lineHeight: "19.2px",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                   className="colorBlack"
                  >
                    2 Way Returns
                  </Typography>
                </Box>
              )} 
              {labelType == LABEL_PROCESS_TYPE.OWN_LABEL && (
                <Box display="flex" alignItems="center" p={2}>
                  <CustomerLabelCreatedIcon
                    color="#002F43"
                    height={20}
                    width={20}
                  />
                  <Typography
                    fontFamily={typography.fontFamilySemiBold}
                    sx={{
                      fontSize: "16px",
                      lineHeight: "19.2px",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  className="colorBlack"
                  >
                    Customer Managed Returns
                  </Typography>
                </Box>
              )} 
              {(labelType == LABEL_PROCESS_TYPE.PREPAID_PAID_LABEL ||
                labelType == LABEL_PROCESS_TYPE.PREPAID_FREE_LABEL ||
                labelType == LABELS.PREPAID_LABEL) && (
                <Box display="flex" alignItems="center" p={2}>
                  <PrepaidFreeIcon color="#002F43" height={20} width={20} />
                  <Typography
                    fontFamily={typography.fontFamilySemiBold}
                    sx={{
                      fontSize: "16px",
                      lineHeight: "19.2px",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  className="colorBlack"
                  >
                    Prepaid Label
                  </Typography>
                </Box>
              )} 
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default ReturnMethodContentDetail;
