const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const backendApiGatewayBaseUrl = process.env.REACT_APP_GATEWAY_BASE_URL;

export const backendUserUrl = backendBaseUrl;

export const backendTrackingUrl =
	backendBaseUrl;

export const backendTenantUrl =
	backendBaseUrl;

export const backendApiGatewayTenant = backendApiGatewayBaseUrl