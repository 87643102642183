import React from "react";
import {
  Select,
  MenuItem,
  ListItemText,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import "../style/styles.css";
import EditIcon from "@mui/icons-material/Edit";
const textStyles={color:"#002F43"}
const CustomSelect = ({
  options,
  value,
  onChange,
  onAddCustomBox,
  onEditCustomBox, 
  customBoxAdded=false,
  showTooltip = false,
  showAddCustomBox = false,
  width = "200px",
  height = "42px",
}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      fullWidth
      style={{
        width,
        height,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        color:"#002F43"
      }}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#AAAAAA", 
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#000000", 
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#002F43", 
        },
      }}
      renderValue={(selectedValue) => {
        const selectedOption = options.find(option => option?.value === selectedValue);
        return selectedOption ? selectedOption?.label : ''; 
      }}
      className="selectboxtext"
    >
      {options.map((option) => (
        
        <MenuItem key={option?.value} value={option?.value} className="selectboxtext" sx={{...textStyles}}
        >
          <ListItemText primary={option?.label}/>
          {showTooltip && (
        <Tooltip title={option?.tooltip}
        placement="right"
            arrow
        >
            <IconButton>
              <InfoOutlinedIcon fontSize="small" color="action" />
            </IconButton>
        </Tooltip>
          )}
        </MenuItem>
      ))}
      {/* {showAddCustomBox && ( */}
     {showAddCustomBox && !customBoxAdded && (
        <MenuItem onClick={onAddCustomBox} className="selectboxtext" sx={{...textStyles}}>
          <IconButton>
            <AddIcon fontSize="small" color="primary" />
          </IconButton>
          Add Custom Box Config
        </MenuItem>
      )}
       {showAddCustomBox && customBoxAdded && (
        <MenuItem onClick={onEditCustomBox} className="selectboxtext">
          <IconButton>
            <EditIcon fontSize="small" color="primary" />
          </IconButton>
          Edit Custom Box Configuration
        </MenuItem>
      )}
    </Select>
  );
};

export default CustomSelect;
