const NoRefundIcon = ({ color }) => {
    return (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 25.5C11.2708 25.5 9.64583 25.1719 8.125 24.5156C6.60417 23.8594 5.28125 22.9688 4.15625 21.8438C3.03125 20.7188 2.14062 19.3958 1.48438 17.875C0.828125 16.3542 0.5 14.7292 0.5 13C0.5 11.2708 0.828125 9.64583 1.48438 8.125C2.14062 6.60417 3.03125 5.28125 4.15625 4.15625C5.28125 3.03125 6.60417 2.14062 8.125 1.48438C9.64583 0.828125 11.2708 0.5 13 0.5C14.7292 0.5 16.3542 0.828125 17.875 1.48438C19.3958 2.14062 20.7188 3.03125 21.8438 4.15625C22.9688 5.28125 23.8594 6.60417 24.5156 8.125C25.1719 9.64583 25.5 11.2708 25.5 13C25.5 14.7292 25.1719 16.3542 24.5156 17.875C23.8594 19.3958 22.9688 20.7188 21.8438 21.8438C20.7188 22.9688 19.3958 23.8594 17.875 24.5156C16.3542 25.1719 14.7292 25.5 13 25.5ZM13 23C14.125 23 15.2083 22.8177 16.25 22.4531C17.2917 22.0885 18.25 21.5625 19.125 20.875L5.125 6.875C4.4375 7.75 3.91146 8.70833 3.54688 9.75C3.18229 10.7917 3 11.875 3 13C3 15.7917 3.96875 18.1562 5.90625 20.0938C7.84375 22.0312 10.2083 23 13 23ZM20.875 19.125C21.5625 18.25 22.0885 17.2917 22.4531 16.25C22.8177 15.2083 23 14.125 23 13C23 10.2083 22.0312 7.84375 20.0938 5.90625C18.1562 3.96875 15.7917 3 13 3C11.875 3 10.7917 3.18229 9.75 3.54688C8.70833 3.91146 7.75 4.4375 6.875 5.125L20.875 19.125Z"
          fill={color || "#1C272A"}
        />
      </svg>
    );
  };
  
  export default NoRefundIcon;
  