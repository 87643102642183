import React from 'react'
import imageCompression from 'browser-image-compression';
const ImageCompression = (file,option) => {

    // const options = {
    //     maxSizeMB: 1,         // Maximum file size in MB (1MB)
    //     maxWidthOrHeight: 800, // Maximum width/height of the image
    //     useWebWorker: true,    // Use Web Worker to run the compression in a separate thread
    // };
    return imageCompression(file, option);
}

export default ImageCompression