import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Profile from "./Profile";
import BillingInvoice from "../billing/billing";
import UsersListing from "./Users";
import ConfigPage from "../ConfigPage";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import { getTenantById } from "src/features/tenants/tenantsApi";
import { useSelector } from "react-redux";
import { retrieveLogoUrl } from "src/features/config/configApi";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import PricingConfiguration from "./PricingConfiguration"
import ReturnConfigPage from "src/pages/ReturnConfiguration/ReturnConfigPage"
import { useSearchParams } from "react-router-dom";

const ProfileDetails = () => {
	const [value, setValue] = React.useState("1");
	const [tenant, setTenant] = useState({});
	const tenant_id = useSelector((state) => state.tenants.tenant_id);
	const userInfo = useAppSelector(getUserInfo);
	const [emailTemplateLayout, setEmailTemplateLayout] = useState("");
  const [emailTemplateContent, setEmailTemplateContent] = useState("");
  const [emailTemplateSubject, setEmailTemplateSubject] = useState("");
  const [emailTemplateBackendReponse, setEmailTemplateBackendReponse] =
    useState("");
	const [searchParams] = useSearchParams();
	useEffect(() => {
		const tab = searchParams.get("tab");
		if (tab) {
		  setValue(tab);
		}
	  }, [searchParams]);
	
	  const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	useEffect(() => {
		GetTenantById()
	}, []);
	
	function GetTenantById(){
		getTenantById(tenant_id, userInfo.accessToken)
			.then((r) => {
				const { statusCode, data } = r;
				if (statusCode === 200) setTenant(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					alignItems: "center",
					padding: "10px 0 20px 0",
					marginTop: 2,
				}}
			>
				<Box display="flex" justifyContent="space-between" width="100%">
					<Typography
						variant="h5"
						sx={{ color: "#000", textTransform: "capitalize" }}
					>
						{tenant.tenant_name}
					</Typography>
					{window.innerWidth >= 600 && (
						<img
							width="100px"
							src={
								tenant.logo_path
									? retrieveLogoUrl + "/" + tenant.logo_path
									: "/assets/no-image.png"
							}
						/>
					)}
					<AccountPopover />
				</Box>
				{window.innerWidth < 600 && (
					<img
						width="100px"
						src={
							tenant.logo_path
								? retrieveLogoUrl + "/" + tenant.logo_path
								: "/assets/no-image.png"
						}
					/>
				)}
			</Box>
			<Box sx={{ width: "100%", typography: "body1" }}>
				<TabContext value={value}>
					<Box>
						<TabList
							onChange={handleChange}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="lab API tabs"
						>
							<Tab
								label="Basic Info"
								sx={{ borderBottom: 1, borderColor: "divider" }}
								value="1"
							/>
							<Tab
								label="Users"
								sx={{ borderBottom: 1, borderColor: "divider" }}
								value="2"
							/>
							<Tab
								label="Payment Info"
								sx={{ borderBottom: 1, borderColor: "divider" }}
								value="3"
							/>
							<Tab
								label="Configuration"
								sx={{ borderBottom: 1, borderColor: "divider" }}
								value="4"
							/>
							<Tab
								label="Return Configuration"
								sx={{ borderBottom: 1, borderColor: "divider" }}
								value="5"
							/>
							{/* <Tab
								label="Pricing Configuration"
								sx={{ borderBottom: 1, borderColor: "divider" }}
								value="5"
							/> */}
						</TabList>
					</Box>
					<TabPanel
						value="1"
						sx={{
							padding: 0,
							paddingTop: { xs: 3 },
						}}
					>
						<Profile />
					</TabPanel>
					<TabPanel
						value="2"
						sx={{
							padding: 0,
							paddingTop: { xs: 1, md: 0 },
						}}
					>
						<UsersListing />
					</TabPanel>
					<TabPanel
						value="3"
						sx={{
							padding: 0,
							paddingTop: { xs: 0, md: 3 },
						}}
					>
						<BillingInvoice />
					</TabPanel>
					<TabPanel
						value="4"
						sx={{
							padding: 0,
							paddingTop: { xs: 1, md: 3 },
							background:"#F2F2F2"
						}}
					>
						<ConfigPage />
					</TabPanel>
					<TabPanel
						value="5"
						sx={{
							padding: 0,
							paddingTop: { xs: 1, md: 3 },
						}}
					>
						<ReturnConfigPage
						 emailTemplateLayout={emailTemplateLayout}
						 setEmailTemplateLayout={setEmailTemplateLayout}
						 emailTemplateContent={emailTemplateContent}
						 setEmailTemplateContent={setEmailTemplateContent}
						 emailTemplateSubject={emailTemplateSubject}
						 setEmailTemplateSubject={setEmailTemplateSubject}
						 emailTemplateBackendReponse={emailTemplateBackendReponse}
						 setEmailTemplateBackendReponse={setEmailTemplateBackendReponse}
						/>
					</TabPanel>
					{/* <TabPanel
						value="5"
						sx={{
							padding: 0,
							paddingTop: { xs: 1, md: 3 },
						}}
					>
						<PricingConfiguration 
						tenantData={tenant}
						getTenantById={GetTenantById}
						/>
					</TabPanel> */}
				</TabContext>
			</Box>
		</>
	);
};

export default ProfileDetails;
