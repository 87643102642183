import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWarehouse } from "src/features/returns/stepperFormSlice";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  Stack,
  FormControl,
} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useForm } from "react-hook-form";
import { warehouseAddressSchema } from "src/utils/validationSchemas/schemas";
import { joiResolver } from "@hookform/resolvers/joi";
import CountrySelect from "src/sections/auth/signin/formsContainer/billing/countrySelect";
import '../../style/styles.css'
const inputStyles={
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#AAAAAA",
    },
    "&:hover fieldset": {
      borderColor: "#000000", 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#002F43", 
    },
  },
  "& .MuiFormLabel-root": {
    color: "#AAAAAA", 
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#002F43", 
  },
}
const dropDownStyles={
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#AAAAAA", 
    },
    "&:hover fieldset": {
      borderColor: "#000000", 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#002F43", 
    },
  },
  "& .MuiInputLabel-root": {
    color: "#AAAAAA", 
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#002F43", 
  },
}
const WarehouseStepper = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const warehouse = useSelector((state) => state.form.warehouse);
  console.log("www",warehouse);
  
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    getValues,
    formState: { errors, isSubmitted },
    reset,
  } = useForm({
    resolver: joiResolver(warehouseAddressSchema),
  });

  const onSubmit = (data) => {
    console.log(data,"ksajkjsak")
    dispatch(updateWarehouse(data));
    handleNext();
  };

  useEffect(() => {
    if (warehouse) {
      reset({...warehouse,country:"US"});
    }
  }, [warehouse]);
console.log(getValues(),errors,"slakskjkje")
  return (
    <FormControl
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        padding: 5,
      }}
    >
      <Stack gap={1}>
        <Typography variant="h5" textAlign={"center"} sx={{ color: "#002F43"}}>
          Warehouse address 
        </Typography>
        <Typography variant="body2" textAlign={"center"} color={"#AAAAAA"}>
          The returned items will reach the address you've inputted.
        </Typography>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={6} md={4}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            {...register("firstName")}
            error={!!errors?.firstName}
            helperText={errors?.firstName?.message}
            className="text-Color"
            sx={{...inputStyles}}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            {...register("lastName")}
            error={!!errors?.lastName}
            helperText={errors?.lastName?.message}
            className="text-Color"
            sx={{...inputStyles}}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            fullWidth
            label="Address Line"
            name="addressLine"
            {...register("addressLine")}
            error={!!errors?.addressLine}
            helperText={errors?.addressLine?.message}
            // inputProps={{ maxLength: 50 }}
            className="text-Color"
            sx={{...inputStyles}} 
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            fullWidth
            label="City"
            name="city"
            {...register("city")}
            error={!!errors?.city}
            helperText={errors?.city?.message}
            className="text-Color"
            sx={{...inputStyles}}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            fullWidth
            label="State"
            name="state"
            {...register("state")}
            error={!!errors?.state}
            helperText={errors?.state?.message}
            className="text-Color"
            sx={{...inputStyles}}
          />
        </Grid>{" "}
        <Grid item xs={6} md={4}>
          <TextField
            fullWidth
            label="Zip Code"
            name="zipCode"
            {...register("zipCode")}
            error={!!errors?.zipCode}
            helperText={errors?.zipCode?.message}
            className="text-Color"
            sx={{...inputStyles}}
          />
        </Grid>{" "}
        <Grid item xs={6} md={4}>
          <CountrySelect
          name="country"
          disabled={true}
            setValue={setValue}
            watch={watch}
            error={errors?.country?.message}
            className="text-Color"
            sx={{ ...dropDownStyles }}
            isValueForOther={true}
          />
        </Grid>
      </Grid>

      <Stack alignItems="flex-end">
        <Button
          type="submit"
          disableRipple
          sx={{
            padding: 0,
            minWidth: "auto",
            ":hover": {
              bgcolor: "inherit",
            },
          }}
        >
          <ArrowCircleRightIcon
            sx={{
              color: Object.values(errors).some((error) => error)
                ? "#CFDEE9"
                : "#002F43",
              cursor: "pointer",
              width: "50px !important",
              height: "50px !important",
              transition: "transform 0.3s ease-in-out",
              "@keyframes pulse": {
                "0%": { transform: "scale(1)" },
                "50%": { transform: "scale(1.1)" },
                "100%": { transform: "scale(1)" },
              },
            }}
          />
        </Button>
      </Stack>
    </FormControl>
  );
};

export default WarehouseStepper;
