import { LoadingButton } from "@mui/lab";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import palette from "src/theme/palette";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import {
  getSpecificReturnConfig,
  updateSpecificReturnConfig,
} from "src/features/managereturns/returnsApi";
import { useDispatch,useSelector } from "react-redux";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import moment from "moment";
import { getUserNameById } from "src/features/managereturns/returnsApi";
import typography from "src/theme/typography";
// import { textStyle } from "../billing/cardsComponent/cardsUtils";

const textStyle = {
	color: palette.common.black,
	// fontSize: { xs: 16, md: 18 },
  // fontFamily: typography.fontFamily
};
const AutoApproval = () => {
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useDispatch();
  const [hasBeenSaved, setHasBeenSaved] = useState(false);
  const [value, setValue] = useState(false);
  const [lastUpdatedInfo, setLastUpdatedInfo] = useState("No updated yet");
const tenantsData = useSelector((state) => state.tenants);  
const { tenant_id, tenant_name,user_id,is_demo_user } = useSelector((state) => state.tenants);
const enhancedUserInfo = {
  accessToken: userInfo?.accessToken,
  tenant_id,
  tenant_name,
  user_id,
  is_demo_user,
};

function capitalizeWords(input) {
  return input
      .toLowerCase() 
      .split(' ') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join(' '); 
} 
const populateData = async () => {
  dispatch(incrementAwaitingApiCounter());
  try {
    const response = await getSpecificReturnConfig(
      enhancedUserInfo,
      "auto_approval"
    );
    if (response.statusCode === 200) {
      const data = response.data;

      if (Object.keys(data).length === 0) {
        setLastUpdatedInfo("No updated yet");
        dispatch(decrementAwaitingApiCounter());
        return;
      }
      setValue(data.value);

      const updatedAt = data.updated_at || data.created_at;
      const updatedById = data.updated_by || data.created_by;

      const updatedByName = await getUserNameById(enhancedUserInfo, updatedById);         
      const time = moment
        .unix(updatedAt)
        .tz("America/Los_Angeles")
        .format("MMM DD, YYYY");

      setLastUpdatedInfo(capitalizeWords(updatedByName) + " on " + time);
    }
    dispatch(decrementAwaitingApiCounter());
  } catch (error) {
    dispatch(decrementAwaitingApiCounter());
  }
};

  useEffect(() => {
    !hasBeenSaved && populateData();
  }, [hasBeenSaved]);

  const handleSaveAutoApproval = async () => {
    dispatch(incrementAwaitingApiCounter());

    const body = {
      rule_name: "auto_approval",
      value,
    };

    try {
      const response = await updateSpecificReturnConfig(enhancedUserInfo, body);
      if (response.statusCode === 200) setHasBeenSaved(false);
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
    }

    setHasBeenSaved(false);
  };

  return (
    <Stack
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Stack>
  <Typography textAlign={"right"} 
  // paddingTop={2}
   fontSize={14} sx={{...textStyle}} fontFamily={typography.fontFamily}>
          {lastUpdatedInfo !== "No updated yet"
            ? `Last modified by ${lastUpdatedInfo}`
            : ``}
        </Typography>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          paddingLeft={2}
        >
          <Stack width={"30%"}>
            <Box sx={{ display: "flex", gap: 1, mb: 1, alignItems: "center" }}>
              <Typography
                fontSize={18}
                fontFamily={typography.fontFamilySemiBold}
                sx={{...textStyle}}
              >
                Auto approval
              </Typography>
            </Box>
            <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
              Enable automatic approval for return orders
            </Typography>
          </Stack>
          <Stack>
            <Switch
              checked={value}
              disabled // remove once the auto approval has been implemented
              onChange={(event) => {
                setValue(event.target.checked);
                setHasBeenSaved(true);
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Box flex={1}></Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "20px",
          boxShadow: "0px -8px 30px 0px #00000014",
          backgroundColor: "white",
          bottom: "-20px",
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          zIndex: "1",
        }}
      >
        <LoadingButton
          variant="contained"
          sx={{ width: "130px", backgroundColor: palette.secondary.main }}
          onClick={handleSaveAutoApproval}
          disabled={!hasBeenSaved}
        >
          Save
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default AutoApproval;
