import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { Box, Button, Grid, Typography, Autocomplete, Chip, TextField } from '@mui/material';
import "./blogs.scss"
import BlogsQuill from 'src/components/BlogsComponent/BlogsQuill/BlogsQuill';
import BlogsInput from 'src/components/BlogsComponent/BlogsInput';
import { Helmet } from 'react-helmet-async';
import AccountPopover from 'src/layouts/dashboard/header/AccountPopover';
import { Controller, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BlogsImageUploader from './BlogsImageUploader';
import palette from 'src/theme/palette';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { createBlogsApi, deleteBlogsApi, getBlogsByIdApi, updateBlogsApi } from 'src/features/blogs/blogsapi';
import ModalComponent from 'src/components/modal/ModalComponent';
import { useDispatch } from 'react-redux';
import { decrementAwaitingApiCounter, incrementAwaitingApiCounter } from "../../../src/features/ui/uiState";
import Modal from "@mui/material/Modal";
import { retrieveLogoUrl } from 'src/features/config/configApi';
import { setViewBlog } from 'src/features/blogs/blogsState';
import { BLOGS_STATUS, popUpMessages } from 'src/utils/core';


const CreateBlogs = () => {
  const { register, handleSubmit, formState: { errors }, control, reset, getValues,setValue } = useForm({mode:"onChange"})
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const blog_id = queryParams.get('blog_id');
  const [isPopupOpen, setIsPopUpOpen] = useState(false)
  const [singleBlogData, setSingleBlogData] = useState()
  const [bannerImage, setBannerImage] = useState(null);
  const [footerImage, setFooterImage] = useState(null);
  const [imgError, setImgError] = useState({
    bannerImage: false,
    footerImage: false
  })

  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleModalClose = () => {
    setModal({ ...modal, open: false });
    navigate("/dashboard/blogs")
  };
  const [popupMessage, setPopupMessage] = useState()
  const [submitPayload, setSubmitPayload] = useState()

  useEffect(() => {
    if (blog_id !== null) getBlogsById()
  }, [])


  const getBlogsById = async () => {
    try {
      dispatch(incrementAwaitingApiCounter());

      let response = await getBlogsByIdApi(blog_id);
      if (response.statusCode == 200) {
        reset({ ...response?.data})
        setValue("metaKeyword",response.data.metaKeyword.split(", "))
        setSingleBlogData(response?.data)
        setFooterImage(response?.data?.footerImage ? { url: `${retrieveLogoUrl}/${response.data.footerImage}`,path:response.data.footerImage } : null)
        setBannerImage(response?.data?.bannerImage ? { url: `${retrieveLogoUrl}/${response.data.bannerImage}`,path:response.data.bannerImage } : null)
        dispatch(decrementAwaitingApiCounter());
      }
    } catch (err) {
      dispatch(decrementAwaitingApiCounter());
    }
  }
  const onSubmit = (data, status) => {
    if (bannerImage == null) {
      setImgError({
        ...imgError,
        bannerImage: true
      })
      return
    }
    let payload = {
      ...data,
      status,
      bannerImage: bannerImage !== null ? bannerImage.path : null,
      footerImage: footerImage !== null ? footerImage.path : null,
      thumbnailUrl: bannerImage !== null ? bannerImage.thumbnailUrl : null,
      metaKeyword: data.metaKeyword.map((val) => val).join(", ")
    }
    if(status=="preview"){
      handleView(payload)
      return;
    }

    setSubmitPayload(payload)
    setIsPopUpOpen(true)
    setPopupMessage(popUpMessages[status])
  }

  const handleApiCall = async (status) => {
    try {
      if (blog_id == null) {
        let response = await createBlogsApi(submitPayload)
        if (response?.statusCode == 200 || response.status == 200) {
          setIsPopUpOpen(false);
          setModal({
            open: true,
            message: "Blog saved successfully!",
            severity: 'success',
          });
        }
      } else if (blog_id !== null && (status == BLOGS_STATUS.DRAFT || status == BLOGS_STATUS.PUBLISHED || status == BLOGS_STATUS.ARCHIVE || status == BLOGS_STATUS.UNARCHIVE)) {
        delete submitPayload.order;
        delete submitPayload.updated_at;
        delete submitPayload._id;
        setIsPopUpOpen(false)
      
        let response = await updateBlogsApi(submitPayload, blog_id)
        if (response?.statusCode == 200 || response.status == 200) {
          setIsPopUpOpen(false)
          setModal({
            open: true,
            message: `Blog updated successfully!`,
            severity: 'success',
          });
  
        }
      } else if (status == BLOGS_STATUS.DELETE && blog_id !== null) {
        let response = await deleteBlogsApi({ blog_id: [blog_id] })
        if (response?.statusCode == 200) {
          setIsPopUpOpen(false)
          setModal({
            open: true,
            message: `Blog deleted successfully!`,
            severity: 'success',
          });
  
        }
      }
    } catch (err) {
      setIsPopUpOpen(false)
    }
  }

  const styleInput = {
    width: "100%",
    marginTop: { xs: "6px", md: "10px" },
    backgroundColor: "#fff",
    borderRadius: "10px",
    color: "#000",
    "& .MuiFormHelperText-root": {
      textAlign: "left",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1C272A33",  
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1C272A33",  
      },
    },
    "& .MuiOutlinedInput-input": {
      // Modify the input text color if needed
      color: "#000",
    },
  };
  
  

  const handleView = (values) => {
    dispatch(setViewBlog(values))
    window.open("/preview","_blank")
  }

  return (
    <div>
      <Helmet>
        <title> Blogs | BeyondCarts CMS </title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #c4c4c4",
          paddingBottom: 2,
          marginTop: "20px",
        }}
      >
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <ArrowBackIcon sx={{ fill: "#000", fontSize: "35px", cursor: "pointer" }} onClick={() => navigate("/dashboard/blogs")} />
          <Typography variant="h4" sx={{ color: "#000" }}>
            {blog_id !== null ? "Edit blog" : "Add blog"}
          </Typography>
        </Box>
        <AccountPopover />
      </Box>

      {blog_id !== null &&
        <Box display={"flex"} justifyContent={"flex-end"} gap={2} alignItems={"center"}>
          <Box mt={3}>
            <span
              style={{
                height: "20px",
                width: "40px",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "14px",
                fontWeight: 700,
                background: singleBlogData?.status == BLOGS_STATUS.DRAFT && "#F8E6C3" || singleBlogData?.status == BLOGS_STATUS.PUBLISHED && "#C9FBC4" || singleBlogData?.status == BLOGS_STATUS.ARCHIVE && "#FEC4C4",
                color: singleBlogData?.status == BLOGS_STATUS.DRAFT && "#DC9510" || singleBlogData?.status == BLOGS_STATUS.PUBLISHED && "#1D9210" || singleBlogData?.status == BLOGS_STATUS.ARCHIVE && "#E50909"
              }}
            >{singleBlogData?.status == BLOGS_STATUS.DRAFT ? "Draft" : singleBlogData?.status == BLOGS_STATUS.PUBLISHED ? "Published" : "Archived"}</span>
          </Box>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{
              marginTop: 3,
              boxShadow: "none",
            }}
            disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
            onClick={handleSubmit((value) => onSubmit(value, "draft"))}
          >
            Save as draft
          </Button>

          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
            sx={{
              marginTop: 3,
              boxShadow: "none",
              backgroundColor:
                palette.secondary.main,
            }}
            onClick={handleSubmit((value) => onSubmit(value, "published"))}
          >
            Publish
          </Button>
        </Box>
      }

      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BlogsInput
              type={"text"}
              label={"Blog title *"}
              name={"blogTitle"}
              charactersAllowed={250}
              disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
              register={register('blogTitle', {
                required: 'Title is required',
                maxLength: {
                  value: 250,
                  message: 'Title must be less than 250 characters'
                }
              })}
              errors={errors}
            />
          </Grid>

        </Grid>
      </Box>

      <Box>
        <Grid container spacing={3}>
          <Grid item xs={6} mt={3}>
            <label className='formLabel'>Banner image *</label>
            <BlogsImageUploader
              required={imgError.bannerImage && bannerImage == null}
              setValue={(val) => setBannerImage(val)}
              disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
              value={bannerImage}
              handleImageDelete={(e) => { setBannerImage(null) }}
              fileName={"bannerImage"}
            />
          </Grid>
          <Grid item xs={6} mt={3}>
            <label className='formLabel'>Footer banner image (Optional)</label>
            <BlogsImageUploader
              setValue={(val) => setFooterImage(val)}
              disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
              fileName={"footerImage"}
              required={imgError.footerImage}
              value={footerImage}
              handleImageDelete={(e) => { setFooterImage(null) }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Grid container >
          <Grid item xs={6}>
            <label className='formLabel'>Meta keywords *</label>
            <Controller
              name="metaKeyword"
              control={control}
              defaultValue={[]}
              rules={{
                required: 'Meta keywords are required',
                validate: (value) => value.length > 0 || 'At least one keyword is required'
              }}
              render={({ field }) => (
                <Autocomplete
                {...field}
                value={field.value} 
                clearIcon={false}
                options={[]}
                freeSolo
                multiple
                onChange={(_, value) => field.onChange(value)}
                renderTags={(value, props) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...props({ index })}
                      sx={{ color: 'black', background: 'rgba(0, 0, 0, 0.35)' }}
                      key={index}
                    />
                  ))
                }
                disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
                renderInput={(params) => <TextField {...params} />}
                sx={styleInput}
              />
              )}
            />
            {errors.metaKeyword && <span className={"text-error"}>{errors.metaKeyword.message}</span>}
          </Grid>
          <Grid xs={6} pl={3}>
            <BlogsInput
              type={"textArea"}
              label={"Meta description *"}
              charactersAllowed={500}
              name={"metaDescription"}
              register={register('metaDescription', {
                required: 'Meta description is required',
                maxLength: {
                  value: 500,
                  message: 'Meta descripition must be less than 500 characters'
                }
              })}
              errors={errors}
              disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <BlogsInput
          name={"footerUrl"}
          type={"text"}
          label={"Footer banner image URL (Optional)"}
          register={register('footerUrl', { required: '' })}
          errors={errors}
          disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
        />
      </Box>

      <Box mt={3}>
        <BlogsQuill
          name="blogContent"
          errors={errors}
          control={control}
          disabled={singleBlogData?.status == BLOGS_STATUS.ARCHIVE}
        />
      </Box>
      <Box mt={5}>
        
      </Box>

      {blog_id == null &&
        <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={5}>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{
              marginTop: 3,
              boxShadow: "none",
            }}
            onClick={handleSubmit((value) => onSubmit(value, "preview"))}
          >
            Preview
          </Button>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{
              marginTop: 3,
              boxShadow: "none",
            }}
            onClick={handleSubmit((value) => onSubmit(value, "draft"))}
          >
            Save as draft
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{
              marginTop: 3,
              boxShadow: "none",
              backgroundColor:
                palette.secondary.main,
            }}
            onClick={handleSubmit((value) => onSubmit(value, "published"))}
          >
            Publish
          </Button>
        </Box>
      }

      {blog_id !== null &&
        <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={5}>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{
              marginTop: 3,
              boxShadow: "none",
            }}
            onClick={handleSubmit((value) => onSubmit(value, "preview"))}
          >
            Preview
          </Button>
          <Button variant="contained" color="error"
            sx={{
              marginTop: 3,
            }}
            onClick={() => { setPopupMessage(popUpMessages["delete"]), setIsPopUpOpen(true) }}
          >
            Delete
          </Button>

          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{
              marginTop: 3,
              boxShadow: "none",
            }}
            onClick={handleSubmit((value) => onSubmit(value, singleBlogData?.status == BLOGS_STATUS.ARCHIVE ? "unarchive" : "archive"))}
          >
            {singleBlogData?.status == BLOGS_STATUS.ARCHIVE ? "Unarchive" : "Archive"}
          </Button>
        </Box>
      }


      <ModalComponent openModal={isPopupOpen} handleCloseModal={() => setIsPopUpOpen(false)} handleBtnClick={() => handleApiCall(popupMessage?.status)} cancelBtnText={"Cancel"} btnText={popupMessage?.buttonText} variant="outlined">
        <div>
          <p style={{ fontSize: "20px", marginBottom: "10px" }}><b>{popupMessage?.popupMessage}</b></p>
          <span style={{ fontSize: "16px" }}>{popupMessage?.subText}</span>
        </div>
      </ModalComponent>
      <Modal
        open={modal.open}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ color: modal.severity === 'success' ? 'green' : 'red' }}
          >
            {modal.severity === 'success' ? 'Success' : 'Error'}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }} style={{ color: 'black' }}>
            {modal.message}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleModalClose}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateBlogs;