const REGEX_EXPRESSIONS = {
    FIRST_NAME: /^([a-zA-Z][ -\s']?)*$/,
    LAST_NAME: /^([a-zA-Z][ -\s']?)*$/,
    FULL_NAME: /^([a-zA-Z][ -\s']?)*$/,
    ADDRESS: /^([A-Za-z0-9\s_,./\\#&`~-]?)*$/,
    EMAIL:
      /^(([a-zA-Z0-9]+[_]{0,2})+(?:[._-]+[a-zA-Z0-9]+[_]{0,2})*)@([a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,})$/,
    EMAILOLD:
      /^(([^<>()[\]\\.,;:\s@"%']+(\.[^<>()[\]\\.,;:\s@"%']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE: /^\+?1? ?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/,
    CITY_NAME: /^[a-zA-Z]+((?:(([',] ?)|-)([a-zA-Z]+))|(?: [a-zA-Z]+))*$/,
    ZIP_CODE: /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/, // This expression matches three different formats of postal codes: 5 digit US ZIP code, 5 digit US ZIP code + 4, and 6 digit alphanumeric Canadian Postal Code
    CREDIT_CARD:
      /^(?:4[0-9]{3} [0-9]{4} [0-9]{4} [0-9](?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720) [0-9]{4} [0-9]{4} [0-9]{4}|3[47][0-9]{2} [0-9]{4} [0-9]{4} [0-9]{3}|6(?:011|5[0-9]{2}) [0-9]{4} [0-9]{4} [0-9]{4}|(\d{4} \d{4} \d{4} \d{1,4}))$/,
    CREDIT_CARD_VISA: /^(4[0-9]{3} [0-9]{4} [0-9]{4} [0-9](?:[0-9]{3})?)$/,
    CREDIT_CARD_MASTER_CARD:
      /^((?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720) [0-9]{4} [0-9]{4} [0-9]{4})$/,
    CREDIT_CARD_AMEX: /^(3[47][0-9]{2} [0-9]{4} [0-9]{4} [0-9]{3})$/,
    CREDIT_CARD_DISCOVER: /^(6(?:011|5[0-9]{2}) [0-9]{4} [0-9]{4} [0-9]{4})$/,
    CREDIT_CARD_GENERAL: /^(\d{4} \d{4} \d{4} \d{1,4})$/,
    CREDIT_CARD_GENERAL_AMEX:
      /^(3[47][0-9]{2} [0-9]{6} [0-9]{5})|(\d{4} \d{4} \d{4} \d{1,4})$/,
    // Password should be minimum of 8 characters with at least one number,one small alphabet and one capital alphabet with Special Chracters: @$!%*?&^#
    PASSWORD:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#])[A-Za-z\d@$!%*?&^#]+$/,
    EXPIRY: /^((0?[1-9]|1[0-2])\/\d{2})$/,
  };
  
  const ZIP_CODE_REGEX = {
    US: /^(\d{5}(?:-\d{4})?)$/,
    CA: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/,
  };
  
  export { REGEX_EXPRESSIONS, ZIP_CODE_REGEX };
  