import dayjs from "dayjs";
import moment from 'moment-timezone';

const validateEmail = (email) => email.match(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export default validateEmail;

export const titleCase = (data) => {
  const formattedData = data.split('_').join(' ');
  const str = formattedData.split(' ');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
};

export const removeTitleCase = (data) => {
  const formattedData =  data.split(' ').join('_');
  const str = formattedData.toLowerCase().split(' ');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toLowerCase() + str[i].slice(1);
  }
  return str.join(' ');
};

export const removeSessionData = (sessionKey) => {
  sessionStorage.removeItem(sessionKey);
};

export const setSessionData = (sessionKey, value) => {
  sessionStorage.setItem(sessionKey, JSON.stringify(value));
};

export const getSessionData = (sessionKey) => {
  const sessionData = JSON.parse(sessionStorage.getItem(sessionKey));
  return sessionData;
};

export const unixToDate = (unixdata) => {
  if(unixdata !== '-'){
  const date = moment.unix(unixdata).tz("America/Los_Angeles").format('MMM DD, YYYY');
  return date;
  }
  return unixdata;
};

export const unixToTime = (unixdata) => {
  const time = moment.unix(unixdata).tz("America/Los_Angeles").format('HH:mm:ss');
  return time;
};

export const dateDiff = (startDate, endDate)=>{
  const start = moment(startDate)
  const end = moment(endDate)
  return end.diff(start, "days");
}

export const dateToUnix = (date) => {
  const momentDateTime = moment.tz(date, 'MM/DD/YYYY', "America/Los_Angeles");
  const epoch = momentDateTime.unix();
  return epoch;
}

export const datePstToUtc = (date) =>{
  return moment.utc(date).format('MM/DD/YYYY')
}

export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  }
  return obj
}

export const removeItemOnce = (arr, value) =>{
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export const popUpMessages = {
  "draft": {
    popupMessage: "Are you sure you want to save this blog as a draft?",
    subText: "It will not be visible to readers until published",
    status: "draft",
    buttonText: "Save as draft"
  },
  "archive": {
    popupMessage: "Are you sure you want to archive this blog?",
    subText: "Archived blogs will no longer be visible to readers",
    status: "archive",
    buttonText: "Archive"
  },
  "unarchive": {
    popupMessage: "Are you sure you want to unarchive this blog?",
    subText: "UnArchived blogs will be visible to readers",
    status: "unarchive",
    buttonText: "Unarchive"
  },
  "delete": {
    popupMessage: "Are you sure you want to delete this blog?",
    subText: "This blog will be permanently deleted and cannot be restored",
    status: "delete",
    buttonText: "Delete"
  },
  "published": {
    popupMessage: "Are you sure you want to publish this blog?",
    subText: "Once published, it will be visible to all readers.",
    status: "published",
    buttonText: "Publish"
  }
}

export const BLOGS_STATUS={
  DRAFT:"draft",
  ARCHIVE:"archive",
  UNARCHIVE:"unarchive",
  PUBLISHED:"published",
  DELETE:"delete"
}
//---------
export const unixToDateAndTime = (unixdata) => {
  const time = moment
    .unix(unixdata)
    .tz("America/Los_Angeles")
    .format("MMM DD, YYYY HH:mm:ss");
  return time;
};

export function getNotificationStatusText(status) {
  const notificationStatus = {
    started: "Started",
    pending: 'In Progress',
    successful: 'Completed',
    unsuccessful: 'Failed',
    stop: 'Stopped'
  }
  if (typeof notificationStatus[status] != "undefined") {
    return notificationStatus[status];
  } else {
    return status;
  }
}

export const groupByArrayData = (array, key) => {
  return array.reduce((acc, obj) => {
    const keyValue = obj[key];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(obj);
    return acc;
  }, {});
};

export const convertUTCtoDateFormat = (timestamp) => {
  const time = moment
    .unix(timestamp)
    .tz("America/Los_Angeles")
    .format("MMMM D, YYYY");
  return time;
};

export const getCurrencySymbol = (currencyCode, locale = 'en-US') => {
  console.log('currencyCode ', currencyCode)
  if (!!currencyCode) {
    return (0).toLocaleString(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).replace(/\d/g, '').trim();
  } else {
    return '';
  }
};

export const setFavicon = (url) => {
  try {
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  } catch (e) {

  }
};

export function convertToPounds(weight, unit) {
  let pounds=0;

  if (unit === 'kg') {
      pounds = weight * 2.20462;
  } else if (unit === 'g') {
      pounds = weight * 0.00220462;
  } else if (unit === "lb") {
    pounds = weight;
  } else if (unit === 'oz') {
    pounds = weight * 0.0625;
  } else {
    //throw new Error('Invalid unit. Please use "kg" or "g".');
  }
  return parseFloat(pounds.toFixed(2));
}
//--------------------