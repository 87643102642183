import { useEffect, useState } from "react";
import EmailTemplateItem from "src/pages/EmailTemplate/EmailTemplateItem";
import EmailTemplateEdition from "./EmailTemplateEdition";
import {
  Box,
  Grid,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Modal,
  Button,
  Dialog,
  Divider,
} from "@mui/material";
import { retrieveLogoUrl } from "src/features/returnConfiguration/emailTemplatesApi";
import {
  getEmailTemplatesConfig,
  getEmailTemplatesList,
  updateEmailTemplateConfig,
  uploadEmailTemplateLogo,
  uploadToS3,
  updateEmailPreferences
} from "src/features/returnConfiguration/emailTemplatesApi";
import { ChromePicker } from "react-color";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import moment from "moment";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import PreviewTemplate from "src/pages/ReturnConfiguration/EmailTemplate/PreviewTemplate";
import palette from "src/theme/palette";
// import { getUserNameById } from "src/utils/getUserNameById";
import { getUserNameById } from "src/features/managereturns/returnsApi";
import { emailConfigurationTexts } from "src/pages/EmailTemplate/EmailConfigurationTexts";

import typography from "src/theme/typography";
import { useSelector } from "react-redux";
import {
  getTenant,
} from "src/features/usersPage/usersApi";
const textStyle = {
  color: palette.common.black,
  fontSize: { xs: 16, md: 18 },
};

const popUpModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "auto", md: "auto" },
  borderRadius: "20px",
  background: "#FFF",
  overflowX: "hidden",
  outline: "none",
};

const EmailTemplate = ({
  emailTemplateLayout,
  setEmailTemplateLayout,
  emailTemplateContent,
  setEmailTemplateContent,
  emailTemplateSubject,
  setEmailTemplateSubject,
  emailTemplateBackendReponse,
  setEmailTemplateBackendReponse,
}) => {
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();

  const [openEditTemplateUI, setOpenEditTemplateUI] = useState("");

  const [logoFile, setLogoFile] = useState("");
  const [logoPreview, setLogoPreview] = useState("");

  const [brandColor, setBrandColor] = useState("#000000");
  const [brandColorError, setBrandColorError] = useState("");
  const [brandColorPicker, setBrandColorPicker] = useState("");
  const [lastUpdatedInfo, setLastUpdatedInfo] = useState("No updated yet");
  const [openPreview, setOpenPreview] = useState(false);
  const [templateNameSelected, setTemplateNameSelected] = useState(false);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [refresh, setRefresh] = useState(false);
  const tenantsData = useSelector((state) => state.tenants);  
   const [emailPreferences, setEmailPreferences] = useState({});
  const [isEmailPreferencesUpdate, setIsEmailPreferencesUpdate] =
    useState(false);
  const { tenant_id, tenant_name,user_id,is_demo_user } = useSelector((state) => state.tenants);
  const enhancedUserInfo = {
    accessToken: userInfo?.accessToken,
    tenant_id,
    tenant_name,
    user_id,
    is_demo_user,
  };
  

  const handleBrandColor = () => {
    setBrandColorPicker(!brandColorPicker);
  };
  const handleBrandColorChange = async (color) => {
    setBrandColor(color.hex);
    setErrors({ ...errors, brandcolor: null });
    try {
      const response = await handleUpdateEmailTemplateConfig({
        brand_color: color.hex,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
    const handleCheckboxChange = (templateName, isChecked) => {
   
      // handleUpdateEmailPreferences({
      //   ...emailPreferences,
      //   [templateName]: isChecked,
      // });
    };
    const handleUpdateEmailPreferences = async (updatedPreferences) => {
      try {
        dispatch(incrementAwaitingApiCounter());
        const response = await updateEmailPreferences(
          updatedPreferences,
          enhancedUserInfo,
          tenant_name,
          tenant_id
        );
        if (response?.status === 200) {
          setIsEmailPreferencesUpdate(true);
          // setShowConfirmation(true);
          getTenant(enhancedUserInfo, tenant_id)
            .then((r) => {
              const { statusCode, data } = r;
              if (statusCode === 200) {
                setEmailPreferences({
                  delivered_email: data.delivered_email,
                  first_scan_email: data.first_scan_email,
                  out_for_delivery_email:
                    data.out_for_delivery_email,
                });
        dispatch(decrementAwaitingApiCounter());

              }
            })
            .catch((e) => console.log(e));
        dispatch(decrementAwaitingApiCounter());

        }
        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        console.log(error);
      }
    };

  const validateColor = (name, value) => {
    const colorPattern = /^#[a-fA-F0-9]{6}$/;
    if (!value || colorPattern.test(value)) {
      setErrors((prev) => ({ ...prev, [name]: null }));
      return true;
    } else {
      setErrors((prev) => ({ ...prev, [name]: "Invalid color format" }));
      return false;
    }
  };
  const handleColorChange = async (event) => {
    const { value } = event.target;
    if (value.length <= 7 && (value === "" || /^#[a-zA-Z0-9]*$/.test(value))) {
      setBrandColor(value);
      if (validateColor("brandcolor", value)) {
        try {
          const response = await handleUpdateEmailTemplateConfig({
            brand_color: value,
          });
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const [emailTemplateItemData, setEmailTemplateItemData] = useState(
    emailConfigurationTexts.emailTemplatePageArr
  );

  const handleLastUpdatedInfo = async (
    templateLastUpdateList,
    currentTextData
  ) => {
    if (templateLastUpdateList?.length > 0) {
      const currentTemplate = templateLastUpdateList.find(
        (updateInfo) =>
          updateInfo.template_name === currentTextData.templateName
      );
      if (currentTemplate) {
        const updatedById =
          currentTemplate.updated_by || currentTemplate.created_by;
        const updatedAt =
          currentTemplate.updated_at || currentTemplate.created_at;

        const updatedByName =
          (await getUserNameById(enhancedUserInfo, updatedById)) || "Unknown User";

        return `${updatedByName} on ${moment
          .unix(updatedAt)
          .tz("America/Los_Angeles")
          .format("MMM DD, YYYY")}`;
      } else return "No updated yet";
    } else return "No updated yet";
  };

  const handleUpdateEmailTemplateConfig = async (valueToUpdate) => {
    const body = {
      rule_name: "email_template",
      value: {
        brand_logo: valueToUpdate.brand_logo
          ? valueToUpdate.brand_logo
          : logoFile,
        brand_color:
          valueToUpdate.brand_color === "#" ? "" : valueToUpdate.brand_color,
      },
    };
    try {
      const response = await updateEmailTemplateConfig(body, enhancedUserInfo);
      return response;
    } catch (error) {}
  };

  const handleLogoFileChange = async (event) => {
    // in event.target.files[0] is the name of the upload file
    const file = event?.target?.files?.[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);

      const fileType = file.type;
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/svg+xml",
      ];
      if (!validImageTypes.includes(fileType)) {                          
        alert("File format must be JPG, PNG, or SVG.");
        URL.revokeObjectURL(fileURL);
        return;
      }

      const fileExtension = file.name.split(".").pop();
      const fileBody = {
        content_type: fileType,
        file_extension: fileExtension,
        upload_task: "email_template",
      };

      try {
        const uploadResponse = await uploadEmailTemplateLogo(
          enhancedUserInfo,
          fileBody
        );
        if (uploadResponse.statusCode === 200) {
          // TODO: this should be called in the save btn
          await handleUpdateEmailTemplateConfig({
            brand_logo: uploadResponse.data.file_path,
          });

          const headers = {
            "Content-Type": fileType,
            "Content-Length": file.size.toString(),
            "x-amz-acl": "public-read",
          };
          await uploadToS3(uploadResponse.data.pre_signed_url, file, headers);

          // const finalURL = `${retrieveLogoUrl}${uploadResponse.data.file_path}`;
          const finalURL = `https://stage-beyondcarts-logo.s3.us-east-2.amazonaws.com/${uploadResponse.data.file_path}`;
          setLogoFile(uploadResponse.data.file_path);
          setLogoPreview(finalURL);
        } else throw new Error("Failed to get pre-signed URL");
      } catch (error) {
        console.error(error.message);
        alert("Failed to upload image.");
      } finally {
        URL.revokeObjectURL(fileURL);
      }
    } else {
      let defaultImg = await handleUpdateEmailTemplateConfig({
        brand_logo: "/assets/no-img.svg",
      });
      setLogoPreview("");
    }
  };

  const handleLogoClick = () => document.getElementById("logo-button").click();
  const handleRemoveLogo = () => {
    setOpen(true);
  };

  const ImageUploadSection = ({
    imagePreview,
    handleChange,
    handleClick,
    id,
    handleRemove,
  }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Box sx={{ position: "relative", pl: 1 }}>
            <Box
              onClick={handleClick}
              style={{
                cursor: "pointer",
                position: "relative",
                // height: "80px",
                // width: "80px",
              }}
            >
              <img src="/assets/img-frame.svg" alt="image frame" />
              <img
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxHeight: 40,
                  display: "block",
                }}
                src={imagePreview}
              />
            </Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id={id}
              type="file"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ color: palette.primary.main, mb: 1 }}>
            Upload Logo
          </Typography>
          <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
            Format : JPG, PNG or SVG
          </Typography>
        </Box>
      </Box>
      {imagePreview !== "/assets/no-img.svg" && (
        <Button
          variant="outlined"
          sx={{ width: "130px" }}
          onClick={handleRemove}
        >
          Remove
        </Button>
      )}
    </Box>
  );

  useEffect(() => {
    const getEmailTemplateInfo = async () => {
      dispatch(incrementAwaitingApiCounter());

      try {
        const configResponse = await getEmailTemplatesConfig(enhancedUserInfo);
        const templatesResponse = await getEmailTemplatesList(enhancedUserInfo);

        if (
          configResponse?.statusCode === 200 &&
          templatesResponse?.statusCode === 200
        ) {
          const configData = configResponse.data;

          setLogoFile(configData?.value?.brand_logo || "/assets/no-img.svg");
          setLogoPreview(
            configData?.value?.brand_logo === "/assets/no-img.svg"
              ? "/assets/no-img.svg"
              :`https://stage-beyondcarts-logo.s3.us-east-2.amazonaws.com/${configData?.value?.brand_logo}`
              // : retrieveLogoUrl + configData?.value?.brand_logo
          );
          setBrandColor(configData?.value?.brand_color || "#000000");

          if (!(configData.updated_at && configData.created_at)) {
            setLastUpdatedInfo("No updated yet");
          } else {
            const time = moment
              .unix(configData.updated_at || configData.created_at)
              .tz("America/Los_Angeles")
              .format("MMM DD, YYYY");

            const username = await getUserNameById(
              enhancedUserInfo,
              configData.updated_by
            );

            setLastUpdatedInfo(
              (username || configData.created_by) + " on " + time
            );
          }

          const templateData = templatesResponse.data;
          setEmailTemplateList(templateData);

          const lastUpdateDate = templateData.map((el) => ({
            template_name: el.template_name,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
          }));

          const updatedData = await Promise.all(
            emailTemplateItemData?.map(async (el) => {
              const lastUpdated = await handleLastUpdatedInfo(
                lastUpdateDate,
                el
              );
              return { ...el, lastUpdatedInfo: lastUpdated };
            })
          );

          setEmailTemplateItemData(updatedData);
        }

        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        console.error("Error fetching template info:", error);
        dispatch(decrementAwaitingApiCounter());
      }
    };

    // Call the function to fetch email templates and config
    getEmailTemplateInfo();
  }, [refresh, openEditTemplateUI]);

  return (
    <>
      {openEditTemplateUI ? (
        <EmailTemplateEdition
          template_name={openEditTemplateUI}
          setOpenEditTemplateUI={setOpenEditTemplateUI}
          emailTemplateItemData={emailTemplateItemData}
          logoFile={logoFile}
          setRefresh={setRefresh}
          emailTemplateLayout={emailTemplateLayout}
          setEmailTemplateLayout={setEmailTemplateLayout}
          emailTemplateContent={emailTemplateContent}
          setEmailTemplateContent={setEmailTemplateContent}
          emailTemplateSubject={emailTemplateSubject}
          setEmailTemplateSubject={setEmailTemplateSubject}
          emailTemplateBackendReponse={emailTemplateBackendReponse}
          setEmailTemplateBackendReponse={setEmailTemplateBackendReponse}
        />
      ) : (
        <>
          <Typography textAlign={"right"} 
          paddingBottom={1} 
          fontSize={14} style={{...textStyle}}>
            {lastUpdatedInfo !== "No updated yet"
              ? `Last modified by ${lastUpdatedInfo}`
              : ``}
          </Typography>

          <Grid container spacing={3} sx={{ pt: 1, pb: 3, pl: 2 }}>
            <Grid item xs={3}>
              <Box
                sx={{ display: "flex", gap: 1, mb: 1, alignItems: "center" }}
              >
                <Typography
                  fontSize={18}
                  fontFamily={typography.fontFamilySemiBold}
                  style={{...textStyle}}
                >
                  Branded Logo
                </Typography>
              </Box>
              <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }} >
                You can customize the logo to suit your branding needs.
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid #E6E1E1",
                  padding: "5px 10px 5px 0",
                  borderRadius: "12px",
                  justifyContent: "space-between",
                }}
              >
                <ImageUploadSection
                  imagePreview={
                    logoPreview !== "" ? logoPreview : "/assets/no-img.svg"
                  }
                  handleChange={handleLogoFileChange}
                  handleClick={handleLogoClick}
                  id="logo-button"
                  handleRemove={handleRemoveLogo}
                />
              </Box>
            </Grid>
          </Grid>

          <Divider />

          <Grid container spacing={3} sx={{ py: 3, pl: 2 }}>
            <Grid item xs={3}>
              <Box
                sx={{ display: "flex", gap: 2, mb: 1, alignItems: "center" }}
              >
                <Typography
                  fontSize={18}
                  fontFamily={typography.fontFamilySemiBold}
                  style={{...textStyle}}
                >
                  Color theme
                </Typography>
              </Box>
              <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
                Choose or customize the colors for your brand.
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography sx={{ color: "#000", marginLeft: 1 }}>
                      Brand color
                    </Typography>
                  </Box>
                  <Box sx={{ position: "relative" }}>
                    <OutlinedInput
                      size="small"
                      value={brandColor}
                      onClick={handleBrandColor}
                      placeholder="#000000"
                      onChange={handleColorChange}
                      sx={{ m: 1, color: "#000" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <Box
                              sx={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "5px",
                                backgroundColor: `${
                                  brandColor ? brandColor : "black"
                                }`,
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                      error={errors.brandcolor}
                    />
                    {errors.brandcolor && (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          position: "absolute",
                          bottom: "-15px",
                          ml: 1,
                        }}
                      >
                        {errors.brandcolor}
                      </Typography>
                    )}
                  </Box>
                  {brandColorPicker && (
                    <Box style={{ position: "absolute", zIndex: 2 }}>
                      <Box
                        style={{
                          position: "fixed",
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                        onClick={() => setBrandColorPicker(false)}
                      />
                      <ChromePicker
                        color={brandColor}
                        onChange={handleBrandColorChange}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Divider />
          {emailTemplateItemData?.map((el, index) => (
            <EmailTemplateItem
              key={index}
              data={el}
              setOpenEditTemplateUI={setOpenEditTemplateUI}
              setOpenPreview={setOpenPreview}
              setTemplateNameSelected={setTemplateNameSelected}
              emailTemplateList={emailTemplateList}
              lastUpdatedInfo={el.lastUpdatedInfo}
              hideDivider={index === emailTemplateItemData.length - 1}
              onCheckboxChange={handleCheckboxChange}
            />
          ))}
        </>
      )}

      <Modal open={openPreview} onClose={() => setOpenPreview(false)}>
        <Box sx={popUpModalStyle}>
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: 11,
              right: 11,
              zIndex: 2,
            }}
            onClick={() => setOpenPreview(false)}
          >
            <img width={25} src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>
          <PreviewTemplate
            template_name={templateNameSelected}
            logoFile={logoFile}
            isRenderingFromModal
          />
        </Box>
      </Modal>

      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="xs"
      >
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ mb: 2,color:'#002F43' }}>
            Are you sure you want to remove this Logo?
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "secondary.main",
                width: "130px",
                margin: "auto",
              }}
              onClick={() => setOpen(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "secondary.main",
                width: "130px",
                margin: "auto",
              }}
              onClick={() => {
                // handleRemoveProduct(productToRemove);
                setOpen(false);
                handleLogoFileChange({});
                //  handleRemoveLogo()
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default EmailTemplate;
