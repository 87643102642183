import {
  LoadingButton,
  TabContext,
  TabList,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
  timelineOppositeContentClasses,
} from "@mui/lab";
import { useState } from "react";
import palette from "src/theme/palette";
import {
  CancelReturn,
  CreateRefund,
  DeclineReturn,
  DirectRefund,
  GetEstimateSummary,
  GetUsersList,
  getLabelPreviewApi,
  getOrderInfo,
  getOrderStatus,
  getReturnDetails,
} from "src/features/managereturns/returnsApi";
import { useEffect } from "react";
import CircularLoader from "src/loader/circular/CircularLoader";
import Actions from "./Actions";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import Refund from "./Refund";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { convertToPounds, getCurrencySymbol, unixToDate } from "src/utils/core";
import TimelineHistory from "./Timeline";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import ReturnMethodContentDetail from "./ReturnMethodContentDetail";
// import partial_refund_del_icon from "/assets/icons/partial_refund_del_icon.svg"
import { useDispatch } from "react-redux";
import { setShopifyImages  } from "src/features/returns/shopifyImages";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Divider,
  MenuItem,
  Tooltip,
  FormControl,
  Select,
  InputLabel,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import { backendBaseUrl } from "src/utils/backendUrls";
import { updateTrackingDetails,GetImages  } from "src/features/managereturns/returnsApi";
import { fetchTenants } from "src/features/user/userApi";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import "./style/styles.css";


const {
  Grid,
  Typography,
  Button,
  Box,
  Tab,
  IconButton,
  TextField,
  Dialog,
  Stack,
} = require("@mui/material");
const dropDownStyles={
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#AAAAAA", 
    },
    "&:hover fieldset": {
      borderColor: "#000000", 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#002F43", 
    },
  },
  "& .MuiInputLabel-root": {
    color: "#AAAAAA", 
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#002F43", 
  },
}
const ReturnsDetail = () => {
  const location = useLocation();
  const { selectedID, lineItems } = location?.state;
  const [returnDetails, setReturnDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [imagesrc, setImageSrc] = useState("");
  const [summaryInfo, setSummaryInfo] = useState({});    
  const dispatch = useDispatch();

  const returnMethod = useSelector((state) => state.returnMethod);
  const [isShippingModal, setShippingModal] = useState(false);
  const [shippingAmount, setShippingAmount] = useState(0);
  const [shipping, setShipping] = useState(false);
  const [directRefundModal, setDirectRefundModal] = useState(false);
  const [returnStatusHistory, setReturnStatusHistory] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("Initiated");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openUpdateTrackingNoModal, setOpenUpdateTrackingNoModal] =
    useState(false);
  const [modalText, setModalText] = useState("");
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [labelError, setLabelError] = useState("");
  const [isViewLabelModal, setIsViewLabelModal] = useState(false);
  const [partialRefundValues, setPartialRefundValues] = useState({});
  const [successModalText, setSuccessModalText] = useState("");
  const [currency, setCurrency] = useState("");
  const [reason, setReason] = useState("");
  const [isFirstTimelineConnector, setIsFirstTimelineConnector] =
    useState(null);
  const [label, setLabel] = useState("");
  const [usersList, setUsersList] = useState({});
  const navigate = useNavigate();
  const userInfo = useAppSelector(getUserInfo);
  const [isViewLabelError, setIsViewLabelError] = useState(false);
  const [openPartialRefund, setOpenPartialRefund] = useState(false);
  const [labelUrlPreview, setLabelUrlPreview] = useState("");
  const [formData, setFormData] = useState({
    partialDeduction: "",
    deductionType: "%",
    comment: "",
  });
  const [updatedTrackingNumber, setUpdatedTrackingNumber] = useState(
    returnDetails?.label_info?.tracking_number
  );
  const [updatedCarrier, setUpdatedCarrier] = useState(
    returnDetails?.label_info?.carrier
  );
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [trackingNumberFieldError, setTrackingNumberFieldError] =
    useState(false);

  const [errors, setErrors] = useState({
    partialDeduction: false,
    comment: false,
    isPercentageValidation: false,
  });

  const [isHandlePartialEdit, setIsHandlePartialEdit] = useState(false);

  const [validationMessage, setValidationMessage] = useState("");


  const handleChange1 = (field, value) => {
    const updatedFormData = { ...formData, [field]: value };

    const updatedErrors = {
      ...errors,
      partialDeduction:
        field === "partialDeduction" &&
        (!/^[1-9]\d*$/.test(value) || value === ""),
      comment: field === "comment" && value.length > 300,
    };

    setFormData(updatedFormData);
    setErrors(updatedErrors);
  };
    const filteredTenant = useSelector(
        (state) => state.selectedTenant.SelectedTenant
    );  
    const enhancedUserInfo = {
      accessToken: userInfo?.accessToken,
      tenant_id:filteredTenant?._id,
      tenant_name:filteredTenant?.tenant_name,
      user_id:filteredTenant?.user_id,
      is_demo_user:filteredTenant?.is_demo_user||false,
    };

    const shopifyImages = useSelector(
      (state) => state.shopify.shopifyImages
      
    );
    useEffect(() => {
      GetImages(enhancedUserInfo).then((res) => {
        if (res?.statusCode === 200) {
          dispatch(setShopifyImages(res?.data)); 
        }
      });
    }, [dispatch, userInfo, filteredTenant]);
  const isSubmitDisabled =
    Object.values(errors).some((error) => error) ||
    !formData.partialDeduction ||
    !formData.comment;

  useEffect(() => {
    let product_images = {};
    let image = "/assets/no-image.png";
    orderDetails.forEach(function (item) {
      const productId = item.product_id;
      if (shopifyImages[productId]) {
        if (
          shopifyImages[productId]?.image &&
          shopifyImages[productId]?.image != null &&
          shopifyImages[productId]?.image != ""
        ) {
          product_images[productId] = shopifyImages[productId].image;
        } else {
          product_images[productId] = image;
        }
      } else {
        product_images[productId] = image;
      }
    });
    setImageSrc(product_images);
  }, [orderDetails, shopifyImages]);

  useEffect(() => {
    if (!openUpdateTrackingNoModal) setTrackingNumberFieldError("");
  }, [openUpdateTrackingNoModal]);

  useEffect(() => {
    setIsCustomLoading(true);
    getOrderStatus(selectedID, enhancedUserInfo).then((res) => {
      if (res?.statusCode === 200) {
        setReturnStatusHistory(res.data);
        setCurrentStatus(res.data[res.data.length - 1]?.status);
      }
    });
    getReturnDetails(selectedID, enhancedUserInfo).then((response) => {
      if (response?.statusCode === 200) {
        const currency=response?.data?.currency;
        if (response?.data?.return_status === "Refunded") {
          let {
            refund_summary: {
              tax_amount,
              items_subtotal,
              adjustment_amount,
              shipping_charges,
            },
          } = response?.data;
          setSummaryInfo({
            ...response.data?.refund_summary,
            return_items_amount: items_subtotal,
            tax_amount: tax_amount || 0,
            partial_amount: adjustment_amount.toFixed(2),
            shipping_maximum_refundable: shipping_charges,
          });
        }
        setReturnDetails(response?.data);
        let lineItemsArray = [];
        response?.data?.line_items?.map((item) => {
          if (item?.return_line_item_id) {
            lineItemsArray.push({
              line_item_id: item?.return_line_item_id,
              quantity: item?.quantity,
            });
          }
        });
        const refundData = {
          order_id: response.data?.order_id,
          return_id: response.data?.return_id,
          refund_shipping: {
            amount: shippingAmount || 0,
            currencyCode: currency,
          },
          line_items: lineItemsArray,
        };

          GetEstimateSummary(refundData,enhancedUserInfo)
          .then((res) => {
            let {
              return_items_amount,
              tax_amount,
              shipping_maximum_refundable,
              label_fees,
            } = res?.data;
            if (response.data?.return_status !== "Refunded") {
              setSummaryInfo({
                ...res.data,
                grand_total: (
                  (Number(return_items_amount) || 0) +
                  (Number(tax_amount) || 0) +
                  (Number(shipping_maximum_refundable) || 0) +
                  (Number(label_fees) || 0)
                ).toFixed(2),
              });
            }
            setIsCustomLoading(false);
          })
          .catch((e) => console.log(e.message));
      }
    });
    setIsCustomLoading(false);
  }, [selectedID, shipping, openUpdateTrackingNoModal]);

  useEffect(() => {
    if (
      returnDetails?.label_info?.tracking_number &&
      returnDetails?.label_info?.carrier
    ) {
      setUpdatedTrackingNumber(returnDetails?.label_info?.tracking_number);
      setUpdatedCarrier(returnDetails?.label_info.carrier);
    }
    if (returnDetails?.order_id) {
      getOrderInfo(returnDetails?.order_id, enhancedUserInfo).then((data) => {
        let currency = data?.data?.currency;
        setCurrency(getCurrencySymbol(currency));
        setIsLoading(true);
        if (data.statusCode === 200) {
          const filteredOrders = data.data?.line_items
            .filter((item) =>
              lineItems.some((lineItem) => lineItem?.line_item_id === item.id)
            )
            .map((item) => {
              const lineItem = lineItems.find(
                (lineItem) => lineItem?.line_item_id === item.id
              );
              return {
                ...item,
                return_reason: lineItem?.reason,
                customer_notes: lineItem?.customer_notes,
              };
            });
          setOrderDetails(filteredOrders);
          setIsLoading(false);
        }
      });
    }
  }, [returnDetails, lineItems]);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    const onlyDigitsRegex = /^[a-zA-Z0-9 ]*$/;
    console.log(newValue,"njsjahjhhhs")
    if (+newValue > 99 || +newValue < 0) {
      setErrors({ ...errors, isPercentageValidation: true });
      setValidationMessage("Partial deduction should be less than 100% and greater than 1%");
      return;
    } else {
      setErrors({ ...errors, isPercentageValidation: false });
      setValidationMessage("");
    }
    setFormData({ ...formData, [event]: newValue });

    // if(Object.keys(formData)?.length <= 0){
    //   setErrors({ partialDeduction: true, comment: true })
    //   return;
    // }else if (Object.keys(formData)?.length <= 0 && formData?.partialDeduction == "" && formData?.comment == "") {
    //   setErrors({ partialDeduction: true, comment: true })
    //   return;
    // } else if (formData?.partialDeduction == "") {
    //   setErrors({ partialDeduction: true, comment: false })
    //   return;
    // } else if (formData?.comment == "") {
    //   setErrors({ partialDeduction: false, comment: true })
    //   return;
    // }
    setErrors({ ...errors, [event]: false });
    // setValue(newValue);
  };
  const handleShippingAmount = () => {
    setShipping(!shipping);
    setShippingModal(false);
  };

  const statusColors = {
    Accepted: { color: palette.primary.main, bgColor: "#CFDEE9" },
    Initiated: { color: "#FF8E09", bgColor: "#fbe4ca" },
    Canceled: { color: "#EA1919", bgColor: "#fbd1d1" },
    Rejected: { color: "#EA1919", bgColor: "#fbd1d1" },
    Refunded: { color: "#4DAC20", bgColor: "#4dac2033" },
  };

  const returnStatus = {
    Initiated: [
      {
        name: "Accepted",
        icon: (
          <AssignmentTurnedInIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />
        ),
      },
      {
        name: "Refunded",
        icon: <CreditScoreIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />,
      },
    ],
    Accepted: [
      {
        name: "Refunded",
        icon: <CreditScoreIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />,
      },
    ],
  };

  const handleEditTrackingNumber = () => {
    setOpenUpdateTrackingNoModal(true);
  };

  const handleSubmitUpdateTrackingNumber = async () => {
    if (!updatedTrackingNumber) {
      setTrackingNumberFieldError("Tracking Number is required.");
      return;
    }

    const body = {
      tracking_number: updatedTrackingNumber,
      carrier: updatedCarrier,
    };
    const updatedUserInfo = {
      ...userInfo,
      tenant: filteredTenant,
    };

    setIsCustomLoading(true);
    try {
      const response = await updateTrackingDetails(
        enhancedUserInfo,
        body,
        returnDetails?._id
      );

      if (response?.statusCode === 200) {
        setSuccessModalText("Successfully Updated Your Tracking Details!");
      } else {
        setSuccessModalText(
          "There was an error updating your tracking details. Please try again."
        );
      }
      setOpenUpdateTrackingNoModal(false);
      setIsCustomLoading(false);
      setOpenSuccessModal(true);
    } catch (error) {
      console.log("error", error);
    }
    setIsCustomLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(true);
    setLabel("Cancel");
    setModalText("Are you sure you want to cancel this return?");
  };
  const handleRefund = () => {
    setDirectRefundModal(true);
    setLabel("Refund");
  };

  const handleSubmit = () => {
    const updatedUserInfo = {
      ...userInfo,
      tenant: filteredTenant,
    };
    setIsModalOpen(false);
    setDirectRefundModal(false);
    setIsLoading(true);
    if (label === "Cancel") {
      CancelReturn({ return_id: returnDetails?._id, reason: "OTHER" },enhancedUserInfo)
        .then((res) => {
          console.log("res", res);
          setIsLoading(false);
          setIsModalSuccessOpen(true);
          setSuccessModalText("Successfully Canceled!");
        })
        .catch((e) => {
          console.log("e", e);

          setIsLoading(false);
          setIsModalSuccessOpen(true);
          setSuccessModalText(e?.response?.data?.statusMessage);
        });
    }
    if (label === "Refund") {
      const data = {
        return_id: selectedID,
        items_subtotal: summaryInfo.return_items_amount,
        order_discount: 0,
        tax_amount: summaryInfo.tax_amount,
        shipping_charges: 0,
        adjustment_amount: 0,
        deduction: 0,
        reason,
        grand_total: (
          (Number(summaryInfo.return_items_amount) || 0) +
          (Number(summaryInfo.tax_amount) || 0) +
          (Number(summaryInfo.shipping_maximum_refundable) || 0)
        ).toFixed(2),
      };
      DirectRefund(data,enhancedUserInfo)
        .then(() => {
          setIsLoading(false);
          setIsModalSuccessOpen(true);
          setSuccessModalText("Successfully Refunded!");
        })
        .catch((e) => {
          setIsLoading(false);
          setIsModalSuccessOpen(true);
          setSuccessModalText(e.response.data.statusMessage);
        });
    }
  };
  useEffect(() => {
    (async () => {
      const res = await GetUsersList(enhancedUserInfo);
      res.statusCode === 200 && setUsersList(res.data);
    })();
  }, []);

  function getUserNameFromID() {}

  const Messages = {
    Initiated: "Return initiated by",
    Accepted: "Return accepted by",
    Cancelled: "Return cancelled by",
    Rejected: "Rejected by",
    Refunded: "Refunded by",
  };

  const sortedReturnStatusHistory = [
    ...returnStatusHistory.filter((item) => item.status === "Initiated"),
    ...returnStatusHistory
      .filter((item) => item.status !== "Initiated")
      .sort((a, b) => a.status.localeCompare(b.status)),
  ];

  // const demoLabelUrl = "https://stage-beyondcarts-logo.s3.us-east-2.amazonaws.com/uploads/return_labels/66e192d245e80081398624d4/15189311581.jpg"

  function handleViewLabel() {
    getLabelPreview();
  }

  async function getLabelPreview() {
    try {
      setIsLoading(true);
      let { data, statusCode } = await getLabelPreviewApi(
        returnDetails?._id,
        // { ...userInfo, tenant: filteredTenant }
        enhancedUserInfo
      );

      if (statusCode == 200) {
        const fileExtension = (data?.label || data?.label_path)
          ?.split(".")
          ?.pop();
        if (fileExtension !== "pdf") {
          setLabelUrlPreview(
            `https://stage-beyondcarts-logo.s3.us-east-2.amazonaws.com/${
              data?.label || data?.label_path
            }`
          );
          setIsViewLabelModal(true);
          setIsLoading(false);
        } else if (fileExtension == "pdf") {
          window.open(
            `https://stage-beyondcarts-logo.s3.us-east-2.amazonaws.com/${
              data?.label || data?.label_path
            }`,
            "_blank"
          );
          setIsLoading(false);
        }
      }
    } catch (err) {
      setLabelError(err);
      setIsViewLabelError(true);
      setIsLoading(false);
    }
  }

  async function handleViewLabelDownload() {
    try {
      const response = await fetch(labelUrlPreview);

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      const blob = await response.blob();

      const fileName = labelUrlPreview.split("/").pop();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName; 

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }

  function handlePartialRefund() {
    if (Object.keys(formData)?.length <= 0) {
      setErrors({ partialDeduction: true, comment: true });
      return;
    } else if (
      Object.keys(formData)?.length <= 0 &&
      formData?.partialDeduction == "" &&
      formData?.comment == ""
    ) {
      setErrors({ partialDeduction: true, comment: true });
      return;
    } else if (
      !formData?.partialDeduction ||
      formData?.partialDeduction == ""
    ) {
      setErrors({ partialDeduction: true, comment: false });
      return;
    } else if (!formData?.comment || formData?.comment == "") {
      setErrors({ partialDeduction: false, comment: true });
      return;
    }

    let partial_amount_value = null;

    let grand_total =
      (Number(summaryInfo.return_items_amount) || 0) +
      (Number(summaryInfo.tax_amount) || 0) +
      (Number(summaryInfo.shipping_maximum_refundable) || 0) +
      (Number(summaryInfo.label_fees) || 0);

    let partial_amount_calculation =
      parseFloat(grand_total) * (parseFloat(formData.partialDeduction) / 100);
    partial_amount_value = parseFloat(partial_amount_calculation);

    setSummaryInfo({
      ...summaryInfo,
      grand_total: (Number(grand_total) - Number(partial_amount_value)).toFixed(
        2
      ),
      partial_amount: partial_amount_value,
      partial_reason: formData.comment,
    });
    setPartialRefundValues({ ...formData });
    setOpenPartialRefund(false);
  }

  function handlePartialRefundOpen(isEdit) {
    if (isEdit) {
      setFormData({ ...partialRefundValues });
      setOpenPartialRefund(true);
      setIsHandlePartialEdit(true);
    } else {
      setFormData({});
      setPartialRefundValues({});
      setOpenPartialRefund(true);
      setIsHandlePartialEdit(false);
    }
  }

  function handlePartialClose() {
    console.log(isHandlePartialEdit, "isHandlePartialEdit");
    if (!isHandlePartialEdit) {
      setErrors({ partialDeduction: false, comment: false });
      setFormData({});
      setPartialRefundValues({});
      setOpenPartialRefund(false);
    } else {
      setFormData({ ...formData });
      setPartialRefundValues({ ...partialRefundValues });
      setOpenPartialRefund(false);
      setErrors({ ...errors, isPercentageValidation: false });
  
    }
    setIsHandlePartialEdit(false);
    setErrors({ partialDeduction: false, comment: false }); 
  }

  function handlePartialDelete() {
    let grand_total = (
      (Number(summaryInfo.return_items_amount) || 0) +
      (Number(summaryInfo.tax_amount) || 0) +
      (Number(summaryInfo.shipping_maximum_refundable) || 0) +
      (Number(summaryInfo.label_fees) || 0)
    ).toFixed(2);
    setFormData({});
    setPartialRefundValues({});
    delete summaryInfo.partial_amount;
    delete summaryInfo.partial_reason;
    setSummaryInfo({
      ...summaryInfo,
      grand_total: Number(grand_total).toFixed(2),
    });
    setOpenPartialRefund(false);
  }


  return (
    <>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Box sx={{ backgroundColor: "#FAFAFA" }}>
          {isCustomLoading && <CircularLoader />}
          <Box
            sx={{
              pt: 2,
              position: "sticky",
              top: 0,
              backgroundColor: "#FAFAFA",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ArrowBackIosIcon
                  sx={{ color: palette.primary.main, cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/manage-returns/"
                    )
                  }
                />
                <Typography
                  sx={{ fontSize: "24px", color: palette.primary.main }}
                >
                  Return ID: {returnDetails?.return_id}
                </Typography>
                {returnDetails?.return_status && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography
                      sx={{
                        backgroundColor: `${
                          statusColors[returnDetails?.return_status]?.bgColor ||
                          "yellow"
                        }`,
                        color: `${
                          statusColors[returnDetails?.return_status]?.color ||
                          "pink"
                        }`,
                        width: "max-content",
                        fontSize: "12px",
                        padding: "2px 15px",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {returnDetails?.return_status}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                {returnDetails?.label_type &&
                  (returnDetails?.label_type === "paid_label" ||
                    returnDetails?.label_type === "free_label") && (
                    <Button
                      variant="contained"
                      sx={{
                        boxShadow: "none",
                        padding: "7px 14px",
                        borderRadius: "10px",
                        backgroundColor: "secondary.main",
                      }}
                      onClick={handleViewLabel}
                    >
                      View Label
                    </Button>
                  )}
                {returnDetails?.return_status === "Initiated" && (
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: "none",
                      padding: "7px 14px",
                      borderRadius: "10px",
                      backgroundColor: "secondary.main",
                    }}
                    onClick={handleRefund}
                  >
                    Refund
                  </Button>
                )}
                {returnDetails?.return_status === "Accepted" && returnDetails?.label_type === "own_label"  &&(
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: "none",
                      padding: "7px 14px",
                      borderRadius: "10px",
                      backgroundColor: "secondary.main",
                    }}
                    onClick={handleEditTrackingNumber}
                  >
                    Update Tracking Number
                  </Button>
                )}
                {(returnDetails?.return_status === "Initiated" ||
                  returnDetails?.return_status === "Accepted") && (
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: "none",
                      padding: "7px 14px",
                      borderRadius: "10px",
                      backgroundColor: "secondary.main",
                    }}
                    onClick={handleCancel}
                  >
                    Cancel return
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  color: palette.primary.main,
                  fontSize: "13px",
                  padding: "0 10px 0 0",
                  borderRight: "2px solid #878484",
                }}
              >
                Order Number: {returnDetails?.order_number || "-"}
              </Typography>
              <Typography
                sx={{
                  color: "#878484",
                  fontSize: "13px",
                  padding: "0 10px",
                  borderRight: "2px solid #878484",
                }}
              >
                Created Date: {unixToDate(returnDetails?.created_at) || "-"}
              </Typography>
              <Typography
                sx={{
                  color: "#878484",
                  fontSize: "13px",
                  padding: "0 10px",
                  borderRight: "2px solid #878484",
                }}
              >
                Tracking Number:{" "}
              {returnDetails?.label_type == "2_way_label" ? returnDetails?.tracking_no : returnDetails?.label_info?.tracking_number}
              {(!returnDetails?.label_type && returnDetails?.tracking_no) ? returnDetails?.tracking_no : "-"}
              </Typography>
              <Typography
                sx={{ color: "#878484", fontSize: "13px", padding: "0 10px" }}
              >
                Carrier:{" "}
                {
                (returnDetails?.label_info?.carrier ||
                  returnDetails?.carrier) &&
                (returnDetails?.label_info?.tracking_number ||
                  returnDetails?.tracking_no) ? (
                  <>
                    {returnDetails?.label_info?.carrier || returnDetails?.carrier}{" "}
                    <span
                      onClick={() =>{
                        window.open(
                       `https://app-staging.beyondcarts.com/returns/${filteredTenant?.tenant_name}/tracking?tracking_id=${returnDetails?._id}`,
                          "_blank"
                        )
                      }}
                      style={{
                        color: "#3A7DAE",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      (Track Item)
                    </span>
                  </>
                ) : !returnDetails?.label_type ? (
                  <>
                    {" "}
                    {returnDetails?.carrier}{" "}
                    <span
                      onClick={() =>
                        window.open(
                       `https://app-staging.beyondcarts.com/returns/${filteredTenant?.tenant_name}/tracking?tracking_id=${returnDetails?._id}`,
                          "_blank"
                        )
                      }
                      style={{
                        color: "#3A7DAE",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      (Track Item)
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              lg={9}
              sx={{
                maxHeight: "200vh",
                scrollbarWidth: "thin",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  borderRadius: "12px",
                  mt: 2,
                  overflow: "hidden",
                  backgroundColor: "white",
                  boxShadow: "0px 2px 8px -4px lightgrey",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    mb: 2,
                    padding: "10px 20px",
                    fontSize: "17px",
                    backgroundColor: "#CFDEE9",
                  }}
                >
                  Customer Details
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                  <Typography
                    sx={{
                      color: "#AAAAAA",
                      mb: 1,
                      fontSize: "14px",
                      padding: "0 0 0 20px",
                    }}
                  >
                    First Name:{" "}
                    <span style={{ color: "#000" }}>
                      {returnDetails?.first_name || "-"}
                    </span>
                  </Typography>
                  <Typography
                    sx={{ color: "#AAAAAA", mb: 1, fontSize: "14px" }}
                  >
                    Last Name:{" "}
                    <span style={{ color: "#000" }}>
                      {returnDetails?.last_name || "-"}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#AAAAAA",
                      mb: 1,
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    Email:{" "}
                    <span style={{ color: "#3A7DAE" }}>
                      {returnDetails?.email || "-"}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  borderRadius: "12px",
                  mt: 3,
                  overflow: "hidden",
                  backgroundColor: "white",
                  boxShadow: "0px 2px 8px -4px lightgrey",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 20px",
                    justifyContent: "space-between",
                    backgroundColor: "#CFDEE9",
                  }}
                >
                  <Typography sx={{ color: "#000", fontSize: "17px" }}>
                    Order and Refund Details
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}             
                  className="colorBlack"
                  >
                    This return contains a total of {orderDetails?.length || 0}{" "}
                    {orderDetails?.length === 1 ? "item" : "items"}
                  </Typography>
                </Box>
                <Grid container spacing={3} sx={{ padding: "20px" }}>
                  {orderDetails?.map((item) => {
                    return (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            padding: "20px 0 0 20px",
                          }}
                        >
                          <img
                            width={125}
                            height={125}
                            style={{
                              objectFit: "contain",
                              border: "1px solid #E4E4E4",
                              borderRadius: "10px",
                            }}
                            src={
                              imagesrc?.[item.product_id]
                                ? imagesrc?.[item.product_id]
                                : "/assets/no-image.png"
                            }
                          />
                          <Box sx={{ width: "100%", pl: 2 }}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sx={{ marginY: "auto" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        mb: 1,
                                        padding: "5px 10px",
                                        borderRadius: "20px",
                                        width: "max-content",
                                        backgroundColor: "#D3EAFA",
                                      }}
                                    >
                                      Reason for Refund: {item.return_reason}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        mb: 1,
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Box display={"flex"} gap={20}>
                                      <Typography
                                        sx={{
                                          color: "#0009",
                                          fontSize: "13px",
                                        }}
                                      >
                                        SKU{" "}
                                        <span style={{ color: "#000" }}>
                                          {item.sku || "-"}
                                        </span>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: "#0009",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Weight:{" "}
                                        <span style={{ color: "#000" }}>
                                          {`${convertToPounds(
                                            item?.weight,
                                            item?.weight_unit
                                          )} lbs` || "Not available"}
                                        </span>
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        mb: 1,
                                      }}
                                    >
                                      {currency} {item.price}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#0009",
                                        fontSize: "13px",
                                        textAlign: "right",
                                      }}
                                    >
                                      Qty{" "}
                                      <span style={{ color: "#000" }}>
                                        {item.quantity}
                                      </span>
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </Grid>
              </Box>
              <TimelineHistory selectedID={selectedID} usersList={usersList} />
            </Grid>
            <Grid item xs={12} lg={3} sx={{ marginTop: "15px" }}>
              <Box
                sx={{
                  borderRadius: "12px",
                  paddingTop: "10px",
                  overflow: "hidden",
                  backgroundColor: "white",
                  boxShadow: "0px 2px 8px -4px lightgrey",
                }}
              >
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {sortedReturnStatusHistory.map((item, index) => {
                    const isLast =
                      index === sortedReturnStatusHistory.length - 1;
                    return (
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot
                            color={
                              item.status === "Canceled" ||
                              item.status === "Rejected"
                                ? "error"
                                : "success"
                            }
                          >
                            {item.status === "Initiated" && (
                              <KeyboardReturnIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {item.status === "Accepted" && (
                              <AssignmentTurnedInIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {item.status === "Refunded" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {item.status === "Canceled" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {item.status === "Rejected" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                          </TimelineDot>
                          {!isLast && (
                            <TimelineConnector
                              sx={{
                                borderStyle: "dashed",
                                borderWidth: "2px",
                                backgroundColor: "white",
                                borderColor: "#54d62c",
                              }}
                            />
                          )}
                        </TimelineSeparator>
                        <TimelineContent sx={{ pt: "10px", px: "10px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              textWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ fontSize: "12px" }} 
                            className="colorBlack"
                            >
                              {item.status}
                            </Typography>
                            <Typography
                              sx={{ color: "#878787", fontSize: "12px" }}
                            >
                              {unixToDate(item.created_at)}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{ color: "#878787", fontSize: "10px" }}
                          >
                            {Messages[item.status]}
                            {usersList[item.created_by]
                              ? ` - ${usersList[item.created_by]}`
                              : ` ${returnDetails?.first_name}`}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                  {returnStatus[currentStatus]?.map((item, index) => {
                    const isLast =
                      index === returnStatus[currentStatus].length - 1;
                    return (
                      <TimelineItem>
                        <TimelineSeparator>
                          {index === 0 && (
                            <TimelineConnector
                              sx={{
                                borderStyle: "dashed",
                                borderWidth: "2px",
                                backgroundColor: "white",
                                borderColor: "#D6D6D6",
                                position: "absolute",
                                top: "-19px",
                                height: "20px",
                              }}
                            />
                          )}
                          <TimelineDot variant="outlined">
                            {item.icon}
                          </TimelineDot>
                          {!isLast && (
                            <TimelineConnector
                              sx={{
                                borderStyle: "dashed",
                                borderWidth: "2px",
                                backgroundColor: "white",
                                borderColor: "#D6D6D6",
                              }}
                            />
                          )}
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "18px", px: "10px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#D6D6D6" }}
                          >
                            {item.name}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </Box>

              {/* return method */}
              <ReturnMethodContentDetail
                returnInfo={returnDetails}
                returnMethod={returnMethod}
              />

              {/* refund summary */}
              <Box
                sx={{
                  borderRadius: "12px",
                  mt: 3,
                  overflow: "hidden",
                  backgroundColor: "white",
                  boxShadow: "0px 2px 8px -4px lightgrey",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    mb: 2,
                    padding: "10px 20px",
                    fontSize: "17px",
                    backgroundColor: "#CFDEE9",
                  }}
                >
                  Refund Summary
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                    justifyContent: "flex-end",
                    padding: "0 20px",
                  }}
                >
                  <Box sx={{ textAlign: "right" }}>
                    <Typography
                      sx={{ color: "#AAAAAA", mb: 2, fontSize: "14px" }}
                    >
                      Refund Status:
                    </Typography>
                    <Typography
                      sx={{ color: "#AAAAAA", mb: 2, fontSize: "14px" }}
                    >
                      Return Item ({orderDetails?.length || 0}):
                    </Typography>
                    <Typography
                      sx={{ color: "#AAAAAA", mb: 2, fontSize: "14px" }}
                    >
                      Tax:
                    </Typography>

                    {summaryInfo?.label_fees > 0 && (
                      <Typography
                        sx={{ color: "#AAAAAA", mb: 2, fontSize: "14px" }}
                      >
                        Label fee:
                      </Typography>
                    )}

                    {summaryInfo?.partial_amount &&
                      returnDetails?.return_status !== "Refunded" && (
                        <>
                          <Tooltip
                            title="You can click to edit or delete the partial deduction amount & reason."
                            placement="left-start"
                            arrow
                          >
                            <Typography
                              sx={{
                                color: "#AAAAAA",
                                mb: 2,
                                fontSize: "14px",
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "#256FA5",
                              }}
                              onClick={() => {
                                handlePartialRefundOpen(true);
                              }}
                            >
                              Partial deduction:
                            </Typography>
                          </Tooltip>
                        </>
                      )}

                    {summaryInfo?.partial_amount &&
                      returnDetails?.return_status == "Refunded" && (
                        <>
                          <Typography
                            sx={{ color: "#AAAAAA", mb: 2, fontSize: "14px" }}
                          >
                            Partial deduction:
                          </Typography>
                        </>
                      )}

                    <Typography
                      sx={{
                        color: "#AAAAAA",
                        mb: 2,
                        fontSize: "14px",
                        textWrap: "nowrap",
                      }}
                    >
                      Refund Shipping fee:
                    </Typography>
                    <Typography sx={{ color: "#000", mb: 2, fontSize: "14px" }}>
                      Total:
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "right" }}>
                    <Typography
                      sx={{
                        backgroundColor: `${
                          statusColors[returnDetails?.return_status]?.bgColor ||
                          "yellow"
                        }`,
                        color: `${
                          statusColors[returnDetails?.return_status]?.color ||
                          "pink"
                        }`,
                        mb: 2,
                        width: "max-content",
                        fontSize: "12px",
                        padding: "2px 15px",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {returnDetails?.return_status}
                    </Typography>
                    <Typography
                      sx={{ color: "#1C272A", mb: 2, fontSize: "14px" }}
                    >
                      {currency}{" "}
                      {(Number(summaryInfo.return_items_amount) || 0).toFixed(
                        2
                      )}
                    </Typography>
                    <Typography
                      sx={{ color: "#1C272A", mb: 2, fontSize: "14px" }}
                    >
                      {currency}{" "}
                      {(Number(summaryInfo.tax_amount) || 0).toFixed(2)}
                    </Typography>
                    {summaryInfo?.label_fees > 0 && (
                      <Typography
                        sx={{ color: "#1C272A", mb: 2, fontSize: "14px" }}
                      >
                        {currency}{" "}
                        {(Number(summaryInfo.label_fees) || 0).toFixed(2)}
                      </Typography>
                    )}
                    {summaryInfo?.partial_amount && (
                      <Typography
                        sx={{ color: "#1C272A", mb: 2, fontSize: "14px" }}
                      >
                        - {currency}{" "}
                        {(Number(summaryInfo.partial_amount) || 0).toFixed(2)}
                      </Typography>
                    )}

                    <Typography
                      sx={{ color: "#1C272A", mb: 2, fontSize: "14px" }}
                    >
                      {currency}{" "}
                      {(
                        Number(summaryInfo.shipping_maximum_refundable) || 0
                      ).toFixed(2)}
                    </Typography>
                    <Typography
                      sx={{ color: "#1C272A", mb: 2, fontSize: "14px" }}
                    >
                      {currency} {Number(summaryInfo.grand_total).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {!summaryInfo?.partial_amount &&
                returnDetails?.return_status == "Accepted" && (
                  <Button
                    onClick={() => handlePartialRefundOpen(true)}
                    variant="contained"
                    fullWidth
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "secondary.main",
                      mt: 2,
                    }}
                  >
                    Partial Refund
                  </Button>
                )}
              <Actions
                // openPartialRefund={openPartialRefund}
                formData={formData}
                returnDetails={returnDetails}
                summaryInfo={summaryInfo}
              />
              {formData.comment && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ color: "#AAAAAA", mb: 1, fontSize: "14px" }}
                  >
                    Partial refund reason
                  </Typography>
                  <p sx={{ color: "#AAAAAA", mb: 1, fontSize: "13px" }}  className="colorBlack">
                    {formData?.comment}
                  </p>
                </Box>
              )}
            </Grid>
          </Grid>
          <Dialog
            fullWidth={true}
            open={directRefundModal}
            onClose={() => setDirectRefundModal(false)}
            maxWidth="xs"
          >
            <Box sx={{ padding: "20px" }}>
              <Typography sx={{ color: "#000", mb: 2 }}>
                Reason for refund
              </Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                fullWidth
                rows={2}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Add a Comment"
                className="colorBlack"
              />
              <Typography
                sx={{ color: "#AAAAAA", fontSize: "10px", mt: 1, mb: 2 }}
              >
                Please note that this comment will be displayed on the customer
                tracking portal and will also appear in the TimeLine history.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleSubmit}
                  disabled={reason ? false : true}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    minWidth: "130px",
                    backgroundColor: "secondary.main",
                  }}
                >
                  Refund
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setDirectRefundModal(false)}
                  sx={{
                    boxShadow: "none",
                    minWidth: "130px",
                    borderColor: "secondary.main",
                    color: "secondary.main",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Dialog>

          {/* update tracking number modal */}
          <Dialog
            fullWidth={true}
            open={openUpdateTrackingNoModal}
            onClose={() => setOpenUpdateTrackingNoModal(false)}
            maxWidth="xs"
          >
            <Stack padding={"20px 60px"} alignItems={"center"} gap={3}>
              <Typography fontSize={18}>
                Please update your tracking here
              </Typography>
              <Stack gap={2} width={"100%"}>
                <Stack direction={"column"}>
                  <TextField
                   sx={{ 
                   ...dropDownStyles
                  }}
                    fullWidth
                    label="Enter your tracking number"
                    name="trackingNumber"
                    value={updatedTrackingNumber}
                    onChange={(e) => setUpdatedTrackingNumber(e.target.value)}
                    error={!!trackingNumberFieldError}
                    className="colorBlack"
                  />
                  {trackingNumberFieldError && (
                    <FormHelperText error={!!trackingNumberFieldError}>
                      {trackingNumberFieldError}
                    </FormHelperText>
                  )}
                </Stack>
                <FormControl fullWidth
                sx={{ 
                   ...dropDownStyles
                  }}>
                  <InputLabel>Select your carrier</InputLabel>
                  <Select
                    label="Select your carrier"
                    value={updatedCarrier}
                    onChange={(e) => setUpdatedCarrier(e.target.value)}
                    sx={{
                      maxHeight: "56px",
                    }}
                   className="colorBlack"
                  >
                    {["USPS", "UPS", "DHL", "FEDEX"].map((carrier) => (
                      <MenuItem key={carrier} value={carrier}
                  className="colorBlack">
                        <ListItemText primary={carrier} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "secondary.main",
                    color: "secondary.main",
                  }}
                  onClick={() => setOpenUpdateTrackingNoModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ backgroundColor: "secondary.main" }}
                  variant="contained"
                  onClick={handleSubmitUpdateTrackingNumber}
                >
                  Update
                </Button>
              </Box>
            </Stack>
          </Dialog>

          {/* un/successfull tracking number updated*/}
          <Dialog
            fullWidth={true}
            open={openSuccessModal}
            onClose={() => setOpenSuccessModal(false)}
            maxWidth="xs"
          >
            <Stack direction={"column"} gap={2} sx={{ padding: "20px" }}>
              <img
                src="/assets/returnbox.svg"
                style={{ paddingTop: "20px", margin: "auto" }}
              />
              <Typography
                sx={{ color: "#000", textAlign: "center", fontSize: 16 }}
              >
                {successModalText}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 2,
                  mt: 1,
                }}
              >
                <LoadingButton
                  variant="outlined"
                  sx={{
                    width: "130px",
                    borderColor: "secondary.main",
                    color: "secondary.main",
                  }}
                  onClick={() => setOpenSuccessModal(false)}
                >
                  Close
                </LoadingButton>
              </Box>
            </Stack>
          </Dialog>

          {/* //--------------partial refund----------------- */}
          <Dialog
            fullWidth={true}
            open={openPartialRefund}
            // onClose={() => setDirectRefundModal(false)}
            maxWidth="xs"
          >
            <Box sx={{ padding: "20px" }}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <img
                  src="/assets/icons/partial_refund_del_icon.svg"
                  style={{ cursor: "pointer" }}
                  onClick={handlePartialDelete}
                />
                <img
                  src="/assets/icons/close_icon.svg"
                  style={{ cursor: "pointer" }}
                  onClick={handlePartialClose}
                />
              </Box>
              <TextField
                label="Partial Deduction"
                variant="outlined"
                type="number"
                fullWidth
                sx={{ mt: 2 }}
                value={formData.partialDeduction}
                onChange={(e) =>
                  handleChange("partialDeduction", e.target.value)
                }
                className="colorBlack"
                error={errors.partialDeduction}
                // helperText={errors.partialDeduction ? 'Enter a number greater than 0' : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <TextField
                        select
                        defaultValue={"%"}
                        value={formData.deductionType}
                        onChange={(e) =>
                          handleChange("deductionType", e.target.value)
                        }
                        variant="standard"
                        sx={{ width: 50, padding: 0 }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className="colorBlack"
                      >
                        <MenuItem  className="colorBlack"value="%">%</MenuItem>
                      </TextField>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.partialDeduction && (
                    <span className="errorMessageText">Required</span>
              )}
              {!errors.partialDeduction &&
                errors.isPercentageValidation &&
                validationMessage !== "" && (
                  <span className="errorMessageText">{validationMessage}</span>
                )}
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ color: "#000", mb: 1 }}>
                  Reason for partial deduction *
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  fullWidth
                  rows={2}
                  value={formData.comment}
                  onChange={(e) => handleChange("comment", e.target.value)}
                  placeholder="Add a Comment"
                  className="colorBlack"
                    sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: errors.comment ? "#D32F2F" : "#002F43", 
                      },
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: errors.comment ? "#D32F2F" : "",
                    },
                    "& .MuiInputBase-input::placeholder": {
                            color: errors.comment ? "#D32F2F" : "", 
                        },
                    }}
                />
              </Box>
              {errors.comment && (
                <span className="errorMessageText">Required</span>
              )}

              <Typography
                sx={{ color: "#AAAAAA", fontSize: "10px", mt: 1, mb: 2 }}
              >
                Please note that this comment will be displayed on the customer
                tracking portal and will also appear in the TimeLine history.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Button
                  onClick={handlePartialRefund}
                  // disabled={!formData ? false : true}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    minWidth: "130px",
                    backgroundColor: "secondary.main",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            fullWidth={true}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            maxWidth="xs"
          >
            <Box sx={{ padding: "20px" }}>
              <Typography
                sx={{ color: "#000", textAlign: "center", fontSize: "16px" }}
              >
                {modalText}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "secondary.main",
                    color: "secondary.main",
                  }}
                  onClick={() => setIsModalOpen(false)}
                >
                  No
                </Button>
                <Button
                  sx={{ backgroundColor: "secondary.main" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            fullWidth={true}
            open={isModalSuccessOpen}
            onClose={() => setIsModalSuccessOpen(false)}
            maxWidth="xs"
          >
            <Box sx={{ padding: "20px" }}>
              <Typography
                sx={{ color: "#000", textAlign: "center", fontSize: "16px" }}
              >
                {successModalText}
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  display: "block",
                  margin: "auto",
                  mt: 2,
                  borderColor: "secondary.main",
                  color: "secondary.main",
                }}
                onClick={() => [
                  setIsModalSuccessOpen(false),
                  navigate("/dashboard/manage-returns/"),
                ]}
              >
                Close
              </Button>
            </Box>
          </Dialog>

          {/* view label dialog box */}
          <Dialog
            // fullWidth={true}
            open={isViewLabelModal}
            onClose={() => setIsViewLabelModal(false)}
            maxWidth="xs"
            // sx={{padding:"30px"}}
          >
            <Box
              textAlign={"right"}
              px={2}
              pt={1}
              sx={{ cursor: "pointer" }}
              onClick={() => setIsViewLabelModal(false)}
            >
              X
            </Box>
            <>
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{ paddingX: "50px" }}
              >
                <img src={labelUrlPreview} width={"330px"} height={"450px"} />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                // justifyContent={"space-between"}
                sx={{ padding: "20px" }}
              >
                <Button
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    textTransform: "none",
                    fontWeight: 500,
                    borderColor: "secondary.main",
                    color: "secondary.main",
                  }}
                  onClick={handleViewLabelDownload}
                  startIcon={<img src="/assets/icons/print_label.svg" />}
                >
                  <p> Print or download</p>
                </Button>
                {/* <Button
                  // variant="outlined"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    textTransform: "none",
                    fontWeight: 500,
                    borderColor: "secondary.main",
                    color: "secondary.main",
                  }}
                  startIcon={<img src="/assets/icons/mail_send_label.svg" />}
                >
                  Share to customer
                </Button> */}
              </Box>
            </>
          </Dialog>
          <Dialog
            // fullWidth={true}
            open={isViewLabelError}
            onClose={() => setIsViewLabelError(false)}
            maxWidth="xs"
            // sx={{padding:"30px"}}
          >
            <Box
              textAlign={"right"}
              px={2}
              pt={1}
              sx={{ cursor: "pointer" }}
              onClick={() => setIsViewLabelError(false)}
            >
              X
            </Box>
            <>
              <Box sx={{ paddingX: "5rem" }}>
                <b style={{ fontSize: "20px" }}>{labelError}</b>
              </Box>
              <Box sx={{ my: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  // variant="outlined"
                  onClick={() => setIsViewLabelError(false)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    textTransform: "none",
                    fontWeight: 500,
                    borderColor: "secondary.main",
                    color: "secondary.main",
                  }}
                >
                  OK
                </Button>
              </Box>
            </>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default ReturnsDetail;
