import { Box, Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import style from "./previewBlog.module.css"
import { retrieveLogoUrl } from 'src/features/config/configApi'

const PreviewBlogs = () => {
    const blogsdata = useSelector((state) => state.blogsState.viewBlogs)
    let { blogTitle, metaKeyword, metaDescription, blogContent, bannerImage, footerImage, footerUrl } = blogsdata;
    const cleanBlogContent = blogContent?.replace(/<p><br><\/p>/g, '');
    return (
        <div style={{backgroundColor: "#222", minHeight: "100vh"}}>
            <Container sx={{py:5}}>
                <article className={style.article}>
                    <img src={`${retrieveLogoUrl}/${bannerImage}`}
                        width={100}
                        height={100}
                        style={{maxHeight:"440px" ,objectFit: "cover", width: "100%", height: "100%" }}
                        alt="Article image"
                        loading="lazy"
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: cleanBlogContent }}
                        className={style.innerContent}
                    />
                    {footerImage !== null && footerUrl !== null &&
                        <a href={footerUrl} target="_blank">
                            <img src={`${retrieveLogoUrl}/${footerImage}`}
                                width={100}
                                height={100}
                                style={{marginTop:"20px"}}
                                // style={{ objectFit: "fill", width: "100%", height: "100%" }}
                                alt="Article image"
                                loading="lazy"
                            />
                        </a>
                    }
                </article>
            </Container>
        </div>
    )
}

export default PreviewBlogs